import React from 'react';

import ThemedImage from '../utils/ThemedImage';

export const stepsGraphType = (language) => {
    return ([       
        {
            content:
            <div className='row-center align-items-center w-100 mt-3' style={{ height: 152, width: 300 }}>
                <ThemedImage imageName={'step1-graph-type.png'}/>
            </div>,
            title: language.onboarding_graph_type_title_step1,
            description: language.onboarding_graph_type_description_step1,
            placement: "auto",          
            target: "#graph_picker",
            disableBeacon: true,
            steps: 2,           
        }, 
        {
            content:
                <div className='row-center align-items-center w-100 mt-3' style={{ height: 142, width: 300 }}>
                    <ThemedImage imageName={'step2-graph-type.png'}/>
                </div>,
            title: language.onboarding_graph_type_title_step2,
            description: language.onboarding_graph_type_description_step2,
            placement: "auto",           
            target: "#graph_picker",
            disableBeacon: true,
            steps: 2,           
        }        
    ])
};