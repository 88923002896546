import React, { useState, useEffect, useMemo } from "react";
import { Modal } from "rsuite";
import InputRounded from "../shared/utilities/InputRounded";

const groupByTypeAndAccount = (arr) => {
    return arr.reduce((acc, item) => {
        const typeKey = item?.type?.name || "Unknown";
        const accountKey = item?.account?.name || "Unknown";
        if (!acc[typeKey]) acc[typeKey] = {};
        if (!acc[typeKey][accountKey]) acc[typeKey][accountKey] = [];
        acc[typeKey][accountKey].push(item);
        return acc;
    }, {});
};

const UpdateService = ({
  theme,
  selectedUser,
  accounts,
  setUserServicesSelected,
  updateUserAsync,
  language,
  onClose,
  show,
  selectedAccount,
  currentUser,
  loadUsersAsync,
}) => {
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedData, setSelectedData] = useState([]);
    const [initialServices, setInitialServices] = useState([]);

    useEffect(() => {
        const initialIds = Array.isArray(selectedUser?.servicesIds)
            ? selectedUser.servicesIds
            : [];
        setInitialServices(initialIds);
        setSelectedData(initialIds);
    }, [selectedUser]);

    const handleClose = () => {
        setUserServicesSelected({ key: "updatedServices", value: initialServices });
        onClose();
    };

    const handleSave = async () => {
        const hasChanges =
            JSON.stringify(selectedData.sort()) !==
            JSON.stringify(initialServices.sort());
        if (hasChanges) {
            const payload = {
                user: {
                    ...selectedUser,
                    servicesIds: selectedData,
                    idUserType: selectedUser?.idRol || selectedUser?.userType?.idUserType,
                },
                navigation: { navigate: () => {} },
            };
            try {
                await updateUserAsync(payload);
                setUserServicesSelected({ key: "updatedServices", value: selectedData });
                await loadUsersAsync({
                    id_services: accounts.find(acc => acc.idAccount === selectedAccount)?.services.map(service => service.idService) || [],
                    page: 1,
                });
            } catch (error) {
            }
        }
        onClose();
    };

    const handleServiceSelection = (service) => {
        const isSelected = selectedData.includes(service.idService);
        const isInitial = initialServices.includes(service.idService);

        if (!currentUser.capabilities?.show_users_admin_view && isInitial) {
            return;
        }

        if (isSelected) {
            setSelectedData(selectedData.filter((id) => id !== service.idService));
        } else {
            setSelectedData([...selectedData, service.idService]);
        }
    };

    const handleSelectAll = (group) => {
        const allServiceIds = Object.values(group.accounts)
            .flat()
            .map((service) => service.idService);

        const areAllSelected = allServiceIds.every(
            (id) => selectedData.includes(id) || initialServices.includes(id)
        );

        if (areAllSelected) {
            setSelectedData((prevSelected) =>
                prevSelected.filter(
                    (id) => !allServiceIds.includes(id) || initialServices.includes(id)
                )
            );
        } else {
            setSelectedData((prevSelected) => [
                ...new Set([...prevSelected, ...allServiceIds]),
            ]);
        }
    };

    const filteredServices = useMemo(() => {
        if (!Array.isArray(accounts) || accounts.length === 0 || !selectedAccount) {
            return [];
        }

        const account = accounts.find((acc) => acc.idAccount === selectedAccount);
        if (!account || !Array.isArray(account.services)) {
            return [];
        }

        const services = account.services.map((service) => ({
            ...service,
            account: { name: account.name },
        }));

        if (!currentUser.capabilities?.show_users_admin_view) {
            return services.filter((service) =>
                initialServices.includes(service.idService)
            );
        }

        return services.filter((service) => {
            const matchesSearch = searchQuery
                ? (service.name || "").toLowerCase().includes(searchQuery.toLowerCase()) ||
                  (service.address || "").toLowerCase().includes(searchQuery.toLowerCase()) ||
                  (service.account?.name || "").toLowerCase().includes(searchQuery.toLowerCase())
                : true;
            return matchesSearch;
        });
    }, [accounts, searchQuery, selectedAccount, currentUser, initialServices]);

    const groupedServices = useMemo(() => {
        const groupedByTypeAndAccount = groupByTypeAndAccount(filteredServices);

        const result = Object.keys(groupedByTypeAndAccount).map((type) => ({
            key: type,
            accounts: groupedByTypeAndAccount[type],
        }));

        return result;
    }, [filteredServices]);

    const hasChanges =
        JSON.stringify(selectedData.sort()) !==
        JSON.stringify(initialServices.sort());

    if (!show) return null;

    return (
        <Modal
            size="md"
            className={`rounded-24 surface-bg-fill border-fill-medium ${theme}`}
            show={show}
            backdropClassName={`w-100 h-100 surface-bg-blackout ${theme}`}
            onHide={handleClose}
            style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
        >
            <div style={{ height: "690px", flexDirection: "column" }}>
                <div className={`column-start align-items-start rounded-top-24 w-100 px-4 py-3 h-10 border-fill-medium-bottom ${theme}`}>
                    <div className="row-between align-items-center w-100 mb-2">
                        <div className={`text-center label-large-semibold content-default-primary w-90 ${theme}`}>
                            Servicios asignados
                        </div>
                        <button
                            onClick={handleClose}
                            className={`row-center align-items-center rounded-pill surface-bg-on-surface w-10 border-fill-medium ${theme}`}
                            style={{ width: 32, height: 32 }}
                        >
                            <i className={`fa-light fa-close txt-8 content-default-primary pe-pointer ${theme}`} />
                        </button>
                    </div>
                </div>
                <div
                    className={`position-relative column-start flex-grow-1 overflow-y-auto overflow-x-hidden w-100 surface-bg-surface px-4 ${theme}`}
                    style={{ height: "540px" }}
                >
                    <div className="row-start align-items-center gap-3 my-3">
                        <div className={`label-medium-regular content-default-primary ${theme}`}>
                            {selectedUser?.name || ""}
                        </div>
                        <div className={`label-medium-regular content-default-tertiary ${theme}`}>|</div>
                        <div className={`label-medium-regular content-default-tertiary ${theme}`}>
                            {selectedUser?.rol || ""}
                        </div>
                    </div>
                    {currentUser.capabilities?.show_users_admin_view && (
                        <InputRounded
                            icon="magnifying-glass"
                            placeholder="Buscar servicio"
                            type="text"
                            py={2}
                            value={searchQuery}
                            onChange={({ target }) => setSearchQuery(target.value)}
                        />
                    )}
                    {groupedServices.length > 0 ? (
                        groupedServices.map((group, index) => (
                            <div key={index} className="mt-3">
                                <div className="w-100 row-between align-items-end">
                                    <div className="row-start">
                                        <i
                                            className={`fak fa-${group.key.toLowerCase()}-symbol txt-12 me-2 content-default-secondary ${theme}`}
                                        />
                                        <p className={`label-medium-semibold content-default-secondary ${theme}`}>
                                            {group.key}
                                        </p>
                                    </div>
                                    {currentUser.capabilities?.show_users_admin_view && (
                                        <p
                                            className={`label-small-special-underline button-content-brand pe-pointer border-fill-medium-button ${theme}`}
                                            onClick={() => handleSelectAll(group)}
                                        >
                                            {Object.values(group.accounts)
                                                .flat()
                                                .every((s) =>
                                                    selectedData.includes(s.idService) ||
                                                    initialServices.includes(s.idService)
                                                )
                                                ? language["dashboard_label_picker_services_all_unselected"] ||
                                                  "Deseleccionar todos"
                                                : language["dashboard_label_picker_services_all_selected"] ||
                                                  "Seleccionar todos"}
                                        </p>
                                    )}
                                </div>
                                {Object.keys(group.accounts).map((accountName, accountIndex) => (
                                    <div key={accountIndex} className="mt-3">
                                        {group.accounts[accountName].map((service, serviceIndex) => {
                                            const isSelected = selectedData.includes(service.idService);
                                            const isInitial = initialServices.includes(service.idService);
                                            return (
                                                <div
                                                    key={serviceIndex}
                                                    className={`row-between align-items-center w-100 p-3 rounded-8 mt-3 ${theme} ${
                                                        isSelected ? "surface-bg-on-surface" : ""
                                                    }`}
                                                    onClick={() => handleServiceSelection(service)}
                                                    style={{
                                                        cursor:
                                                            !currentUser.capabilities?.show_users_admin_view && isInitial
                                                                ? "not-allowed"
                                                                : "pointer",
                                                    }}
                                                >
                                                    <div className="column-start">
                                                        <p
                                                            className={`label-medium-semibold content-default-primary ${theme}`}
                                                        >
                                                            {service.name}
                                                        </p>
                                                        <p
                                                            className={`label-medium-semibold content-default-primary pe-pointer ${theme}`}
                                                        >
                                                            {service.address || "Sin dirección"}
                                                        </p>
                                                    </div>
                                                    <i
                                                        className={`fa-regular fa-circle-check txt-10 ${
                                                            isSelected ? "content-color-green" : "content-default-primary"
                                                        } ${theme}`}
                                                    />
                                                </div>
                                            );
                                        })}
                                    </div>
                                ))}
                            </div>
                        ))
                    ) : (
                        <p className={`label-medium-medium content-default-tertiary text-center mt-3 ${theme}`}>
                            {language["dashboard_empty_state_picker_services"] || "No hay proyectos disponibles"}
                        </p>
                    )}
                </div>
                <div className={`column-end align-items-center rounded-bottom-24 w-100 px-4 py-2 h-10 ${theme}`}>
                    <div className={`row-end align-items-center w-100 gap-3 ${theme}`}>
                        <div
                            className={`row-center align-items-center py-1 px-2 gap-2 button-fill-disabled surface-bg-surface rounded-pill mt-3 ${theme}`}
                        >
                            <p className={`label-small-regular content-default-primary ${theme}`}>
                                {selectedData.length} {language["users_label_count_services"] || "seleccionados"}
                            </p>
                        </div>
                        {currentUser.capabilities?.show_users_admin_view ? (
                            <button
                                onClick={handleSave}
                                className={`row-center align-items-center py-2 px-3 gap-2 ${
                                    hasChanges
                                        ? "button-fill-brand"
                                        : "button-fill-disabled surface-bg-on-surface"
                                } rounded-pill pe-pointer mt-3 ${theme}`}
                                disabled={!hasChanges}
                            >
                                <p className={`label-medium-medium button-content-equal pe-pointer ${theme}`}>
                                    {language["dashboard_filter_done_button"] || "Hecho"}
                                </p>
                            </button>
                        ) : null}
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default UpdateService;