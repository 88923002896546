import * as pays from '../creators/Pays';

import { SETData, GETData } from '../../services/WebServices';

import { Alert } from 'rsuite';

export const setBanks = payload => {
  return {
      type: pays.SET_PAYS_BANKS,
      payload
  }
}

export const setInvoicesToPay = payload => {
  return {
      type: pays.SET_PAYS_INVOICES_TO_PAY,
      payload
  }
}

export const setTypeComponent = payload => {
  return {
      type: pays.SET_PAYS_TYPE_COMPONENT,
      payload
  }
}

export const setInfoPayment = payload => {
  return {
      type: pays.SET_PAYS_INFO_PAYMENT,
      payload
  }
}

export const setPaymentLoading = payload => {
  return {
      type: pays.SET_PAYS_PAYMENT_LOADING,
      payload
  }
}

export const setPaymentMethods = payload => {
  return {
      type: pays.SET_PAYS_PAYMENT_METHODS,
      payload
  }
}

export const setPaymentProvider = payload => {
  return {
      type: pays.SET_PAYS_PAYMENT_PROVIDER,
      payload
  }
}

export const loadBankDataAsync = () => {
  return (dispatch) => {     

    GETData("payvalida/banks", "GET")          
        .then(response => {                                     
            if (response !== null) {                  
                dispatch(setBanks(response.DATA));                  
            }          
        })
        .catch(response => console.error(response));

  }
}
export const loadPaymentMethodsAsync = () => {
  return (dispatch) => {

    GETData("payment/methods", "GET")          
        .then(response => {                                     
            if (response !== null) {             
              dispatch(setPaymentMethods(response));                  
            }          
        })
        .catch(response => console.error(response));

  }
}

export const setPsePaymentAsync = (payload) => {
  return (dispatch, getState) => {

    let infoPayment = getState().pays.infoPayment;

    const language = getState().shared.language;

    const pseProvider = payload.pseProvider;

    infoPayment.id_bills = payload.id_bills_array.filter(bill => bill.selected).map(bill => bill.value);
    dispatch(setPaymentLoading(true));
  
    SETData(`${pseProvider}/pse/transaction`, "POST", infoPayment)
        .then(response => {
            if (response !== null) {
                window.location.assign(response.URL_BANK);
            } else {
                Alert.error(language.alert_submit_form_popup_failed);
            }
        })
        .catch(() => Alert.error(language.alert_submit_form_popup_failed))
        .finally(() => dispatch(setPaymentLoading(false)));
  }
}

export const setCardPaymentAsync = (payload) => {
  return (dispatch, getState) => {

    let infoPayment = getState().pays.infoPayment;

    const language = getState().shared.language;

    infoPayment.id_bills = payload.id_bills_array.filter(bill => bill.selected).map(bill => bill.value);

    dispatch(setPaymentLoading(true));

    SETData(`openpay/card`, "POST", infoPayment)
        .then(response => {
            if (response.code === "SUCCESS") {

              window.location.assign(response.redirect_url);
              
            } else {

              let errorMessage = language[`invoices_card_payment_alert_${response.code.toLowerCase()}`] || language["alert_submit_form_popup_failed"];

              Alert.error(errorMessage);
            }
        })
        .catch(() => Alert.error(language.alert_submit_form_popup_failed))
        .finally(() => dispatch(setPaymentLoading(false)));
  }
}