import React from "react"

import ThemedImage from "../utils/ThemedImage"

export const stepsWalktrough = (language) => {
  return ([
    {
      content: (
        <div className="row-center align-items-center w-100 rounded-8 mt-3" style={{ height: 162, width: 300 }}>
          <ThemedImage imageName={'step1-walktrough.png'}/>
        </div>
      ),
      title: language.onboarding_walktrough_title,
      description: language.onboarding_walktrough_description,
      placement: "center",     
      target: "#tooltip_walktrough",
      disableBeacon: true,
      steps: 3,
      walktrough: true
    }    
  ])
}