import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { Animation, Drawer, Alert, Loader } from "rsuite";

import Input from "./utilities/Input";

import { SETData } from "../../services/WebServices";
import BackButton from "./utilities/BackButton";
import MultipleSelect from "./utilities/MultipleSelect";

const isValidateEmail = (email) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

const isValidUser = (user) => {
  if (user.name === null || user.name === "") {
    return false;
  }

  if (user.lastname === null || user.lastname === "") {
    return false;
  }

  if (!isValidateEmail(user.email)) {
    return false;
  }

  if (user.phone === null || user.phone === "") {
    return false;
  }

  if (user.address === null || user.address === "") {
    return false;
  }

  if (user.idService === null || user.idService === "") {
    return false;
  }

  if (user.ticketDescription === null || user.ticketDescription === "") {
    return false;
  }

  return true;
};

const showError = (user, setFocus, setError, setShowPersonalData, language) => {
  if (user.name === null || user.name === "") {
    setFocus(1);
    setError(language.alert_user_popup_name);
    setShowPersonalData(true);
    return;
  }

  if (user.lastname === null || user.lastname === "") {
    setFocus(2);
    setError(language.alert_user_popup_lastname);
    setShowPersonalData(true);
    return;
  }

  if (!isValidateEmail(user.email)) {
    setFocus(3);
    setError(language.alert_user_popup_email);
    setShowPersonalData(true);
    return;
  }

  if (user.phone === null || user.phone === "") {
    setFocus(4);
    setError(language.alert_user_popup_phone);
    setShowPersonalData(true);
    return;
  }

  if (user.address === null || user.address === "") {
    setFocus(5);
    setError(language.alert_user_popup_address);
    setShowPersonalData(true);
    return;
  }

  if (user.idService === null || user.idService === "") {
    setFocus(9);
    setError(language.alert_support_popup_ticket_service);
    return;
  }

  if (user.ticketDescription === null || user.ticketDescription === "") {
    setFocus(8);
    setError(language.alert_support_popup_ticket_description);
    return;
  }

  return setFocus(0);
};

const Support = (props) => {

  const initTicket = {
    idUser: props.user.idUser,
    idService: "",
    idDocument: props.user.idDocument,
    name: props.user.name,
    lastname: props.user.lastname,
    email: props.user.email,
    phone: props.user.phone,
    address: props.user.address,
    ticketDescription: "",
    file: "",
  };

  const [user, setUser] = useState(initTicket);
  const [isEnable, setEnable] = useState(false);
  const [personalData, setShowPersonalData] = useState(false);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [focus, setFocus] = useState(0);
  const [error, setError] = useState("");
  const [authData, setAuthData] = useState(false);

  let language = props.language;

  const { theme } = props;

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (files.length) {
      setFile(files[0]);
    }
  };

  const handleSubmit = async e => {
    e.preventDefault()
    if (isEnable && authData) {
      setLoading(true)

      const selectedService = props.totalServices.find(service => service.idService === parseInt(user.idService))
      const serviceType = selectedService.type.name
      let fileData = {}
      let errorFlag = false 

      if (file !== null) {
        fileData = {
          fileName: `pqrs-files/Support-${Date.now()}-${file.name}`,
          bucket: "neu-public",
          contentType: file.type,
        }

        SETData(`web/support/upload`, "POST", fileData).then(response => {
          if (response.body) {
            const responseBody = JSON.parse(response.body)
            const signedUrl = responseBody.url

            fetch(signedUrl, {
              method: "PUT",
              headers: {
                "Content-Type": fileData.contentType,
              },
              body: file,
            }).then(response => {
              if (!response.ok) {
                console.error("Error en la carga de la imagen", response)
                Alert.error(props.language.alert_submit_form_popup_failed, 6000)
                errorFlag = true
              }
            })
          } else {
            Alert.error(props.language.alert_submit_form_popup_failed, 6000)
            console.error(error)
            setLoading(false)
            errorFlag = true
          }
        })
      }

      if(errorFlag) return

      const dataZoho = {
        company: selectedService.name,
        clientType: "Industrial o comercial",
        name: user.name,
        lastname: user.lastname,
        phone: user.phone,
        email: user.email,
        ticketDescription: user.ticketDescription,
        channel: "Platforms",
        presignedUrl: file ? `https://dqeqym4vbi7vy.cloudfront.net/${fileData.fileName}` : null,
        type: serviceType === "generation" ? "erco" : "neu",
      }

      SETData("web/support/create", "POST", dataZoho).then(response => {
        if (!response) {
          Alert.error(props.language.alert_submit_form_popup_failed, 6000)
        } else {
          const responseBody = JSON.parse(response.body)
  
          if (responseBody.message === "Ticket created") {
            props.setShowSupport(false)
            Alert.success(props.language.alert_support_popup_success, 7000)
          }
        }
        setLoading(false)
        setFile(null)
        setUser({ ...user, ticketDescription: "" })
      })
    } else {
      showError(user, setFocus, setError, setShowPersonalData, language)
    }
  }

  useEffect(() => {
    setEnable(isValidUser(user));
  }, [setEnable, user]);

  useEffect(() => {
    if (props.totalServices.length === 1) {
      setUser((prevUser) => ({ ...prevUser, idService: props.totalServices[0].idService }));
    } else {
      setUser((prevUser) => ({ ...prevUser, idService: "" }));
    }
  }, [props.totalServices]);

  useEffect(() => {
    if (focus !== 0) { showError(user, setFocus, setError, setShowPersonalData, language) }
  }, [user, language, focus]);

  const optionsData = [{ name: props.language.support_select_placeholder, idService: "" }, ...props.totalServices]

  return (
    <Drawer
      show={props.showSupport}
      size={"xs"}
      placement={"right"}
      backdropClassName={`pe-pointer ${theme}`}
      onHide={() => props.setShowSupport(false)}
    >
      <form className={`px-4 overflow-y-scroll vh-100 py-4 surface-bg-surface ${theme}`} onSubmit={handleSubmit}>
        <div className="row-between align-items-center w-100">
          <BackButton theme={theme} onClick={() => props.setShowSupport(false)}></BackButton>
          <p className={`heading-h6-bold content-default-primary ${theme}`}>{props.language.support_main_title_drawer}</p>
        </div>
        <div className="row-center w-100">
          <div className={`text-wrap text-center my-3 label-medium-regular content-default-primary ${theme}`}>
            {props.language.support_main_description_drawer}
          </div>
        </div>
        <div className="w-100 pb-4">
          <div className={`column-start align-items-center w-100 p-3 rounded-16 border-fill-medium surface-bg-on-surface ${theme}`}>
            <div
              className="row-between align-items-center w-100 pe-pointer"
              onClick={() => { setShowPersonalData(!personalData); }}
            >
              <div className="row-start align-items-center">
                <i className={`fa-regular fa-user txt-10 content-default-secondary ${theme}`}></i>
                <div className={`label-small-medium ms-3 pe-pointer content-default-primary ${theme}`}>{props.language.support_title_section_personal_data}</div>
              </div>
              <i
                className={`fa-regular fa-circle-chevron-right txt-10 pe-pointer content-default-secondary ${theme} ${!personalData ? "account-detail-open" : "account-detail-close"
                  }`}
              ></i>
            </div>
            <Animation.Collapse in={personalData} dimension={"height"}>
              <div className="column-start align-items-center w-100">
                <div className="w-100 py-4">
                  <div className="w-100 my-2">
                    <Input disabled label={props.language.support_input_label_id_document} type={"text"} value={user.idDocument} />
                  </div>
                  <div className="w-100 my-2">
                    <Input
                      label={props.language.support_input_label_user_name}
                      type={"text"}
                      value={user.name}
                      onChange={({ target }) => {
                        setUser({ ...user, name: target.value });
                      }}
                      error={focus === 1 ? error : ""}
                    />
                  </div>
                  <div className="w-100 my-2">
                    <Input
                      label={props.language.support_input_label_user_lastname}
                      type={"text"}
                      value={user.lastname}
                      onChange={({ target }) => {
                        setUser({ ...user, lastname: target.value });
                      }}
                      error={focus === 2 ? error : ""}
                    />
                  </div>
                  <div className="w-100 my-2">
                    <Input
                      label={props.language.support_input_label_user_email}
                      type={"email"}
                      value={user.email}
                      onChange={({ target }) => {
                        setUser({ ...user, email: target.value });
                      }}
                      error={focus === 3 ? error : ""}
                    />
                  </div>
                  <div className="w-100 my-2">
                    <Input
                      label={props.language.support_input_label_user_phone}
                      type={"tel"}
                      value={user.phone}
                      onChange={({ target }) => {
                        setUser({ ...user, phone: target.value });
                      }}
                      error={focus === 4 ? error : ""}
                    />
                  </div>
                  <div className="w-100 my-2">
                    <Input
                      label={props.language.support_input_label_user_address}
                      type={"text"}
                      value={user.address}
                      onChange={({ target }) => {
                        setUser({ ...user, address: target.value });
                      }}
                      error={focus === 5 ? error : ""}
                    />
                  </div>
                </div>
              </div>
            </Animation.Collapse>
          </div>
        </div>
        <div className="column-start align-items-start w-100">
          <div className="w-100">
             <MultipleSelect
              options={ props.totalServices.length === 1 ? [props.totalServices[0]] : optionsData}
              selectedData={user.idService ? [user.idService] : []} 
              singleSelect={true} 
              onChange={(data) =>{console.log(data); setUser({ ...user, idService: data[0] })}} 
              width={325}
              keyName='idService'
              labelName='name'
              label={'Servicio'}
              value={props.totalServices.length === 1 ? props.totalServices[0].name : optionsData.filter((option) => option.idService === user.idService)[0].name}
              />
          </div>
        </div>
        <div className="w-100 my-4">
          <Input label={props.language.support_title_section_detail_ticket} value={user.ticketDescription ?? ""}
            onChange={({ target }) => setUser({ ...user, ticketDescription: target.value })}
            error={focus === 8 ? error : ""}
            placeholder={props.language.support_input_label_request_ticket}
            />
        </div>
        <div className={`w-100 label-medium-medium content-default-primary ${theme}`}>{props.language.support_title_section_upload_file}</div>
        <div className={`my-2 text-wrap label-medium-medium content-default-primary ${theme}`}>
          {props.language.support_description_section_upload_file}
        </div>
        <div
          className={`column-center align-items-center gap-4 py-4 mt-4 rounded-24 border-fill-medium surface-bg-on-surface ${theme}`}
          onDrop={handleDrop}
          onDragOver={handleDragOver}>
          <i className={`fa-regular fa-upload txt-24 content-color-green ${theme}`}></i>
          <div className="column-center align-items-center gap-2">
            <p className={`heading-h6-semibold content-default-primary text-center ${theme}`}>{props.language.support_drag_or_select_file}</p>
            {file ? (
            <div className={`row-between align-items-center gap-2 rounded-30 px-2 py-1`}>
              <p className={`max-width-96 text-truncate paragraph-XSmall-regular content-default-tertiary ${theme}`}>{file.name}</p>
              <i
              className={`fa-light fa-circle-xmark pe-pointer txt-8 content-default-tertiary ${theme}`}
              onClick={() => {
                setFile(null);
              }}></i>
            </div>
            ) : (
            <p className={`paragraph-XSmall-regular content-default-tertiary text-center ${theme} mt-0`}>{props.language.support_upload_files}</p>
             )}
          </div>
          <button
            className={`rounded-30 px-3 py-2 button-content-default label-small-regular button-border-outline surface-bg-on-surface ${theme}`}
            onClick={() => document.getElementById('pqrFile').click()}>
           <p className={`button-content-default label-small-regular ${theme}`}>{props.language.support_select_file}</p>
          </button>
          <input id="pqrFile" type="file" name="pqrFile" accept="image/*, .pdf, .doc, .docx" 
            onChange={(e) => {
              const selectedFile = e.target.files[0];

                if (selectedFile) {
                  setFile(selectedFile);
                  e.target.value = null;
                  }
              }} 
            style={{ display: 'none' }} />
        </div>
        <div className={`row-center align-items-between w-100 my-4`}>
          <i
            className={`fa-check-circle pe-pointer ${
              authData
                ? "content-color-green fa-solid"
                : "content-default-secondary fal"
            } txt-10 ${theme}`}
            onClick={() => setAuthData(!authData)}
          ></i>
          <div className={`w-100 label-medium-medium content-default-secondary mx-2 ${theme}`}>{props.language.authorize_personal_data} <p className="text-decoration-underline pe-pointer" onClick={() => window.open("https://d95q7df0kkrx0.cloudfront.net/website-media-content/Generales/Documentos/POLITICA-DE-PRIVACIDAD-DE-DATOS-V2.pdf")}>{props.language.policy_personal_data}</p></div>
        </div>
        <button
          disabled={loading}
          type="submit"
          className={`row-center align-items-center rounded-30 py-2 px-3 mt-4 pe-pointer w-100 label-small-regular button-fill-${authData ? "brand" : "disabled"} button-content-${authData ? "equal" : "disabled"} ${theme}`}
        >
          {loading ? <Loader size="sm" /> : props.language.support_button_label_send_form}
        </button>
      </form>
    </Drawer>
  );
};

const mapStateToProps = (state) => ({
  user: state.users.user,
  language: state.shared.language,
  totalServices: state.services.totalServices,
  theme: state.theme.theme,
});

export default connect(mapStateToProps)(Support);
