import React, { useEffect, useState, Fragment } from "react";
import { connect } from "react-redux";

import * as notificationsActions from "../../store/actions/Notifications";
import * as alertsActions from "../../store/actions/Alerts";

import emptyData from '../../imgs/empty-data-notifications.svg';
import emptyDataLight from '../../imgs/empty-data-notifications-light.svg';

import emptyDataAlerts from '../../imgs/empty_state_alerts.svg';

import { Drawer } from "rsuite";

import ContentLoader from "react-content-loader";

import NotificationBox from "./NotificationBox";

import NewAlert from '../alerts/NewAlert';

import { track } from "@amplitude/analytics-browser";
import BackButton from "../shared/utilities/BackButton";

const NotificationsLoading = ({theme}) => {

  const height = 85;

  return (
    <div className="position-absolute w-100 h-100 left-0 top-0 zindex-1 py-1">
      <ContentLoader
        seed={2}
        backgroundColor={theme === "light" ? "#ffffff" : "#141414"}
        foregroundColor={theme === "light" ? "#d4d4d4" : "#262626"}
        style={{ width: "100%", height: "100%" }}>
        {Array.from({ length: 10 }, (_x, i) => i).map((i) => <rect key={i} x="0" y={`${i * (height + 10)}`} width="100%" height={height} rx="8" ry="8"></rect>)}
      </ContentLoader>
    </div>
  )
};

const Notifications = (props) => {
  const currentUrl = new URL(window.location.href);
  const isDashboard = currentUrl.pathname === '/home'

  let notifications = props.notifications;
  let alertRegisters = props.alertRegisters;
  let notificationsLoading = props.notificationsLoading;
  let notificationsPagination = props.notificationsPagination;
  let alertRegistersPagination = props.alertRegistersPagination;
  let showBlackScreenDrawer = props.showBlackScreenDrawer;

  const loadNotificationsAsync = props.loadNotificationsAsync;
  const loadAlertRegistersAsync = props.loadAlertRegistersAsync;
  const loadTotalNotificationsAsync = props.loadTotalNotificationsAsync;

  const { theme } = props;

  const [type, setType] = useState(0);
  const [showNewAlert, setShowNewAlert] = useState(false);

  const handleScroll = (target) => {
    if (type === 0) {
      if (target.scrollTop + target.clientHeight >= (target.scrollHeight - 120) && notificationsPagination != null) {
        loadNotificationsAsync();
      }
    }
    if (type === 1) {
      if (target.scrollTop + target.clientHeight >= (target.scrollHeight - 120) && alertRegistersPagination != null) {
        loadAlertRegistersAsync();
      }
    }
  }

  const handleClose = () => {
    props.setShowNotifications(false)
    loadTotalNotificationsAsync()
  }

  useEffect(() => {
    if (!notifications.length && type === 0) {
      loadNotificationsAsync();
    }
    if (!alertRegisters.length && type === 1) {
      loadAlertRegistersAsync();
    }
  }, [notifications, loadNotificationsAsync, type, loadAlertRegistersAsync, alertRegisters]);

  return (
    <Drawer
      size="xs"
      placement="right"
      show={props.showNotifications}
      onEntering={() => track('Notification viewed')}
      backdropClassName={`pe-pointer ${theme}`}>
      <div className={`h-100 w-100 column-start overflow-auto pt-3 px-3 surface-bg-surface ${theme}`} onScroll={({ target }) => handleScroll(target)}>
        {showBlackScreenDrawer &&
          <div className="position-absolute left-0 top-0 h-100 w-100 zindex-5">
            <div className={`w-100 h-100 surface-bg-blackout ${theme}`} />
          </div>}
        <div className="row-between align-items-center w-100">
          <BackButton theme={theme} onClick={() => handleClose()}></BackButton>
          <p className={`heading-h6-bold content-default-primary ${theme}`}>{props.language.notifications_title}</p>
        </div>
        <div className="my-3">
          <div className={`position-relative row-between py-2 rounded-30 surface-bg-on-surface-alpha border-fill-medium ${theme}`}>
            <div className="row-center align-items-center h-100 col-6 zindex-2 pe-pointer" onClick={() => setType(0)}>
              <div className={`label-small-regular pe-pointer content-default-primary ${theme}`}>{props.language.notifications_show_all}</div>
            </div>
            <div className="row-center align-items-center h-100 col-6 zindex-2 pe-pointer" onClick={() => { setType(1) }}>
              <div className={`label-small-regular pe-pointer content-default-primary ${theme}`}>{props.language.notifications_show_alerts}</div>
            </div>
            <div id="notifications-type" className={`position-absolute h-100 top-0 col-6 rounded-30 zindex-1 surface-bg-surface ${theme}`} style={{ left: `${type * 50}%` }}></div>
          </div>
        </div>
        {type === 0 ?
          <Fragment>
            {
              notifications.length ?
                notifications.map((notification) => {
                  return (
                    <div key={notification.id_register}>
                      <NotificationBox notification={notification} theme={theme} />
                    </div>
                  );
                }) : null
            }
            {
              !notifications.length && !notificationsLoading &&
              <div className="w-100 h-100 column-center align-items-center px-3 overflow-hidden">
                <img src={theme === 'dark' ? emptyData : emptyDataLight} className="my-4" alt="logo-empty-data-notifications" />
                <div className={`text-center text-wrap label-medium-semibold content-default-tertiary ${theme}`}>{props.language.notifications_empty_state_title}</div>
                <div className={`text-center py-3 label-small-regular content-default-tertiary ${theme}`}>{props.language.notifications_empty_state_text}</div>
            </div>
            }
            {
              (notificationsLoading || notificationsPagination != null) ?
                <div className="position-relative w-100 h-100 min-height-200">
                  <NotificationsLoading theme={theme}/>
                </div> : <div className="py-2" />
            }
          </Fragment> :
          <Fragment>
            {!isDashboard &&
              <>
                <div className='column-center w-100 align-items-center rounded-16'>
                  <button className={`row-center align-items-center p-2 gap-2 rounded-30 button-fill-brand w-100 my-3 ${theme}`} onClick={() => { setShowNewAlert(true) }}>
                    <i className={`fas fa-plus button-content-equal txt-10 ${theme}`}></i>
                    <p className={`button-content-equal label-small-regular pe-pointer ${theme}`}>{props.language.alerts_button_add_new_alert}</p>
                  </button>
                </div>
                <NewAlert
                  showNewAlert={showNewAlert}
                  setShowNewAlert={setShowNewAlert}
                  setAlertSelected={() => { }}
                  setEditAlert={() => { }} />
              </>
            }
            {
              alertRegisters.length ?
                alertRegisters.map((notification) => {
                  return (
                    <div key={notification.id_register}>
                      <NotificationBox notification={notification} theme={theme}/>
                    </div>
                  );
                }) : null
            }
            {
              !alertRegisters.length && !notificationsLoading &&
              <div className="w-100 h-100 column-center align-items-center px-3 overflow-hidden">
                <img src={emptyDataAlerts} className="my-4" alt="logo-empty-data-alerts" />
                <div className="txt-phrase txt-semibold txt-10 txt-gray-400 text-center text-wrap">{props.language.alerts_label_title_empty_state_registers}</div>
                <div className="txt-phrase txt-8 txt-gray-400 text-center py-3">{props.language.alerts_label_body_empty_state_registers}</div>
              </div>
            }
          </Fragment>
        }
      </div>
    </Drawer>
  );
};

const mapStateToProps = (state) => ({
  language: state.shared.language,
  notifications: state.notifications.notifications,
  notificationsPagination: state.notifications.notificationsPagination,
  notificationsLoading: state.notifications.notificationsLoading,
  showBlackScreenDrawer: state.shared.showBlackScreenDrawer,
  alertRegisters: state.alerts.alertRegisters,
  alertRegistersPagination: state.alerts.alertRegistersPagination,
  theme: state.theme.theme,
});

const mapDispatchToProps = (dispatch) => ({
  loadNotificationsAsync: () => dispatch(notificationsActions.loadNotificationsAsync()),
  loadTotalNotificationsAsync: () => dispatch(notificationsActions.loadTotalNotificationsAsync()),
  loadAlertRegistersAsync: () => dispatch(alertsActions.loadAlertRegistersAsync())
});

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);


