import * as creators from '../creators/Services';

const servicesTypes = [
    {
      key: 'energy',
      title: 'Energía',
      icon: 'energy',
    },
    {
      key: 'plus',
      title: 'Plus',
      icon: 'plus'
    },
    {
      key: 'zero',
      title: 'Financieros',
      icon: 'zero'
    },
    {
      key: 'generation',
      title: 'Generación',
      icon: 'generation'
    }
  ];

const initialState = {
    servicesTypes,
    totalServices: [],
    totalServicesGrouped: [],
    activeServicesEnergy: [],
    activeServicesPlus: [],
    activeServicesInvoices: [],
    activeServicePreonboarding: null,
    defaultActiveService: null,
    usersByService: {}
}

export const servicesReducer = (state = initialState, { type, payload }) => {

    switch (type) {
        case creators.SET_TOTAL_SERVICES:
          return { ...state, totalServices: payload };
        case creators.SET_TOTAL_SERVICES_GROUPED:
          return { ...state, totalServicesGrouped: payload };
        case creators.SET_ACTIVE_SERVICES_ENERGY:
          return { ...state, activeServicesEnergy: payload };
        case creators.SET_ACTIVE_SERVICES_PLUS:
          return { ...state, activeServicesPlus: payload };
        case creators.SET_ACTIVE_SERVICES_INVOICES:
          return { ...state, activeServicesInvoices: payload };
        case creators.SET_ACTIVE_SERVICE_PREONBOARDING:
          return { ...state, activeServicePreonboarding: payload };
        case creators.SET_DEFAULT_ACTIVE_SERVICE:
          return { ...state, defaultActiveService: payload };
        case creators.SET_USERS_BY_SERVICE:
          return { ...state, usersByService: {
            ...state.usersByService,
            [payload.idService]: payload.users
          }};
        default:
            return state;
    }

}
