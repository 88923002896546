import React, { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { far } from '@fortawesome/pro-regular-svg-icons';

import { connect } from 'react-redux';

import DarkIncompleteUserInfoFinishedSVG from '../../imgs/incomplete_user_info_finished_dark.svg'
import LightIncompleteUserInfoFinishedSVG from '../../imgs/incomplete_user_info_finished_light.svg'

import { GETData, SETData } from '../../services/WebServices';

import { Modal, Whisper, Tooltip } from "rsuite"

import Select from '../shared/utilities/Select';

import { themes } from '../../utils/enums';

const IncompleteUserinfoModal = ({
    theme,
    user,
    language
}) => {

    const MODAL_VIEWS = {
        FORM: 0,
        FINISH: 1
    }

    const [modalView, setModalView] = useState(MODAL_VIEWS.FORM);
    const [openModal, setOpenModal] = useState(false);
    const [businessUnitsList, setBusinessUnitsList] = useState([]);
    const [preferencesList, setPreferencesList] = useState([]);
    const [businessUnitSelected, setBusinessUnitSelected] = useState(null);
    const [preferencesSelected, setPreferencesSelected] = useState([]);
    const [savingPreferenceData, setSavingPreferenceData] = useState(false);

    const validateFields = () => {

        if (modalView === MODAL_VIEWS.FINISH ) return false;

        const errors = {}

        if (preferencesSelected.length === 0) {
            errors.preferencesSelected = true
        }

        if (!businessUnitSelected) {
            errors.businessUnitSelected = true
        }

        return Object.values(errors).length > 0
    }

    const handleClose = () => {
        setOpenModal(false);
    };

    const getBusinessUnits = async () => {
        try {
            const businessUnits = await GETData('usersdev/businessUnits/19', 'GET');
            if (businessUnits && businessUnits.length) {
                setBusinessUnitsList(businessUnits);
            }
        } catch (err) {
            console.error(err)
        }
    };

    const getPreferences = async () => {
        try {
            const preferences = await GETData('users/users/interests', 'GET');
            if (preferences && preferences.length) {
                setPreferencesList(preferences);
            }
        } catch (err) {
            console.error(err)
        }
    };

    const handleClickPreference = (currentPreference) => {
        if (preferencesSelected.includes(currentPreference.idInterest)) {
            setPreferencesSelected(preferencesSelected.filter(p => p !== currentPreference.idInterest))
        } else {
            setPreferencesSelected([...preferencesSelected, currentPreference.idInterest])
        }
    }

    const onSave = async () => {
        if (modalView === MODAL_VIEWS.FORM) {
            const businessUnitFound = businessUnitsList.find(currentBusinnesUnit => currentBusinnesUnit.idBusinessUnits === businessUnitSelected)
            if (!user || !businessUnitSelected || validateFields() || !businessUnitFound) return;
            try {
                setSavingPreferenceData(true);
                await SETData('usersdev/users/preferencedata', 'POST', {
                    idUser: user.idUser,
                    personalInterestIds: preferencesSelected,
                    channelIds: [],
                    usePlatform: '',
                    idBusinessUnit: businessUnitSelected,
                    customProfile: businessUnitFound.customProfile
                });
                setModalView(MODAL_VIEWS.FINISH)
            } catch (err) {
                console.error(err)
            } finally {
                setSavingPreferenceData(false)
            }
            return;
        }
        setOpenModal(false);
    }

    useEffect(() => {
        getBusinessUnits();
        getPreferences()
    }, []);

    useEffect(() => {
        if (user.capabilities.show_section_onboarding) {
            setOpenModal(true)
        }
        // setOpenModal(true)
    }, [user])

    return (
        <Modal
            size={modalView === MODAL_VIEWS.FORM ? 'sm' : 'xs'}
            className={`rounded-24 align-items-centers ${theme}`}
            show={openModal}
            backdropClassName={`backdrop-surface-bg-blackout ${theme}`}
            onHide={handleClose}
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
            <div
                className={`column-between border-fill-medium surface-bg-surface align-items-start rounded-24 w-100 ${theme}`}
                style={{ minWidth: modalView === MODAL_VIEWS.FORM ? '50vh' : '30vw' }}
            >
                <div
                    className={`row-between align-items-center py-3 px-3 surface-bg-fill w-100 rounded-top-24 border-fill-regular-bottom ${theme}`}
                >
                    <div className='w-100 row-center align-items-center'>
                        <p className={`label-large-semibold content-default-primary ${theme}`}>
                            {language[modalView === MODAL_VIEWS.FINISH ? 'incomplete_user_info_modal_title_finished' : 'incomplete_user_info_modal_title']}
                        </p>
                    </div>
                </div>
                {
                    modalView === MODAL_VIEWS.FORM ? (
                        <div className='flex-grow-1 column-start items-align-center gap-3 w-100 p-3'>
                            <div className="w-100 column-start items-align-center text-center">
                                <p className={`label-medium-regular content-default-primary ${theme}`}>
                                    {language['incomplete_user_info_modal_description_1']}
                                </p>
                                <p className={`label-medium-regular content-default-primary ${theme}`}>
                                    {language['incomplete_user_info_modal_description_2']}
                                </p>
                            </div>
                            <div className={`w-100 py-4`}>
                                <div className='w-100 h-100 column-start gap-1 select-container' style={{ columnSpan: '1' }}>
                                    <p className={`label-medium-medium content-default-primary ${theme}`}>{language.incomplete_user_info_modal_business_units_label}</p>
                                    <Select
                                        backdropClassName={'bg-black opacity-30 pe-pointer'}
                                        value={businessUnitSelected}
                                        options={businessUnitsList}
                                        onChange={(value) => {
                                            setBusinessUnitSelected(value);
                                        }}
                                        labelName={'valueText'}
                                        keyName='idBusinessUnits'
                                        defaultMessage="Tu área"
                                        background={`surface-bg-surface`}
                                        width={'560px'}
                                        height={150}
                                        completeHeight
                                        type_label='label-medium-medium'
                                        renderOptionContainer={({ children, width, height }) => (
                                            <div
                                                className={`column-start align-items-center border-fill-regular overflow-auto px-2 py-1 rounded-16 surface-bg-surface scroll-without-border ${theme}`}
                                                style={{ width: width, maxHeight: height }}
                                            >
                                                {children}
                                            </div>
                                        )}
                                        renderCustomValue={(languageKey) => language[languageKey]}
                                        renderCustomOption={({ value, item, onClick, isSelected }) => (
                                            <div
                                                key={value}
                                                className={`row-between w-100 align-items-center pe-pointer py-2 ps-2 my-1 rounded-8 ${isSelected ? 'px-2' : 'ps-2'
                                                    } ${isSelected
                                                        ? "surface-bg-on-surface"
                                                        : "surface-bg-surface"
                                                    } ${theme}`}
                                                onClick={onClick}
                                            >
                                                <div className="row-between align-items-center w-100">
                                                    <div
                                                        className={`label-small-medium content-default-primary ${theme}`}
                                                    >
                                                        {language[item.valueText]}
                                                    </div>
                                                    {isSelected ? (
                                                        <i
                                                            className={`fa-solid fa-check-circle content-color-green txt-10 ${theme}`}
                                                        />
                                                    ) : (
                                                        <>
                                                            {
                                                                language[`${item.valueTextDescription}`] ? (
                                                                    <Whisper
                                                                        delay={200}
                                                                        placement="top"
                                                                        trigger="hover"
                                                                        container={document.body}
                                                                        speaker={
                                                                            <Tooltip
                                                                                className='incomplete-user-info-modal'
                                                                                style={{ zIndex: 10001 }}
                                                                            >
                                                                                {language[`tooltip_${item.valueText}`]}
                                                                            </Tooltip>
                                                                        }>
                                                                        <FontAwesomeIcon
                                                                            icon={far["faCircleInfo"]}
                                                                            className={`txt-10 content-default-secondary ${theme}`}
                                                                        />
                                                                    </Whisper>
                                                                ) : <></>
                                                            }
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                    />
                                </div>
                            </div>
                            <h1 className={`heading-h6-bold content-default-primary ${theme}`}>{language['incomplete_user_info_modal_preferences_title']}</h1>
                            <div className={`w-100 flex-wrap row-start align-items-start gap-3`}>
                                {
                                    preferencesList.map(currentPreference => {
                                        const isSelected = preferencesSelected.includes(currentPreference.idInterest)
                                        return (
                                            <div
                                                key={`preference:${currentPreference.idInterest}`}
                                                onClick={() => handleClickPreference(currentPreference)}
                                                className={`pe-pointer rounded-pill ${isSelected ? 'border-color-green' : 'surface-bg-on-surface'} ${theme}`}
                                            >
                                                <div
                                                    className={`row-center align-items-center px-3 py-2 content-default-primary label-small-medium  ${theme}`}>
                                                    {language[currentPreference.valueText]}
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    ) : (
                        <div className="w-100 column-center align-items-center gap-3 py-4">
                            <img src={theme === themes.DARK ? DarkIncompleteUserInfoFinishedSVG : LightIncompleteUserInfoFinishedSVG} alt="finished_incomplete_user_info_img" />
                            <p className={`content-default-primary label-medium-medium text-center ${theme}`} style={{ maxWidth: '250px' }}>
                                { language.incomplete_user_info_modal_finished_text }
                            </p>
                        </div>
                    )
                }
                <div
                    className={`row-end align-items-center w-100 p-3 gap-2 surface-bg-fill w-100 border-fill-regular-top rounded-bottom-24 ${theme}`}
                >
                    {
                        modalView !== MODAL_VIEWS.FINISH ? (
                            <>
                                <span className={`py-1 px-2 label-small-regular content-default-primary surface-on-surface-hover rounded-pill ${theme}`}>
                                    {language["incomplete_user_info_modal_footer_label"]}
                                </span>
                                <button
                                    onClick={() => setOpenModal(false)}
                                    className={`row-center align-items-center py-2 px-3 gap-2 button-border-outline surface-bg-fill rounded-pill pe-pointer ${theme}`}
                                >
                                    <p className={`label-small-medium button-content-default ${theme}`}>
                                        {language['incomplete_user_info_modal_omit_button']}
                                    </p>
                                </button>
                            </>
                        ) : <></>
                    }
                    <button
                        onClick={onSave}
                        className={`row-center align-items-center py-2 px-3 gap-2 ${validateFields() ? 'border-fill-medium button-fill-disabled button-content-disabled' : 'button-fill-brand button-content-equal'} label-medium-medium rounded-pill pe-pointer ${theme}`}
                        disabled={validateFields()}
                    >
                        <p className={`label-small-medium ${theme}`}>
                            {
                                modalView === MODAL_VIEWS.FINISH ? (
                                    language['incomplete_user_info_modal_save_button_finish']
                                ) : (
                                    (savingPreferenceData) ? language['incomplete_user_info_modal_save_button_loading'] : language['incomplete_user_info_modal_save_button']
                                )
                            }
                        </p>
                    </button>
                </div>
            </div>
        </Modal>
    )
}

const mapStateToProps = (state) => ({
    theme: state.theme.theme,
    user: state.users.user,
    language: state.shared.language,
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(IncompleteUserinfoModal);