import React, {useRef} from "react";
import { connect } from 'react-redux';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Navigation } from "swiper";

import { useWindowSize } from '../../../shared/utilities/useWindowSize';

import empty_widgets_slider from "../../../../imgs/empty-widgets-slider.svg";
import empty_widgets_slider_light from "../../../../imgs/empty_widgets_slider_light.svg";

import { track } from "@amplitude/analytics-browser";

SwiperCore.use([Autoplay, Navigation]);


const RenderWidgets = ({ item, theme }) => {
    
    const hasHovered = useRef(false);

    const handleHover = () => {
        if (!hasHovered.current) {
            hasHovered.current = true;            
            track('Plus widget hover', {Plus_Widget_ID: item?.title});
        }
    };

    const handleLeave = () => {
        hasHovered.current = false;
    };

    return (
        <div
            className={`swiper-slider-container column-between align-items-center surface-bg-surface border-fill-medium rounded-16 p-3 w-100 ${theme}`}
            style={{ height: 120 }}
            onMouseOver={handleHover}
            onMouseLeave={handleLeave}
        >
            <p className={`label-XSmall-regular content-default-secondary ${theme}`}>{item.title}</p>
            <div className={`row-center align-items-center ${item.color}`}>
                {item.icon && <i className={`fa-regular heading-h6-bold me-3 fa-${item.icon}`} />}
                <p className="heading-h6-bold">{item.value}</p>
            </div>
            <div className="row-center align-items-center">
                {item.kpi && <i className={`fa-regular fa-chevron-${item.increment ? "up" : "down"} me-2 ${item.positive ? "txt-red-500" : "txt-green-500"}`} />}
                <p className={`label-XSmall-regular content-default-secondary ${theme}`}>{item.text}</p>
            </div>
        </div>);
};


const Cards = props => {

    const size = useWindowSize();
    const { theme } = props;

    const RenderWidgetsEmpty = () => {
        return (
            <div className={`swiper-slider-container column-between align-items-center surface-bg-surface ${theme} rounded-16 p-3`} style={{ height: 120 }}>
                <img src={theme === 'dark' ? empty_widgets_slider : empty_widgets_slider_light} alt="empty_widget_energy" style={{ width: 124, height: 60 }} />
                <div className={`content-default-secondary label-XSmall-regular text-wrap mt-2 ${theme}`}>{props.language.energy_widgets_empty_data}</div>
            </div>
        )
    };

    return (
        <div className="row-center align-items-center ps-1 w-100 h-100">
            <div
                id="prev-slide"
                className="row-center align-items-center h-100 pe-pointer"
                style={{ width: 40, height: 40 }}
                onClick={() => track('Plus widget clicked', { Widget_Back: 'activate' })}
            >
                <i className="fa-regular fa-chevron-left txt-14 txt-gray-700 pe-pointer" />
            </div>
            <div className="position-relative" style={{ width: "calc(100% - 80px)" }} >
                <div className={`position-absolute h-100 top-0 left-0 bg-black-transparent-gradient-horizontal zindex-2 ${theme}`} style={{ width: 120 }}></div>
                <div className={`position-absolute h-100 top-0 right-0 bg-transparent-black-gradient-horizontal zindex-2 ${theme}`} style={{ width: 120 }}></div>
                {props.indicators.length ?
                    <Swiper
                        loop={true}
                        centeredSlides={true}
                        spaceBetween={16}
                        autoplay={{ delay: 5000, disableOnInteraction: false }}
                        slidesPerView={size.width >= 1400 ? 3 : 2}
                        navigation={{ nextEl: "#next-slide", prevEl: "#prev-slide" }}>
                        {props.indicators.map((card, i) =>
                            <SwiperSlide key={i}>
                                <RenderWidgets item={card} theme={theme}></RenderWidgets>
                            </SwiperSlide>)}
                    </Swiper> :
                    <Swiper
                        loop={true}
                        centeredSlides={true}
                        spaceBetween={16}
                        autoplay={{ delay: 5000, disableOnInteraction: false }}
                        slidesPerView={size.width >= 1400 ? 3 : 2}
                        navigation={{ nextEl: "#next-slide", prevEl: "#prev-slide" }}>
                        {[0, 1, 2].map((i) =>
                            <SwiperSlide key={i}>
                                <RenderWidgetsEmpty></RenderWidgetsEmpty>
                            </SwiperSlide>)}
                    </Swiper>
                }
            </div>
            <div
                id="next-slide"
                className="row-center align-items-center  h-100 pe-pointer"
                style={{ width: 40, height: 40 }}
                onClick={() => track('Plus widget clicked', { Widget_Forward: 'activate' })}
            >
                <i className="fa-regular fa-chevron-right txt-14 txt-gray-700 pe-pointer" />
            </div>
        </div>);
};

const mapStateToProps = (state) => ({
    indicators: state.plus.indicators,
    language: state.shared.language,
    theme: state.theme.theme,
});

export default connect(mapStateToProps)(Cards);


