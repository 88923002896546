import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as usersActions from '../../store/actions/Users';
import { Modal } from 'rsuite';

const ModalSuccess = (props) => {
    const { theme, language, infoInviteSuccessModal, setShowCreateUserModal, loadUsersAsync, onClose, onAddMoreUsers } =
        props;
    const history = useHistory();

    const handleClose = () => {
        setShowCreateUserModal(false);
        loadUsersAsync();
        history.push('/users');
        if (onClose) onClose();
    };

    const handleAddMoreUsers = () => {
        if (onAddMoreUsers) {
            onAddMoreUsers();
        }
    };

    const handleLinkClick = (e) => {
        e.preventDefault();
        handleClose();
    };

    return (
        <Modal
            size='sm'
            className={`rounded-24 surface-bg-surface border-fill-medium ${theme}`}
            show={true}
            backdropClassName={`w-100 h-100 surface-bg-blackout ${theme}`}
            onHide={handleClose}
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
            <div
                className={`column-between align-items-start rounded-24 w-100${theme}`}
                style={{ flex: 1, maxWidth: '100%' }}
            >
                <div
                    className={`row-between align-items-center py-2 px-3 surface-bg-fill w-100 rounded-top-24 border-fill-regular-bottom ${theme}`}
                >
                    <div className='w-100 row-center align-items-center'>
                        <p className={`label-large-semibold content-default-primary ${theme}`}>
                            {props.language.users_title_header_modal_success}
                        </p>
                    </div>
                    <button
                        onClick={handleClose}
                        className={`column-center align-items-center rounded-30 border-fill-medium surface-bg-on-surface ${theme}`}
                        style={{ width: '25px', height: '25px' }}
                    >
                        <i
                            className={`fa-light fa-xmark txt-10 content-default-primary ${theme}`}
                            style={{ fontSize: 10 }}
                        />
                    </button>
                </div>
                <div className={`column-center align-items-center gap-3 w-100 border-fill-medium-top p-3 ${theme}`}>
                    <div className={`content-default-primary label-medium-semibold w-100 column-start ${theme}`}>
                        {`Has enviado ${infoInviteSuccessModal?.users?.length || 0} invitaciones para acceder a ${
                            infoInviteSuccessModal?.idServices?.length || 0
                        } servicios.`}
                    </div>
                    <div className={`content-default-primary label-medium-regular ${theme}`}>
                        {language['users_first_description_modal_success']}
                        <span
                            className={`label-medium-special-underline content-color-green pe-pointer ${theme}`}
                            onClick={handleLinkClick}
                        >
                            {language['users_link_description_modal_success']}
                        </span>
                        {language['users_second_description_modal_success']}
                    </div>
                </div>
                <div
                    className={`row-end align-items-center w-100 px-3 gap-2 surface-bg-fill w-100 border-fill-regular-top rounded-bottom-24 ${theme}`}
                    style={{ flexShrink: 0, height: '10vh' }}
                >
                    <button
                        onClick={handleAddMoreUsers}
                        className={`row-center align-items-center py-2 px-3 gap-2 button-border-outline surface-bg-fill rounded-pill pe-pointer ${theme}`}
                    >
                        <p className={`label-small-medium button-content-default ${theme}`}>
                            {language['users_button_new_user_success_modal']}
                        </p>
                    </button>
                    <button
                        onClick={handleClose}
                        className={`row-center align-items-center py-2 px-3 gap-2 button-fill-brand button-content-equal  rounded-pill pe-pointer ${theme}`}
                    >
                        <p className={`label-small-medium ${theme}`}>{language['users_button_finish_success_modal']}</p>
                    </button>
                </div>
            </div>
        </Modal>
    );
};

const mapStateToProps = (state) => ({
    language: state.shared.language,
    theme: state.theme.theme,
    infoInviteSuccessModal: state.users.infoInviteSuccessModal,
});

const mapDispatchToProps = (dispatch) => ({
    setShowCreateUserModal: (payload) => dispatch(usersActions.setShowCreateUserModal(payload)),
    loadUsersAsync: (payload) => dispatch(usersActions.loadUsersAsync(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalSuccess);
