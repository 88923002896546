import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";

import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";

import Header from "../shared/Header";
import ContentLoader from "react-content-loader";
import { Transition } from "react-transition-group";

import * as sharedActions from "../../store/actions/Shared";

const Analytics = (props) => {
    const initialSelected = {
        report_id: process.env.REACT_APP_POWER_BI_REPORT_ID,
        url_embedded: process.env.REACT_APP_POWER_BI_EMBED_URL,
        report_name: "Comercialización",
        id:1
    };
    const theme = props.theme;
    const setShowBlackScreen = props.setShowBlackScreen;
    const [isLoading, setIsLoading] = useState(true);
    const [accessToken, setAccessToken] = useState(null);
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState({});
    const [reports, setReports] = useState([])
    const menuRef = useRef(null);

    const [embedConfig, setEmbedConfig] = useState({
        type: "report",
        id: initialSelected.report_id,
        embedUrl: initialSelected.url_embedded,
        accessToken: null,
        settings: {
            panes: {
                filters: { expanded: false, visible: false },
                pageNavigation: { visible: false },
            },
        },
        background: models.BackgroundType.Transparent,
    });

    useEffect(() => {
        const fetchReports = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_ANALYTICS_API_URL}/reports`, {
                    method: "GET",
                    headers: {
                        "Authorization": `Bearer ${localStorage.getItem("token")}`,
                        "Content-Type": "application/json"
                    }
                });

                if (!response.ok) {
                    throw new Error(`Error ${response.status}: ${response.statusText}`);
                }

                const data = await response.json();
                const filteredReports = process.env.REACT_APP_STAGE === "prod"
                ? data.reports.filter(report => report.is_ready_for_prod)
                : data.reports;

            setReports(filteredReports);

            } catch (error) {
                console.error("Error obteniendo el token:", error);
            }
        };
        fetchReports();
    }, []);

    useEffect(() => {
        if (reports.length > 0) {
            setSelected(reports[0]); // Selecciona el primer elemento de `reports`
        }
    }, [reports]);

    useEffect(() => {
        const fetchAccessToken = async () => {
            try {
                const response = await fetch(process.env.REACT_APP_ANALYTICS_API_URL, {
                    method: "GET",
                    headers: {
                        "Authorization": `Bearer ${localStorage.getItem("token")}`,
                        "Content-Type": "application/json"
                    }
                });

                if (!response.ok) {
                    throw new Error(`Error ${response.status}: ${response.statusText}`);
                }

                const data = await response.json();
                setAccessToken(data.token);

                setEmbedConfig((prevConfig) => ({
                    ...prevConfig,
                    accessToken: data.token
                }));
            } catch (error) {
                console.error("Error obteniendo el token:", error);
            }
        };
        fetchAccessToken();
    }, []);

    useEffect(() => {
        setEmbedConfig((prevConfig) => ({
            ...prevConfig,
            id: selected.report_id,
            embedUrl: selected.url_embedded,
            accessToken: accessToken
        }));
    }, [selected, accessToken]);

    useEffect(() => {
        setShowBlackScreen(open);
    }, [setShowBlackScreen, open]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleSelect = (item) => {
        if (item.id !== selected.id) {
            setIsLoading(true);
            setSelected(item);
        }
        setOpen(false);
    };

    return (
        <section className="column-start align-items-center vh-100 w-100">
            <div className={`w-100 zindex-2 ${theme}`} style={{ height: 74 }}>
                <Header moduleName={props.language.menu_analytics} backToButton={false} />
            </div>

            <div className={`w-100 d-flex flex-column flex-grow-1 p-3 ${theme}`}>
                <div className={`column-start w-100 surface-bg-fill rounded-top-16 surface-bg-surface border-fill-medium border-bottom-0 ${theme} `}>
                    <div className={``}>
                        <div className={`row-between align-items-center gap-2 py-2 px-3 surface-on-surface-alpha hover-gray border-fill-medium rounded-pill pe-pointer w-15 m-3 ${theme}`}
                            onClick={() => {
                                setOpen(!open);
                            }}
                        >
                            <p className={`w-100 label-medium-regular content-default-primary pe-pointer ${theme}`}>
                                {selected.report_name}
                            </p>
                            <div className={`position-relative row-center align-items-center pe-pointer ${open && "zindex-10"}`}>
                                <i className={`fal fa-circle-chevron-down content-default-secondary txt-8 ${theme}`}></i>

                                <Transition in={open} timeout={50}>
                                    {(state) => (
                                        <div
                                            className={`position-absolute column-start align-items-center mt-3 top-100  trans-fade trans-fade-${state}`}
                                            style={{ width: 300, zIndex: 100 }}
                                        >
                                            <div className={`invoice-state-arrow surface-bg-surface ${theme}`}></div>
                                            <div ref={menuRef} className={`p-3 surface-bg-surface rounded-16 border-fill-medium ${theme}`}>
                                                {reports.map((item) => (
                                                    <div
                                                        key={item.id}
                                                        className={`row-between align-items-center px-2 py-3 rounded ${selected.id === item.id ? 'surface-bg-on-surface' : ''} ${theme}`}
                                                        onClick={() => handleSelect(item)}
                                                    >
                                                        <p className={`label-small-medium content-default-primary pe-pointer ${theme}`}>
                                                            {item.report_name}
                                                        </p>
                                                        <i className={`txt-8 ${selected.id === item.id ? 'fas fa-circle-check content-color-green' : 'fal fa-circle-check content-default-secondary'} ${theme}`}></i>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </Transition>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`position-relative column-start flex-grow-1 w-100 surface-bg-surface border-fill-medium border-top-0 rounded-bottom-16 px-2 pb-2  ${theme} `}>

                    {isLoading && (
                        <div className="position-absolute d-flex justify-content-center align-items-center w-100 h-100 top-0 left-0 rounded-12 px-2 pb-2" style={{ zIndex: 2 }}>
                            <ContentLoader
                                seed={2}
                                backgroundColor="#161617"
                                foregroundColor="#323234"
                                width="100%"
                                height="100%"
                            >
                                <rect x="0" y="0" width="100%" height="100%" rx="12" ry="12" />
                            </ContentLoader>
                        </div>
                    )}
                    <div className="w-100 h-100 rounded-12 border-fill-medium dark">
                    <PowerBIEmbed
                        embedConfig={embedConfig}
                        eventHandlers={
                            new Map([
                                ['rendered', () => setIsLoading(false)],
                                ['error', function (event) { console.log(event.detail); }],
                            ])
                        }
                        cssClassName="embed-container"
                        getEmbeddedComponent={(embeddedReport) => {
                            window.report = embeddedReport;
                        }}
                    />
                    </div>
                </div>
            </div>
        </section>
    );
};

const mapStateToProps = (state) => ({
    language: state.shared.language,
    theme: state.theme.theme,
    showBlackScreen: state.shared.showBlackScreen,
});

const mapDispatchToProps = (dispatch) => ({
    setShowBlackScreen: (payload) =>
        dispatch(sharedActions.setShowBlackScreen(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Analytics);