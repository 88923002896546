import React from 'react';

import ThemedImage from "../utils/ThemedImage"

export const stepsProfile = (language, theme) => {
    return ([
        {
            content:
                <div className="row-center align-items-center w-100 rounded-8 mt-3" style={{ height: 152, width: 300 }}>
                    <ThemedImage imageName={'step1-profile.png'}/>
                </div>,
            title: language.onboarding_profile_title_step1,
            description: language.onboarding_profile_description_step1,
            placement: "center",
            target: "#profile_menu",
            disableBeacon: true,
            steps: 4
        },
        {
            content:                
                <div className={`row-center align-items-center w-100 rounded-8 mt-3 surface-bg-fill p-4 ${theme}`} style={{ height: 152, width: 300 }}>
                    <div className={`row-between align-items-center w-100 mt-3 pe-pointer p-3 rounded-16 my-3 surface-bg-on-surface border-fill-medium ${theme}`}>
                        <div className="row-start align-items-center">
                            <i className={`fa-regular fa-user txt-10 content-default-secondary ${theme}`}></i>
                            <div className={`label-small-medium ms-3 pe-pointer content-default-primary ${theme}`}>{language.user_title_section_personal_data}</div>
                        </div>
                        <i className={`fa-regular fa-circle-chevron-right txt-10 pe-pointer content-default-secondary ${theme}`}></i>
                    </div>
                </div>,
            title: language.onboarding_profile_title_step2,
            description: language.onboarding_profile_description_step2,
            placement: "left",            
            target: "#profile",
            floaterProps: {
                disableAnimation: true,
            },
            disableBeacon: true,
            steps: 4
        },
        {
            content:
                <div className={`row-center align-items-center w-100 rounded-8 mt-3 surface-bg-fill p-4 ${theme}`} style={{ height: 152, width: 300 }}>
                    <div className={`row-between align-items-center w-100 mt-3 pe-pointer p-3 rounded-16 my-3 surface-bg-on-surface border-fill-medium ${theme}`}>
                        <div className="row-start align-items-center">
                            <i className={`fa-regular fa-user-plus txt-10 content-default-secondary ${theme}`}></i>
                            <div className={`label-small-medium ms-3 pe-pointer content-default-primary ${theme}`}>{language.user_title_section_create_users}</div>
                        </div>
                        <i className={`fa-regular fa-circle-chevron-right txt-10 pe-pointer content-default-secondary ${theme}`}></i>
                    </div>
                </div>,
                title: language.onboarding_profile_title_step3,
            description: language.onboarding_profile_description_step3,
            placement: "left",            
            target: "#profile",
            floaterProps: {
                disableAnimation: true,
            },
            disableBeacon: true,
            steps: 4
        },
        {
            content:
                <div className="row-center align-items-center w-100 rounded-8 mt-3" style={{ height: 142, width: 300 }}>
                    <ThemedImage imageName={'step4-profile.png'}/>
                </div>,
            title: language.onboarding_profile_title_step4,
            description: language.onboarding_profile_description_step4,
            placement: "left",            
            target: "#profile",
            floaterProps: {
                disableAnimation: true,
            },
            disableBeacon: true,
            scrollOffset: 40,
            steps: 4
        },
    ])
};