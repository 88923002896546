import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import * as usersActions from '../../store/actions/Users';
import { Modal } from 'rsuite';
import InputRounded from '../shared/utilities/InputRounded';
import MultipleSelect from '../shared/utilities/MultipleSelect'; // Importa el nuevo componente

const ModalService = ({
    theme,
    showCreateUserModal,
    accounts,
    userServicesSelected,
    setShowCreateUserModal,
    setUserServicesSelected,
    setSelectedAccount,
    selectedAccount,
    language,
    onClose,
    mode = 'add',
}) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedIds, setSelectedIds] = useState([]);
    const [selectedAccounts, setSelectedAccounts] = useState([]); 

 
    const getSelectedAccountNames = () => {
        if (!Array.isArray(accounts) || accounts.length === 0 || selectedAccounts.length === 0) {
            return ''; // Si no hay cuentas seleccionadas, devuelve una cadena vacía
        }

        const selectedNames = accounts
            .filter((acc) => selectedAccounts.includes(acc.idAccount))
            .map((acc) => acc.name);

        return selectedNames.join(', '); 
    };

    useEffect(() => {
        if (selectedAccounts.length === 0 && Array.isArray(accounts) && accounts.length > 0) {
            setSelectedAccounts([accounts[0].idAccount]); 
        }
    }, [accounts, selectedAccounts, setSelectedAccounts]);

    useEffect(() => {
        const servicesKey = mode === 'add' ? 'newServices' : 'updatedServices';
        setSelectedIds(userServicesSelected[servicesKey] || []);
    }, [userServicesSelected, mode]);

    const handleBack = () => {
        setShowCreateUserModal(true);
        onClose();
    };

    const handleDone = () => {
        setUserServicesSelected({
            key: mode === 'add' ? 'newServices' : 'updatedServices',
            value: selectedIds,
        });
        setShowCreateUserModal(true);
        onClose();
    };

    const handleServiceSelection = (service) => {
        const isSelected = selectedIds.includes(service.idService);
        if (isSelected) {
            setSelectedIds(selectedIds.filter((id) => id !== service.idService));
        } else {
            setSelectedIds([...selectedIds, service.idService]);
        }
    };

    const handleSelectAll = (groupServices) => {
        const allIds = groupServices.map((service) => service.idService);
        const allSelected = allIds.every((id) => selectedIds.includes(id));
        if (allSelected) {
            setSelectedIds(selectedIds.filter((id) => !allIds.includes(id)));
        } else {
            setSelectedIds([...new Set([...selectedIds, ...allIds])]);
        }
    };

    const filteredServices = useMemo(() => {
        if (!Array.isArray(accounts) || accounts.length === 0 || selectedAccounts.length === 0) {
            return [];
        }

        const selectedAccs = accounts.filter((acc) => selectedAccounts.includes(acc.idAccount));
        if (selectedAccs.length === 0) {
            return [];
        }

        const services = selectedAccs.flatMap((acc) => (Array.isArray(acc.services) ? acc.services : []));

        return services.filter((service) => {
            const matchesSearch = searchQuery
                ? (service.name || '').toLowerCase().includes(searchQuery.toLowerCase()) ||
                  (service.address || '').toLowerCase().includes(searchQuery.toLowerCase())
                : true;
            return matchesSearch;
        });
    }, [accounts, selectedAccounts, searchQuery]);

    const groupedServices = useMemo(() => {
        if (!filteredServices.length) {
            return [];
        }

        const groupedByType = filteredServices.reduce((acc, service) => {
            const typeName = service.type?.name || 'Unknown';
            if (!acc[typeName]) {
                acc[typeName] = {
                    type:
                        typeName === 'energy'
                            ? language['services_picker_type_consumption'] || 'Consumo'
                            : typeName === 'generation'
                            ? language['services_picker_type_generation'] || 'Generación'
                            : typeName === 'efficiency'
                            ? 'Eficiencia'
                            : typeName,
                    key: typeName,
                    services: [],
                };
            }
            acc[typeName].services.push(service);
            return acc;
        }, {});

        const grouped = Object.values(groupedByType).filter((group) => group.services.length > 0);
        return grouped;
    }, [filteredServices, language]);

    if (showCreateUserModal !== 2) return null;

    return (
        <Modal
            size='sm'
            className={`rounded-24 surface-bg-surface border-fill-medium ${theme}`}
            show={true}
            backdropClassName={`w-100 h-100 surface-bg-blackout ${theme}`}
            onHide={handleBack}
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center',  minHeight: '70vh' }}
        >
            <div className={`column-start align-items-start rounded-24 w-100 ${theme}`}>
                <div
                    className={`row-between align-items-center py-2 px-3 surface-bg-fill w-100 rounded-top-24 border-fill-regular-bottom ${theme}`}
                >
                    <button
                        onClick={handleBack}
                        className={`column-center align-items-center rounded-30 border-fill-medium surface-bg-on-surface ${theme}`}
                        style={{ width: '25px', height: '25px' }}
                    >
                        <i
                            className={`fa-light fa-chevron-left txt-10 content-default-primary ${theme}`}
                            style={{ fontSize: 10 }}
                        />
                    </button>
                    <div className='w-100 row-center align-items-center'>
                        <p className={`label-large-semibold content-default-primary ${theme}`}>
                            {language['new_user_title_header']}
                        </p>
                    </div>
                </div>
                <div className={`column-start align-items-start w-100 gap-3 p-3 overflow-auto scroll-without-border ${theme}`} style={{maxHeight: '60vh'}}>
                    <InputRounded
                        icon='magnifying-glass'
                        placeholder={language['dashboard_input_placeholder'] || 'Buscar servicio'}
                        type='text'
                        py={2}
                        value={searchQuery}
                        onChange={({ target }) => setSearchQuery(target.value)}
                    />
                    <div
                        className={`column-start border-fill-medium-top w-100 ${theme}`}
                        style={{ minHeight: '50svh' }}
                    >
                        <div className='mt-2 w-50 align-items-start'>
                            <MultipleSelect
                                background='surface-bg-surface'
                                value={getSelectedAccountNames()} 
                                options={
                                    accounts && Array.isArray(accounts)
                                        ? accounts.map((acc) => ({ label: acc.name, value: acc.idAccount }))
                                        : []
                                }
                                selectedData={selectedAccounts} 
                                onChange={(selectedValues) => setSelectedAccounts(selectedValues)} 
                                width={278}
                                label={language['dashboard_label_select_account'] || 'Seleccionar cuenta'}
                                theme={theme}
                            />
                        </div>
                        {groupedServices.length > 0 ? (
                            groupedServices.map((group, index) => (
                                <div key={index} className='mt-3'>
                                    <div className='w-100 row-between px-3 align-items-center'>
                                        <div className='row-start align-items-end'>
                                            <i
                                                className={`fak fa-${group.key.toLowerCase()}-symbol txt-12 me-2 content-default-secondary ${theme}`}
                                            />
                                            <p className={`label-medium-semibold content-default-secondary ${theme}`}>
                                                {group.type}
                                            </p>
                                        </div>
                                        <p
                                            className={`label-small-special-underline button-content-brand pe-pointer border-fill-medium-button ${theme}`}
                                            onClick={() => handleSelectAll(group.services)}
                                        >
                                            {group.services.every((s) => selectedIds.includes(s.idService))
                                                ? language['dashboard_label_picker_services_all_unselected'] ||
                                                  'Deseleccionar todos'
                                                : language['dashboard_label_picker_services_all_selected'] ||
                                                  'Seleccionar todos'}
                                        </p>
                                    </div>
                                    {group.services.map((service) => {
                                        const isSelected = selectedIds.includes(service.idService);
                                        return (
                                            <div
                                                key={service.idService}
                                                className={`row-between align-items-center w-100 p-3 rounded-8 mt-3 ${theme} ${
                                                    isSelected ? 'surface-bg-on-surface' : ''
                                                }`}
                                                onClick={() => handleServiceSelection(service)}
                                            >
                                                <div className='column-start'>
                                                    <p
                                                        className={`label-medium-semibold content-default-primary pe-pointer ${theme}`}
                                                    >
                                                        {service.name || 'Sin nombre'}
                                                    </p>
                                                    <p
                                                        className={`label-small-medium content-default-secondary pe-pointer ${theme}`}
                                                    >
                                                        {service.address || 'Sin dirección'}
                                                    </p>
                                                </div>
                                                <i
                                                    className={`fa-regular fa-circle-check txt-10 ${
                                                        isSelected ? 'content-color-green' : 'content-default-primary'
                                                    }  ${theme}`}
                                                />
                                            </div>
                                        );
                                    })}
                                </div>
                            ))
                        ) : (
                            <p className={`label-medium-medium content-default-tertiary text-center mt-3 ${theme}`}>
                                {language['dashboard_empty_state_picker_services']}
                            </p>
                        )}
                    </div>
                </div>
                <div
                    className={`row-end align-items-center w-100 py-2 px-3 gap-2 surface-bg-fill w-100 border-fill-regular-top rounded-bottom-24 ${theme}`}
                    style={{ flexShrink: 0, height: '6vh' }}
                >
                    <div
                        className={`row-center align-items-center py-1 px-2 gap-2 button-fill-disabled surface-bg-on-surface rounded-pill ${theme}`}
                    >
                        <p className={`label-small-regular content-default-primary ${theme}`}>
                            {selectedIds.length} {language['users_label_count_services'] || 'seleccionados'}
                        </p>
                    </div>
                    <button
                        onClick={handleDone}
                        className={`row-center align-items-center py-2 px-3 gap-2 button-fill-brand rounded-pill pe-pointer ${theme}`}
                    >
                        <p className={`label-medium-medium button-content-equal pe-pointer ${theme}`}>
                            {language['dashboard_filter_done_button'] || 'Hecho'}
                        </p>
                    </button>
                </div>
            </div>
        </Modal>
    );
};

const mapStateToProps = (state) => ({
    showCreateUserModal: state.users.showCreateUserModal,
    theme: state.theme.theme,
    accounts: state.accounts.totalAccounts,
    userServicesSelected: state.users.userServicesSelected,
    selectedAccount: state.users.accountSelectedUsersTable,
    language: state.shared.language,
});

const mapDispatchToProps = (dispatch) => ({
    setShowCreateUserModal: (payload) => dispatch(usersActions.setShowCreateUserModal(payload)),
    setUserServicesSelected: (payload) => dispatch(usersActions.setUserServicesSelected(payload)),
    setSelectedAccount: (payload) => dispatch(usersActions.setAccountSelectedUsersTable(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalService);