import React, { useState, useEffect } from "react";

import { Popover } from "@idui/react-popover";

import { connect } from 'react-redux';

import * as themeActions from "../../../store/actions/Theme";

const MultipleSelect = ({ options, selectedData, label, keyName = 'value', labelName = 'label', onChange, width = 280, height = 120, language, disabledData, newData, allowAllOptions = false, labelAllServices, error, value, theme,  singleSelect = false, }) => {

  const [active, setActive] = useState(false);
  const [selectedAllOptions, setSelectedAllOptions] = useState(false);

  let totalOptions = options.map((item) => { return item[keyName] });

  useEffect(() => {
    setSelectedAllOptions(totalOptions.length === selectedData.length);
  }, [selectedData, totalOptions]);

  return (
    <Popover
      lazy
      closeOnEnter
      fitMaxHeightToBounds
      fitMaxWidthToBounds
      trigger={'click'}
      placement={'bottom'}
      arrowColor={'#161617'}
      className={`p-0 bg-popover-none rounded-16`}
      zIndex={10000}
      arrowSize={20}
      offset={[0, 0]}
      openingAnimationTranslateDistance={0}
      animation={{
        animate: { opacity: 1 },
        exit: { opacity: 0, transition: { duration: 0.1 } },
        initial: { opacity: 0 }
      }}
      withArrow={false}
      content={
        <div className="w-100">
          {options.length ?
            <div className={`column-start align-items-center border-fill-medium overflow-auto px-2 py-1 surface-bg-surface rounded-16 scroll-without-border ${theme}`} style={{ width: width, maxHeight: height }}>
              {allowAllOptions && !singleSelect && options.length > 1 ?
                <div className="row-between align-items-center bg-gray-800 pe-pointer p-2 rounded-8 my-2" onClick={() => { if (!selectedAllOptions) { onChange(totalOptions) } else { onChange([]) } }}>
                  <div className={`txt-phrase txt-6 txt-${!selectedAllOptions ? 'white' : 'green-500'} pe-pointer ms-2`}>{labelAllServices}</div>
                  <i className={`fa-solid fa-check-circle txt-10 txt-${!selectedAllOptions ? 'white' : 'green-500'}`} />
                </div> : null
              }
              {options.map((item) => {

                let isSelected = selectedData.includes(item[keyName]);
                let isDisabled = false;
                let isNew = false;

                if (disabledData) isDisabled = disabledData.findIndex(i => i === item[keyName]) >= 0;

                if (newData) isNew = newData.findIndex(i => i === item[keyName]) >= 0;

                return <div key={item[keyName]} className={`row-between w-100 align-items-center pe-pointer py-2 px-2 my-1 rounded-8 ${isSelected ? "surface-bg-on-surface" : "surface-bg-surface"} ${theme}`}
                  onClick={() => {
                      if (singleSelect) {
                        // Selección única
                        onChange(isSelected ? [] : [item[keyName]]);
                      } else {
                        // Selección múltiple
                        if (isSelected) {
                          onChange(selectedData.filter(value => value !== item[keyName]));
                        } else {
                          !isDisabled && onChange([...selectedData, item[keyName]]);
                        }
                      }
                    }}>
                  <div className="row-between align-items-center w-100">
                    <div className="row-start align-items-center">
                      <div className={`label-small-medium ${isDisabled ? 'content-default-secondary' : 'content-default-primary'} ${theme} pe-pointer`}>{language ? language[item[labelName]] : item[labelName]}</div>
                      {isNew ? <p className={`label-small-medium ${isDisabled ? 'content-default-secondary' : 'content-default-primary'} ${theme} pe-pointer ms-2`}>{`[${language['genius_multipleselect_label_new_graphs']}]`}</p> : null}
                    </div>
                    {isSelected && <i className={`fa-solid fa-check-circle content-color-green txt-10 ${theme}`}></i>}
                  </div>
                </div>
              })}
            </div> : null
          }
        </div>
      }>
      <div className="column-start align-items-start w-100">
        <div className="column-start w-100 gap-2">
         {label && <p className={`label-small-medium content-default-primary ${theme}`}>{label}</p>}
          <button className={`row-between align-items-center rounded-30 border-fill-medium surface-bg-surface py-2 px-3 w-100 ${theme}`} onClick={() => { setActive(!active) }}>
            <p className={`txt-truncate label-small-medium content-default-secondary ${theme}`}>{value}</p>
            <i className={`fa-regular ${active ? 'fa-circle-chevron-right' : 'fa-circle-chevron-down'} txt-10 content-default-secondary ${theme}`}></i>
          </button>
        </div>
        {error ? <p className="txt-phrase txt-regular txt-6 txt-red-500 mt-1 w-100 text-wrap">{error}</p> : null}
      </div>
    </Popover>
  );
};
const mapStateToProps = (state) => ({
  theme: state.theme.theme,
})

const mapDispatchToProps = dispatch => ({
  setTheme: (theme) => dispatch(themeActions.setTheme(theme)),
})

export default connect(mapStateToProps, mapDispatchToProps)(MultipleSelect)