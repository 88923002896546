import React, { useState, Fragment, useEffect} from 'react';
import { connect } from 'react-redux';

import * as energyActions from "../../../store/actions/Energy";

import moment from "moment";

import { Transition } from 'react-transition-group';

import { Loader, Modal } from "rsuite";

import { Calendar as Datepicker } from 'react-calendar';

import Input from '../../shared/utilities/Input';

import { track } from "@amplitude/analytics-browser"

const showError = (payloadExport, setFocus, setError, language) => {

    if (payloadExport.report_name === null || payloadExport.report_name === "") {        
        setFocus(1);
        setError(language.alert_filename_report_error);
        return;
    }

    return setFocus(0);
}

const validate = (payloadExport) => {

    if (payloadExport.report_name === null || payloadExport.report_name === "") {
        return false;
    }  

    return true
}

const ExportGraph = (props) => {

    const payloadToExportInit = {
        report_name: null,
        startTime: props.dateFrom,
        endTime: props.dateTo,
        "format_type": "CSV"        
    }

    let language = props.language;
    let theme = props.theme;

    const [payloadToExport, setPayloadToExport] = useState(payloadToExportInit);    
    const [showCalendarDateFrom, setShowCalendarDateFrom] = useState(false);
    const [showCalendarDateTo, setShowCalendarDateTo] = useState(false); 
    const [focus, setFocus] = useState(0);   
    const [error, setError] = useState("");

    useEffect(() => {
        if(focus !== 0) {showError(payloadToExport, setFocus, setError, language)}                      
    }, [payloadToExport, language, focus]);
        
    return(
        <Modal            
            style={{
                width: 500,
                marginLeft: 'auto',
                marginRight: 'auto',
                display: 'block'
            }}
            show={props.showReportModal}
            overflow={false}
            backdropClassName={'bg-black opacity-80 pe-pointer'}    
            dialogClassName="rounded"                                  
            onHide={() => props.setShowReportModal(false)}>
            <Fragment>
                <div id='export_csv' className={`column-start w-100 h-100 surface-bg-surface rounded-24 border-fill-medium ${theme}`}>
                    <div className={`w-100 text-start mt-3 mx-3 heading-h6-bold content-default-primary ${theme}`}>{props.language.energy_export_graph_title}</div> 
                    <div className='column-start p-3'>
                        <div className="row-start align-items-start flex-wrap my-2">
                            <div className="col-md-6 col-12 pe-1">
                                <div className={`label-medium-medium content-default-primary ${theme}`}>{props.language.energy_export_graph_start_time}</div>
                                <div className="w-100 my-2">
                                    <div className={`form-control bg-transparent py-2 pe-pointer border-fill-regular rounded-30 content-default-secondary label-medium-medium ${theme}`} onClick={() => setShowCalendarDateFrom(!showCalendarDateFrom) }>
                                        <div className="row-between align-items-center">
                                            {payloadToExport.startTime ? moment(payloadToExport.startTime).format("YYYY-MM-DD") : props.language.energy_export_graph_select_date}
                                            <i className="fa-light fa-calendar txt-10"></i>
                                        </div>
                                    </div>
                                    <Transition
                                        in={showCalendarDateFrom}
                                        timeout={50}>
                                        {state => (
                                            <div className={`trans-fade trans-fade-${state} ${theme}`}>
                                                <Datepicker 
                                                    next2Label={null}
                                                    prev2Label={null}
                                                    maxDate={new Date()}
                                                    className={`txt-phrase txt-8 p-2 rounded-16 surface-bg-on-surface-alpha border-fill-medium ${theme}`}
                                                    nextLabel={<i className={`fa-regular fa-chevron-right txt-8 pe-pointer content-default-primary ${theme}`}></i>}
                                                    prevLabel={<i className={`fa-regular fa-chevron-left txt-8 pe-pointer content-default-primary ${theme}`}></i>}
                                                    onChange={value => {                                                        
                                                        setPayloadToExport({ ...payloadToExport, startTime: value });
                                                        setShowCalendarDateFrom(false);                                                        
                                                    }}
                                                />
                                            </div>
                                        )} 
                                    </Transition>
                                </div>
                            </div>
                            <div className="col-md-6 col-12 ps-1">
                                <div className={`label-medium-medium content-default-primary ${theme}`}>{props.language.energy_export_graph_end_time}</div>
                                    <div className="w-100 my-2">
                                        <div className={`form-control bg-transparent py-2 pe-pointer border-fill-regular rounded-30 content-default-secondary label-medium-medium ${theme}`}onClick={() => setShowCalendarDateTo(!showCalendarDateTo) }>
                                            <div className="row-between align-items-center">
                                                {payloadToExport.endTime ? moment(payloadToExport.endTime).format("YYYY-MM-DD") : props.language.energy_export_graph_select_date}
                                                <i className="fa-light fa-calendar txt-10"></i>
                                            </div>
                                        </div>
                                        <Transition
                                            in={showCalendarDateTo}
                                            timeout={50}>
                                            {state => (
                                                <div className={`trans-fade trans-fade-${state}`}>
                                                    <Datepicker 
                                                        next2Label={null}
                                                        prev2Label={null}
                                                        maxDate={new Date()}
                                                        className={`txt-phrase txt-8 p-2 rounded-16 surface-bg-on-surface-alpha border-fill-medium ${theme}`}
                                                        nextLabel={<i className={`fa-regular fa-chevron-right txt-8 pe-pointer content-default-primary ${theme}`}></i>}
                                                        prevLabel={<i className={`fa-regular fa-chevron-left txt-8 pe-pointer content-default-primary ${theme}`}></i>}
                                                        onChange={value => {                                                            
                                                            setPayloadToExport({ ...payloadToExport, endTime: value });
                                                            setShowCalendarDateTo(false);                                                            
                                                        }}/>
                                                </div>
                                            )} 
                                        </Transition>
                                    </div>
                                </div>
                        </div>                        
                        <div className={`mt-3 label-medium-medium content-default-primary ${theme}`}>{props.language.energy_export_graph_filename}</div>
                        <div className="w-100 mt-2 mb-4">
                            <Input 
                                label={props.language.export_graph_example_filename} 
                                value={payloadToExport?.report_name}
                                type={'text'} 
                                onChange={({target}) => setPayloadToExport({ ...payloadToExport, report_name: target?.value })}
                                error={focus === 1 ? error : ""}
                            />
                        </div>                       
                        <div className='row-between my-2'>
                            <button className={`me-3 rounded-30 py-2 px-4 button-fill-brand ${theme}`} style={{minWidth: 155}}
                                onClick={() => {
                                    if(validate(payloadToExport)){
                                        props.createReportAsync(payloadToExport);
                                        track('Graphic downloaded', {Rank_ID: props.rankGraphId, Project_Type:props.activeServicesEnergy.map(function (service) { return service?.type?.name })})                                                                                                                
                                    }
                                    else {
                                        showError(payloadToExport, setFocus, setError, language)
                                    }}}> 
                                {!props.reportLoading ?                               
                                    <div className="row-between align-items-center">
                                        <i className={`fa-regular fa-file-arrow-down txt-10 button-content-equal ${theme}`}></i>
                                        <div className={`ms-2 pe-pointer label-small-regular button-content-equal ${theme}`}>{props.language.energy_export_graph_button}</div>
                                    </div> :
                                    <div className='column-center align-items-center h-100'>
                                        <Loader/>
                                    </div>
                                }
                            </button>
                            <button className={`rounded-30 py-2 px-4 button-fill-error txt-white ${theme}`} onClick={() => {props.setShowReportModal(false)}}>
                                <div className="row-between align-items-center">
                                    <i className={`fa-light fa-file-code txt-10`}></i>
                                    <div className={`ms-2 pe-pointer label-small-regular ${theme}`}>{props.language.tag_new_device_button_cancel}</div>
                                </div>
                            </button>
                        </div>                       
                    </div>                  
                </div>
            </Fragment>           
        </Modal>

    );
}

const mapStateToProps = (state) => ({ 
    dateFrom: state.energy.graphDateFrom,
    dateTo: state.energy.graphDateTo,  
    showReportModal: state.energy.showReportModal,
    reportLoading: state.energy.reportLoading,
    user: state.users.user, 
    language: state.shared.language,
    rankGraphId: state.energy.rankGraphId,
    activeServicesEnergy: state.services.activeServicesEnergy,
    theme: state.theme.theme,
});

const mapDispatchToProps = dispatch => ({   
    createReportAsync: (payload) => dispatch(energyActions.createReportAsync(payload)),
    setShowReportModal: (payload) => dispatch(energyActions.setShowReportModal(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(ExportGraph);