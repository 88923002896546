import React, { useEffect } from "react";
import { connect } from 'react-redux';

import * as sharedActions from "../../store/actions/Shared";

import { Link } from 'react-router-dom';
import { Transition } from 'react-transition-group';

import appStore from '../../imgs/app-store.svg';
import playStore from '../../imgs/play-store.svg';

import { Loader } from 'rsuite';

const MobileScreen = (props) => {

    let language = props.language;
    let theme = props.theme;

    const logo = require(`../../imgs/logo-enrg-${theme}.svg`);

    const loadLanguageAsync = props.loadLanguageAsync;

    useEffect(() => {
        if (!Object.keys(language).length)
            loadLanguageAsync();
    }, [loadLanguageAsync, language]);

    return (
            <div className="d-flex d-lg-none">
                <Transition
                    in={!Object.keys(language).length}
                    timeout={100}>
                    {state => (
                        <div className={`trans-fade trans-fade-${state} position-absolute w-100 h-100 top-0 left-0 zindex-1`}>
                            <div className="column-center align-items-center w-100 h-100">
                                <Loader inverse={theme === 'dark'} center size="md" content={language.loader_text_content} />
                            </div>
                        </div>
                    )}
                </Transition>
                <div className={`column-between align-items-center min-vh-100 w-100 px-4 py-5 surface-bg-fill ${theme}`}>
                    <div className='column-start w-100 align-items-center'>
                        <img src={logo} alt="logo-neu-energy" style={{ width: 100 }} />
                        <div className={`heading-h6-regular content-color-green text-wrap text-center mt-3 ${theme}`}>{language.main_title_mobile_screen}</div>
                    </div>
                    <div className='column-start w-100 align-items-center gap-4'>
                        <div className={`content-default-primary label-small-regular text-center text-wrap ${theme}`}>{language.main_description_mobile_screen}</div>
                        <Link to={'https://apps.apple.com/co/app/neu/id1483668731?l=en'}>
                            <img src={appStore} alt="app-store-logo" style={{ height: 60, width: 180 }} />
                        </Link>
                        <Link to={'https://play.google.com/store/apps/details?id=com.nextenergyutility.NEU'}>
                            <img src={playStore} alt="play-store-logo" style={{ height: 60, width: 180 }} />
                        </Link>
                    </div>
                    <div className='row-center align-items-center w-100'>
                        <i className={`fak txt-18 fa-genius-symbol content-color-green ${theme} me-5`} />
                        <i className={`fak txt-18 fa-energy-symbol content-color-green ${theme} me-5`} />
                        <i className={`fak txt-18 fa-plus-symbol content-color-green ${theme}`} />
                    </div>
                </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    language: state.shared.language,
    theme: state.theme.theme
})

const mapDispatchToProps = dispatch => ({
    loadLanguageAsync: () => dispatch(sharedActions.loadLanguageAsync())
})

export default connect(mapStateToProps, mapDispatchToProps)(MobileScreen);