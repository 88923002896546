import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as usersActions from '../../store/actions/Users';
import { Modal, Tooltip, Whisper } from 'rsuite';
import Select from '../shared/utilities/Select';
import ModalSuccess from './ModalSuccess';
import ModalService from './ModalService';
import empty_users from '../../imgs/empty-users.svg';
import empty_users_light from '../../imgs/empty-user-light.svg';

const isValidateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
const isValidColombianPhone = (phone) => {
    return /^\d{10}$/.test(phone) || /^\+57\d{10}$/.test(phone);
};

const NewUser = ({
    theme,
    showCreateUserModal,
    language,
    userServicesSelected,
    setShowCreateUserModal,
    user,
    setUsersToInvite,
    getUserRegisteredAsync,
    loadInviteUserAsync,
    setUserServicesSelected,
    userRegisteredLoading,
    usersLoading,
    showInviteSuccessModal,
    usersToInvite: reduxUsersToInvite,
    userTypesSelect,
    getUserTypesAsync,
}) => {
    const history = useHistory();

    const [modalState, setModalState] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [usersToInvite, setLocalUsersToInvite] = useState([]);
    const [error, setError] = useState('');
    const [isEnable, setEnable] = useState(false);

    useEffect(() => {
        if (showCreateUserModal === true) {
            setModalState('newUser');
        } else if (showCreateUserModal === 2) {
            setModalState('service');
        } else if (showCreateUserModal === 3) {
            setModalState('success');
        } else {
            setModalState(null);
        }
    }, [showCreateUserModal]);

    useEffect(() => {
        if (user?.userType.idUserType) {
            getUserTypesAsync(user?.userType.idUserType);
        }
        // eslint-disable-next-line
    }, [user]);

    useEffect(() => {
        if (showInviteSuccessModal === true) {
            setModalState('success');
            setShowCreateUserModal(3);
        }
        // eslint-disable-next-line
    }, [showInviteSuccessModal]);

    useEffect(() => {
        const hasInvalidUser = usersToInvite.some(
            (item) => item.userExist && user.userType?.idUserType === 1 && item.userType?.idUserType === 0
        );
        setEnable(userServicesSelected.newServices.length > 0 && usersToInvite.length > 0 && !hasInvalidUser);
    }, [userServicesSelected.newServices, usersToInvite, user.userType]);

    useEffect(() => {
        if (reduxUsersToInvite.length) {
            setLocalUsersToInvite(reduxUsersToInvite);
        }
    }, [reduxUsersToInvite]);

    const handleKeyDown = async (event) => {
        if (event.key !== 'Enter' || !inputValue.trim()) return;

        const trimmedInput = inputValue.trim();
        let validateInput = null;

        if (isValidateEmail(trimmedInput)) {
            validateInput = {
                email: trimmedInput,
                phone: null,
                userType: { idUserType: 1, description: 'Colaborador' },
            };
        } else if (isValidColombianPhone(trimmedInput)) {
            const normalizedPhone = trimmedInput.startsWith('+57') ? trimmedInput : `+57${trimmedInput}`;
            validateInput = {
                email: null,
                phone: normalizedPhone,
                userType: { idUserType: 1, description: 'Colaborador' },
            };
        }

        if (validateInput) {
            const exists = usersToInvite.some(
                (u) =>
                    (validateInput.email && u.email === validateInput.email) ||
                    (validateInput.phone && u.phone === validateInput.phone)
            );

            if (!exists) {
                await getUserRegisteredAsync({
                    email: validateInput.email,
                    phone: validateInput.phone,
                });
            } else {
                setError(language['user_alert_duplicate_user_to_invite']);
            }
            setInputValue('');
        } else {
            setError(language['user_alert_invalid_fields_error']);
        }
        event.preventDefault();
    };

    const handleDelete = (identifier) => {
        const updatedUsers = usersToInvite.filter((user) => (user.phone || user.email) !== identifier);
        setLocalUsersToInvite(updatedUsers);
        setUsersToInvite(updatedUsers);
    };

    const handleClose = () => {
        setModalState(null);
        setShowCreateUserModal(false);
        setLocalUsersToInvite([]);
        setUsersToInvite([]);
        setInputValue('');
        setError('');
        setUserServicesSelected({ key: 'newServices', value: [] });
    };

    const openModalService = () => {
        setModalState('service');
        setShowCreateUserModal(2);
    };

    const handleSaveServices = (selectedIds) => {
        setUserServicesSelected({ key: 'newServices', value: selectedIds });
        setModalState('newUser');
        setShowCreateUserModal(true);
    };

    const openModalSuccess = async () => {
        if (usersToInvite.length && userServicesSelected.newServices.length) {
            await loadInviteUserAsync(user.capabilities.show_users_admin_view);
        } else {
            setError(language['user_alert_incomplete_fields_error']);
        }
    };

    const goToUserTable = () => {
        handleClose();
        history.push('/users');
    };

    return (
        <>
            {modalState === 'newUser' && (
                <Modal
                    size='sm'
                    className={`rounded-24 border-fill-medium align-items-centers ${theme}`}
                    show={true}
                    backdropClassName={`w-100 h-100 surface-bg-blackout ${theme}`}
                    onHide={handleClose}
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '70vh' }}
                >
                    <div
                        className={`column-between surface-bg-surface align-items-start rounded-24 w-100 ${theme}`}
                        style={{ minHeight: '70vh' }}
                    >
                        <div
                            className={`row-between align-items-center py-2 px-3 surface-bg-fill w-100 rounded-top-24 border-fill-regular-bottom ${theme}`}
                        >
                            <div className='w-100 row-center align-items-center'>
                                <p className={`label-large-semibold content-default-primary ${theme}`}>
                                    {language['new_user_title_header']}
                                </p>
                            </div>
                            <button
                                onClick={handleClose}
                                className={`column-center align-items-center rounded-30 border-fill-medium surface-bg-on-surface ${theme}`}
                                style={{ width: '25px', height: '25px' }}
                            >
                                <i
                                    className={`fa-light fa-xmark txt-10 content-default-primary ${theme}`}
                                    style={{ fontSize: 10 }}
                                />
                            </button>
                        </div>
                        <div className='column-start items-align-center w-100 p-3'>
                            <div className='column-start gap-3'>
                                <p className={`label-medium-medium content-default-primary ${theme}`}>
                                    {language['new_user_label_picker_services']}
                                </p>
                                <div
                                    onClick={openModalService}
                                    className={`row-between align-items-center py-2 px-3 gap-2 w-50 border-fill-medium surface-bg-on-surface-alpha rounded-pill pe-pointer ${theme}`}
                                >
                                    <p className={`label-small-medium content-default-primary pe-pointer ${theme}`}>
                                        {`${userServicesSelected.newServices.length} ${language['users_label_count_services']}`}
                                    </p>
                                    <i
                                        className={`fal fa-circle-chevron-right content-default-secondary txt-8 ${theme}`}
                                    />
                                </div>
                            </div>
                            <div className='column-start w-100 gap-2 mt-3'>
                                <p className={`label-medium-medium content-default-primary ${theme}`}>
                                    {language['new_user_label_input_users']}
                                </p>
                                <input
                                    type='text'
                                    value={inputValue}
                                    onChange={(e) => setInputValue(e.target.value)}
                                    onKeyDown={handleKeyDown}
                                    placeholder={language['new_user_placeholder_input_users']}
                                    disabled={userRegisteredLoading}
                                    className={`w-100 py-2 px-3 rounded-30 border-fill-medium surface-bg-surface label-small-medium content-default-secondary ${theme}`}
                                />
                                {error && (
                                    <p
                                        className={`txt-phrase txt-regular txt-6 txt-red-500 mt-1 w-100 text-wrap ${theme}`}
                                    >
                                        {error}
                                    </p>
                                )}
                            </div>
                            <div
                                className={`column-start overflow-auto border-none-scroll rounded-bottom w-100 ${theme}`}
                                style={{ minHeight: '35vh' }}
                            >
                                {usersToInvite.length === 0 ? (
                                    <div
                                        className={`column-center align-items-center w-100 mt-3 border-fill-medium-top ${theme}`}
                                    >
                                        <div className='column-center align-items-center w-100 mt-3'>
                                            <img
                                                src={theme === 'dark' ? empty_users : empty_users_light}
                                                alt='empty_users'
                                                style={{ width: '50%', height: '50%' }}
                                            />
                                        </div>
                                        <p
                                            className={`label-small-medium content-default-tertiary mt-3 w-50 text-center ${theme}`}
                                        >
                                            {language['new_user_description_empty_state']}
                                        </p>
                                    </div>
                                ) : (
                                    <div className={`column-start align-items-center gap-3 mt-2 border-fill-medium-top w-100 ${theme}`}>
                                        {usersToInvite.map((item, index) => {
                                            const hasError =
                                                !user.capabilities.show_users_admin_view &&
                                                item.userType?.idUserType !== 17;
                                            return (
                                                <div
                                                    key={index}
                                                    className='row-between align-items-center w-100 mt-3'
                                                    style={{ flexShrink: 0, minWidth: '60vh' }}
                                                >
                                                    <div className='row-start align-items-center gap-2 align-items-center w-70'>
                                                        <p
                                                            className={`label-small-medium content-default-primary ${theme}`}
                                                        >
                                                            {item.name ? item.name : item.phone || item.email}
                                                        </p>
                                                        {hasError ? (
                                                            <Whisper
                                                                placement='top'
                                                                trigger='hover'
                                                                speaker={
                                                                    <Tooltip>
                                                                        Este usuario tiene un permiso superior. No
                                                                        puedes asignar nuevos proyectos.
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <div
                                                                    className={`row-center gap-2 align-items-center bg-alpha-red-32 border-color-red rounded-30 py-1 px-2 ${theme}`}
                                                                >
                                                                    <p
                                                                        className={`label-XSmall-regular txt-red-500 ${theme}`}
                                                                    >
                                                                        No puedes invitar a este usuario
                                                                    </p>
                                                                    <i
                                                                        className={`fa fa-regular fa-circle-question txt-red-500 txt-10 ${theme}`}
                                                                    />
                                                                </div>
                                                            </Whisper>
                                                        ) : item.userExist ? (
                                                            <Whisper
                                                                placement='top'
                                                                trigger='hover'
                                                                speaker={
                                                                    <Tooltip>
                                                                        Podrás cambiar el rol desde el módulo usuarios.
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <div
                                                                    className={`rounded-30 row-center gap-2 align-items-center border-fill-regular button-fill-disabled py-1 px-2 row-center gap-2 align-items-center ${theme}`}
                                                                >
                                                                    <p
                                                                        className={`label-XSmall-regular content-default-primary ${theme}`}
                                                                    >
                                                                        {language['new_user_label_registered_user']}
                                                                    </p>
                                                                    <i
                                                                        className={`fa fa-regular fa-circle-question content-default-primary txt-10 ${theme}`}
                                                                    />
                                                                </div>
                                                            </Whisper>
                                                        ) : null}
                                                    </div>
                                                    {userTypesSelect && (
                                                        <div className='row-end align-items-center gap-3 w-30'>
                                                            {hasError ? null : !item.userExist ? (
                                                                <div className='w-90'>
                                                                    <Select
                                                                        value={item.userType?.idUserType}
                                                                        options={userTypesSelect}
                                                                        keyName='idUserType'
                                                                        labelName='description'
                                                                        width={170}
                                                                        background='surface-bg-surface'
                                                                        onChange={(value) => {
                                                                            const updatedUsers = usersToInvite.map((u) =>
                                                                                (u.phone || u.email) ===
                                                                                (item.phone || item.email)
                                                                                    ? {
                                                                                        ...u,
                                                                                        userType: {
                                                                                            idUserType: value,
                                                                                            description:
                                                                                                userTypesSelect.filter(
                                                                                                    (type) =>
                                                                                                        type.idUserType ===
                                                                                                        value
                                                                                                )[0].description,
                                                                                        },
                                                                                    }
                                                                                    : u
                                                                            );
                                                                            setLocalUsersToInvite(updatedUsers);
                                                                            setUsersToInvite(updatedUsers);
                                                                        }}
                                                                        disabled={item.userExist}
                                                                    />
                                                                </div>
                                                                
                                                            ) : (
                                                                <div
                                                                    className={`py-2 px-3 button-fill-disabled button-content-disabled label-medium-medium rounded-30 ${theme}`}
                                                                >
                                                                    {item.userType?.description}
                                                                </div>
                                                            )}
                                                            <button
                                                                onClick={() => handleDelete(item.phone || item.email)}
                                                                className={`column-center align-items-center rounded-30 surface-bg-surface ${theme}`}
                                                                style={{ width: '24px', height: '24px' }}
                                                            >
                                                                <i
                                                                    className={`fal fa-circle-xmark content-default-tertiary txt-10 ${theme}`}
                                                                />
                                                            </button>
                                                        </div>
                                                    )}
                                                </div>
                                            );
                                        })}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div
                            className={`row-end align-items-center w-100 px-3 gap-2 surface-bg-fill w-100 border-fill-regular-top rounded-bottom-24 ${theme}`}
                            style={{ flexShrink: 0, height: '6vh' }}
                        >
                            <button
                                onClick={goToUserTable}
                                className={`row-center align-items-center py-2 px-3 gap-2 button-border-outline surface-bg-fill rounded-pill pe-pointer ${theme}`}
                            >
                                <p className={`label-small-medium button-content-default ${theme}`}>
                                    {language['new_user_button_users_module']}
                                </p>
                            </button>
                            <button
                                onClick={openModalSuccess}
                                className={`row-center align-items-center py-2 px-3 gap-2 ${
                                    usersToInvite.length === 0
                                        ? 'border-fill-medium surface-bg-on-surface-alpha button-content-disabled'
                                        : 'button-fill-brand button-content-equal'
                                }  rounded-pill pe-pointer ${theme}`}
                                disabled={!isEnable || usersLoading}
                            >
                                <p className={`label-small-medium ${theme}`}>
                                    {usersLoading ? 'Enviando...' : language['new_user_button_send_invitation']}
                                </p>
                            </button>
                        </div>
                    </div>
                </Modal>
            )}

            {modalState === 'service' && (
                <ModalService
                    onClose={() => {
                        setModalState('newUser');
                        setShowCreateUserModal(true);
                    }}
                    onSave={handleSaveServices}
                />
            )}
            {modalState === 'success' && (
                <ModalSuccess
                    onClose={handleClose}
                    onAddMoreUsers={() => {
                        setModalState('newUser');
                        setShowCreateUserModal(true);
                    }}
                />
            )}
        </>
    );
};

const mapStateToProps = (state) => ({
    showCreateUserModal: state.users.showCreateUserModal,
    userServicesSelected: state.users.userServicesSelected,
    usersToInvite: state.users.usersToInvite,
    language: state.shared.language,
    theme: state.theme.theme,
    user: state.users.user,
    userRegisteredLoading: state.users.userRegisteredLoading,
    usersLoading: state.users.usersLoading,
    showInviteSuccessModal: state.users.showInviteSuccessModal,
    userTypesSelect: state.users.userTypesSelect,
});

const mapDispatchToProps = (dispatch) => ({
    setShowCreateUserModal: (payload) => dispatch(usersActions.setShowCreateUserModal(payload)),
    setUsersToInvite: (payload) => dispatch(usersActions.setUsersToInvite(payload)),
    getUserRegisteredAsync: (payload) => dispatch(usersActions.getUserRegisteredAsync(payload)),
    getUserTypesAsync: (payload) => dispatch(usersActions.getUserTypesAsync(payload)),
    loadInviteUserAsync: (payload) => dispatch(usersActions.loadInviteUserAsync(payload)),
    setUserServicesSelected: (payload) => dispatch(usersActions.setUserServicesSelected(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewUser);
