export const SET_MENU = 'SET_MENU';
export const SET_CURRENT_MODULE = 'SET_CURRENT_MODULE';
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const SET_SHOW_BLACK_SCREEN = 'SET_SHOW_BLACK_SCREEN';
export const SET_SHOW_BLACK_SCREEN_IFRAME = 'SET_SHOW_BLACK_SCREEN_IFRAME';
export const LOGOUT = 'LOGOUT';
export const SET_SHOW_CALENDAR = 'SET_SHOW_CALENDAR';
export const SET_SHOW_BLACK_SCREEN_DRAWER = 'SET_SHOW_BLACK_SCREEN_DRAWER';
export const SET_SHOW_NPS_SURVEY = 'SET_SHOW_NPS_SURVEY';
export const SET_START_ONBOARDING = 'SET_START_ONBOARDING';
export const SET_CONTROLLED_ONBOARDING = 'SET_CONTROLLED_ONBOARDING';
export const SET_SHOW_DRAWER_ONBOARDING = 'SET_SHOW_DRAWER_ONBOARDING';
export const SET_SHOW_PULSE_ANIMATION = 'SET_SHOW_PULSE_ANIMATION';
export const SET_SCREEN_PADDING_BOTTOM = 'SET_SCREEN_PADDING_BOTTOM';