import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';

import * as invoicesActions from '../../store/actions/Invoices';

import { sortByDate } from '../../services/CalendarHandler';

import { CircularProgressbarWithChildren } from 'react-circular-progressbar';

import QRCode from "react-qr-code";

import signature from '../../imgs/jeh-signature.png';

import TooltipInfo from '../shared/utilities/Tooltip';

import Logo from "../../imgs/logo-erco-black.svg";
import PSELogo from "../../imgs/pse_bg_blue.svg";
import VisaLogo from "../../imgs/visa_bg_blue.svg";
import MastercardLogo from "../../imgs/mastercadr_bg_black.svg";
import AmericanLogo from "../../imgs/american_express.svg";
import VigiladoLogo from "../../imgs/vigilado_superservicios.svg";

import CryptoJS from 'crypto-js';

const encryptId = (id, key) => {
    const keyBytes = CryptoJS.enc.Utf8.parse(key);
    const iv = CryptoJS.enc.Utf8.parse('0123456789abcdef');
    const encrypted = CryptoJS.AES.encrypt(id, keyBytes, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
    });
    return encrypted.ciphertext.toString(CryptoJS.enc.Base64url);
};

const InvoiceDetails = (props) => {
    let theme = props.theme;
    let invoiceDetailsContent = props.invoiceDetailsContent;
    let invoiceDetails = props.invoiceDetails;
    let invoiceShow = props.invoiceShow;

    const baseLink = "https://erco.energy/co/servicios/comercializacion/pago-factura";
    const secretKey = process.env.REACT_APP_QR_CRYPTO_KEY;

    const loadInvoiceDetailsContent = props.loadInvoiceDetailsContent;
    
    const groupConceptsByServiceType = (concepts) => {
        return concepts.reduce((result, concept) => {
            const serviceType = concept.service_type;
            if (!result[serviceType]) {
                result[serviceType] = [];
            }
            result[serviceType].push(concept);
            return result;
        }, {});
    };

    useEffect(() => {
        if (invoiceDetails !== null && invoiceShow?.type_content !== 'pdf') { loadInvoiceDetailsContent() }
    }, [loadInvoiceDetailsContent, invoiceDetails, invoiceShow]);

    theme = 'light'

    return (
        <Fragment>
            {invoiceDetailsContent !== null ?
                <section key={invoiceDetailsContent.id_bill} className="w-100" style={{ maxHeight: '100%' }}>
                    <div className={`position-relative column-between align-items-center w-100 h-100 surface-bg-surface ${theme}`} style={{ minHeight: 1440 }}>
                        <div className="column-start align-items-start align-items-stretch w-100">
                            {invoiceDetailsContent.content.map((c, index) => (<Fragment key={index}>
                                <div className={`position-absolute right-0 txt-2 txt-phrase txt-regular txt-black vertical-banner-invoice ${theme}`}>Impreso por CENET SA NIT 830057860 - MISFACTURAS | {c.dian_resolution}</div>
                                <div className='w-100 mt-4'>
                                    <div className="row-between align-items-center align-items-stretch mx-4">
                                        <img src={Logo} alt="Logo Empresa" width={120} />
                                        <div className={`row-center align-items-center surface-bg-on-surface rounded-18 px-2 ${theme}`} style={{ height: '35px' }}>
                                            <div className={`content-default-primary label-medium-regular ${theme}`}>{props.language.invoice_content_text_bill}</div>
                                            <div className={`content-default-primary label-medium-semibold ms-1 ${theme}`}>{c.bill}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className='w-100 mt-3'>
                                    <div className={`row-between align-items-center surface-bg-fill mx-4 py-2 px-4 rounded-8 dark ${theme}`}>
                                        <div className='w-60'>
                                            <div className={`content-default-primary label-large-regular dark ${theme}`}>{props.language.invoice_content_text_total} de la factura {c.bill}</div>
                                            <div className={`content-color-green heading-h6-semibold mt-1 dark ${theme}`}>{c.total}</div>
                                            <div className={`content-default-primary label-small-regular dark ${theme}`}>{props.language.invoice_content_text_billed_period}: {c.billed_since} a {c.billed_until}</div>
                                        </div>
                                        <div className="flex-grow-1">
                                            <div className="row-start">
                                                <div className={`flex-grow-1 w-50 row-start align-items-center rounded-10 py-1 content-default-primary ${theme}`} style={{ background: 'rgba(255, 255, 255, 0.178)' }}>
                                                    <i className={`fa-solid fa-bolt txt-10 txt-white mx-2 dark ${theme}`}></i>
                                                    <div className="w-100">
                                                        <div className={`content-default-primary label-small-regular dark ${theme}`}>{props.language.invoice_content_text_bill_date}</div>
                                                        <div className={`content-default-primary label-small-semibold dark ${theme}`}>{c.bill_date}</div>
                                                    </div>
                                                </div>
                                                <div className={`flex-grow-1 w-50 row-start align-items-center rounded-10 border-1 border-color-orange-600 py-1 ms-2 ${theme}`}>
                                                    <i className={`content-color-orange txt-10 fa fa-exclamation-circle mx-2 dark ${theme}`}></i>
                                                    <div>
                                                        <div className={`content-color-orange label-small-regular dark ${theme}`}>{props.language.invoice_content_text_due_date}</div>
                                                        <div className={`content-color-orange label-small-semibold dark ${theme}`}>{c.due_date}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            {c.suspension_date === 'Suspendido' ? (
                                                <div className={`row-center align-items-center rounded-10 py-1 content-default-primary mt-1 ${theme}`} style={{ background: 'rgba(255, 255, 255, 0.178)' }}>
                                                    <div className="text-center w-100">
                                                        <div className={`content-default-primary label-small-regular dark ${theme}`}>{props.language.support_select_label_service}</div>
                                                        <div className={`content-default-primary label-small-semibold dark ${theme}`}>{c.suspension_date}</div>
                                                    </div>
                                                </div>
                                            ) : c.suspension_date === null && c.outstanding_balance === "0" ? (
                                                null
                                            ) : (
                                                <div className={`row-center align-items-center rounded-10 py-1 content-default-primary mt-1 ${theme}`} style={{ background: 'rgba(255, 255, 255, 0.178)' }}>
                                                    <div className="text-center w-100">
                                                        <div className={`content-default-primary label-small-regular dark ${theme}`}>{props.language.invoice_content_text_suspension_date}</div>
                                                        <div className={`content-default-primary label-small-semibold dark ${theme}`}>{c.suspension_date}</div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="row-between align-items-stretch w-100 px-4 mt-3">
                                    <div className="column-start flex-grow-1" style={{ width: '63%' }}>
                                        {c.outstanding_balance === "0" ? (
                                            c.creg_1050072024 === "upper_deviation" ? (
                                                <div className={`row-start align-items-center surface-bg-on-surface p-2 me-2 rounded-8 ${theme}`}>
                                                    <i className={`fa-regular fa-do-not-enter content-color-orange mx-2 txt-14 ${theme}`}></i>
                                                    <div className={`content-default-primary label-small-regular text-right text-wrap w-100 ${theme}`}>
                                                        <strong className={`content-color-orange ${theme}`}>{props.language.invoice_content_check_consumption}</strong>{props.language.invoice_content_text_check_consumption}
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className={`row-start align-items-center surface-bg-on-surface p-2 me-2 rounded-8 ${theme}`}>
                                                    <i className={`fa-regular fa-face-laugh-beam content-color-green mx-2 txt-14 ${theme}`}></i>
                                                    <div className={`content-default-primary label-small-regular text-right text-wrap w-100 ${theme}`}>
                                                        <strong className={`content-color-green ${theme}`}>{props.language.invoice_content_text_strong_balance}</strong>
                                                        {props.language.invoice_content_first_text_balance} {props.language.invoice_content_second_text_balance}
                                                    </div>
                                                </div>
                                            )
                                        ) : (
                                            <div className={`row-between align-items-center surface-bg-on-surface p-2 me-2 rounded-8 ${theme}`}>
                                                <i className={`fa-regular fa-warning content-color-red mx-2 txt-14 ${theme}`}></i>
                                                <div className={`content-default-primary label-small-regular text-right text-wrap w-100 ${theme}`}>
                                                    <strong className={`content-color-red ${theme}`}>¡Atención!</strong> {props.language.invoice_content_text_strong_outstanding_balance} por un valor de:<br />
                                                    <strong className={`content-color-red label-medium-semibold ${theme}`}>${c.outstanding_balance}</strong>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className="column-start flex-grow-1" style={{ width: '37%' }}>
                                        <div className={`row-start align-items-center surface-bg-on-surface p-2 rounded-8 ${theme}`}>
                                            <i className={`fa-solid fa-exclamation-circle content-color-orange mx-2 txt-14 ${theme}`}></i>
                                            <div className={`content-default-primary label-small-regular text-right text-wrap w-100 ${theme}`}>{props.language.invoice_content_text_suspension_date_empty}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className='w-100 mt-3'>
                                    <div className={`align-items-stretch surface-bg-on-surface p-2 rounded-8 mx-4 ${theme}`}>
                                        <div className="row-start align-center">
                                            <div className={`surface-bg-surface py-2 px-2 rounded-8 flex-grow-1 me-2 ${theme}`}>
                                                <div className={`content-default-secondary label-small-regular ${theme}`}>{props.language.invoice_content_text_customer_name}</div>
                                                <div className={`content-default-primary label-small-semibold ${theme}`}>{c.customer_name}</div>
                                            </div>
                                            <div className={`surface-bg-surface py-2 px-2 rounded-8 w-20 ${theme}`}>
                                                <div className={`content-default-secondary label-small-regular ${theme}`}>{props.language.invoice_content_text_contract}</div>
                                                <div className={`content-default-primary label-small-semibold ${theme}`}>{c.contract}</div>
                                            </div>
                                        </div>
                                        <div className="row-start align-center my-2">
                                            <div className={`surface-bg-surface py-2 px-2 rounded-8 me-2 w-50 flex-grow-1 ${theme}`}>
                                                <div className={`content-default-secondary label-small-regular ${theme}`}>{c.type_id}</div>
                                                <div className={`content-default-primary label-small-semibold ${theme}`}>{c.id}</div>
                                            </div>
                                            <div className={`surface-bg-surface py-2 px-2 rounded-8 w-50 flex-grow-1 ${theme}`}>
                                                <div className={`content-default-secondary label-small-regular ${theme}`}>{props.language.invoice_content_text_niu}</div>
                                                <div className={`content-default-primary label-small-semibold ${theme}`}>{c.niu}</div>
                                            </div>
                                            {c.factor_embedded && (
                                                <div className={`surface-bg-surface py-2 px-2 rounded-8 ms-2 w-50 flex-grow-1 ${theme}`}>
                                                    <div className={`content-default-secondary label-small-regular ${theme}`}>{props.language.invoice_content_text_embedded_border_factor}</div>
                                                    <div className={`content-default-primary label-small-semibold ${theme}`}>{c.factor_embedded}</div>
                                                </div>
                                            )}
                                        </div>
                                        <div className="row-start align-center my-2">
                                            <div className={`surface-bg-surface py-2 px-2 rounded-8 me-2 w-50 flex-grow-1 ${theme}`}>
                                                <div className={`content-default-secondary label-small-regular ${theme}`}>{props.language.invoice_content_text_service_name}</div>
                                                <div className={`content-default-primary label-small-semibold ${theme}`}>{c.service_name}</div>
                                            </div>
                                            <div className={`surface-bg-surface py-2 px-2 rounded-8 w-50 flex-grow-1 ${theme}`}>
                                                <div className={`content-default-secondary label-small-regular ${theme}`}>{props.language.invoice_content_text_billing_address}</div>
                                                <div className={`content-default-primary label-small-semibold ${theme}`}>{c.billing_address}</div>
                                            </div>
                                        </div>
                                        <div className="row-start align-center">
                                            <div className={`surface-bg-surface py-2 px-2 rounded-8 flex-grow-1 ${theme}`}>
                                                <div className={`content-default-secondary label-small-regular ${theme}`}>{props.language.invoice_content_text_municipality}</div>
                                                <div className={`content-default-primary label-small-semibold ${theme}`}>{c.city_name}</div>
                                            </div>
                                            <div className={`surface-bg-surface py-2 px-2 rounded-8 ms-2 flex-grow-1 ${theme}`}>
                                                <div className={`content-default-secondary label-small-regular ${theme}`}>{props.language.invoice_content_text_department}</div>
                                                <div className={`content-default-primary label-small-semibold ${theme}`}>{c.state_name}</div>
                                            </div>
                                            <div className={`surface-bg-surface py-2 px-2 rounded-8 ms-2 flex-grow-1 ${theme}`}>
                                                <div className={`content-default-secondary label-small-regular ${theme}`}>{props.language.invoice_content_text_market}</div>
                                                {c.is_unregulated_market ?
                                                    <div className={`content-default-primary label-small-semibold ${theme}`}>{props.language.invoice_content_text_unregulated_market}</div> :
                                                    <div className={`content-default-primary label-small-semibold ${theme}`}>{props.language.invoice_content_text_regulated_market}</div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='mx-4 mt-3'>
                                    <div className={`content-default-primary label-medium-semibold text-right ${theme}`}>{props.language.invoice_content_text_active_energy_consumption}</div>
                                </div>
                                <div className='w-100 mt-2'>
                                    <div className="row-between align-items-stretch mx-4">
                                        <div className="column-start align-center w-80">
                                            <div className={`py-3 border-1 border-color-gray-100 surface-bg-on-surface py-2 px-3 rounded-8 ${theme}`}>
                                                <div className="row-between w-100 text-center">
                                                    <div className="w-60">
                                                        <div className={`row-between align-items-center surface-bg-surface rounded-8 py-3 border-1 border-color-gray-100 ${theme}`}>
                                                            <div className="col-4">
                                                                <div className={`content-default-primary label-small-regular ${theme}`}>{props.language.invoice_content_text_days_billed}</div>
                                                                <div className={`content-default-primary label-small-semibold mt-1 ${theme}`}>{c.days_billed}</div>
                                                            </div>
                                                            <div className="col-4">
                                                                <div className={`content-default-primary label-small-regular ${theme}`}>{props.language.invoice_content_text_billed_since}</div>
                                                                <div className={`content-default-primary label-small-semibold mt-1 ${theme}`}>{c.billed_since}</div>
                                                            </div>
                                                            <div className="col-4">
                                                                <div className={`content-default-primary label-small-regular ${theme}`}>{props.language.invoice_content_text_billed_until}</div>
                                                                <div className={`content-default-primary label-small-semibold mt-1 ${theme}`}>{c.billed_until}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="w-20">
                                                        <div className={`surface-bg-surface rounded-8 py-3 border-1 border-color-gray-100 ${theme}`}>
                                                            <div className={`content-default-primary label-small-regular ${theme}`}>{props.language.invoice_content_text_avg_six_months}</div>
                                                            <div className={`content-default-primary label-small-semibold mt-1 ${theme}`}>{c.avg_6_months} kWh</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="column-end" style={{ height: 250 }}>
                                                    <div className="row-between align-items-stretch">
                                                        <div className="row-start align-items-end mb-3 me-1">
                                                            <div className="zindex-1 text-end">
                                                                {[100, 75, 50, 25, 0].map((number, index) => (
                                                                <div key={index} className={`content-default-secondary label-XSmall-regular ${index === 0 ? "mt-1" : "mt-3"} ${index === 4 ? "mb-2" : ""} ${theme}`}>
                                                                    {number}
                                                                </div>
                                                                ))}
                                                            </div>
                                                            <div className={`content-default-secondary label-XSmall-semibold zindex-2 ms-1 ${theme}`}>
                                                                {Array.from({ length: 12 }).map((_, index) => (
                                                                <div key={index}>-</div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                        {invoiceDetailsContent.graph.sort(sortByDate).map((g, index, array) => {
                                                            const isLast = index === array.length - 1;
                                                            const isDeviation = c.creg_1050072024_r === "upper_deviation" || c.creg_1050072024 === "upper_deviation";
                                                            const backgroundColor = isLast
                                                                ? isDeviation
                                                                    ? "#EA580C"
                                                                    : "#7DA60C"
                                                                : isDeviation
                                                                    ? "rgba(249, 115, 22, 0.32)"
                                                                    : "rgba(158, 204, 20, 0.32)";
                                                            const textColor = isDeviation ? "content-color-orange" : "content-color-green";

                                                            return (
                                                                <div key={index} className="column-end align-items-center"
                                                                    style={{
                                                                        width: 95,
                                                                        backgroundColor: isLast ? "#E8E8E8" : undefined,
                                                                        padding: isLast ? "8px" : "0 0 8px 0",
                                                                        borderRadius: isLast ? "8px" : undefined,
                                                                    }}>
                                                                    <div className={`label-XSmall-semibold content-color-blue ${theme}`}>${g.total}</div>
                                                                    <div className={`label-XSmall-semibold content-default-primary ${theme}`}>${g.cu}</div>
                                                                    <div className={`label-XSmall-semibold ${textColor} ${theme}`}>
                                                                        {g.quantity}</div>
                                                                    <div className="my-1 mt-2"
                                                                        style={{
                                                                            borderRadius: "8px 8px 0 0",
                                                                            height: `${g.pixels}px`,
                                                                            width: 50,
                                                                            backgroundColor: backgroundColor,
                                                                        }}></div>
                                                                    <div className={`label-small-semibold content-default-primary ${theme}`}>{isLast ? "Actual" : `${g.month}`}</div>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                                <div className="row-between align-items-center mt-2">
                                                    <div className="row-start align-items-center">
                                                        <i className={`fa-regular fa-coin txt-10 content-color-blue ${theme}`}></i>
                                                        <div className={`label-XSmall-semibold content-color-blue ms-2 ${theme}`}>{props.language.invoice_content_text_monthly_rate_cu}</div>
                                                    </div>
                                                    <div className="row-start align-items-center">
                                                        <i className={`fa-solid fa-money-bill txt-10 content-default-primary ${theme}`}></i>
                                                        <div className={`label-XSmall-semibold content-default-primary ms-2 ${theme}`}>{props.language.invoice_content_text_label_pay_month}</div>
                                                    </div>
                                                    <div className={`row-start align-items-center ${c.creg_1050072024_r === "upper_deviation" || c.creg_1050072024 === "upper_deviation"
                                                                ? 'content-color-orange'
                                                                : 'content-color-green'
                                                            } ${theme}`}>
                                                        <i className={`fa-regular fa-circle-bolt txt-10 ${theme}`}></i>
                                                        <div className={`label-XSmall-semibold ms-2 ${theme}`}>{props.language.invoice_content_text_consumption}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-center ms-2 w-20">
                                            <div className={`flex-grow-1 surface-bg-surface rounded-8 py-3 border-1 ${c.creg_1050072024_r === "upper_deviation" || c.creg_1050072024 === "upper_deviation"
                                                    ? 'border-color-orange content-color-orange'
                                                    : 'border-color-green-550 content-color-green'
                                                } ${theme}`}>
                                                <div className={`label-small-regular ${theme}`}>{props.language.invoice_content_current_consumption}</div>
                                                <div className={`label-small-semibold mt-1 ${theme}`}>{c.consumption} kWh</div>
                                            </div>
                                            <div className={`flex-grow-1 mt-1 surface-bg-surface rounded-8 py-2 text-start ${theme}`}>
                                                <div className={`label-small-regular content-default-primary ${theme}`}>{props.language.tag_manager_table_header_reactive_energy}</div>
                                                <div className={`label-small-regular mt-1 txt-gray-400 ${theme}`}>(kVArh)</div>
                                            </div>
                                            <div className={`flex-grow-1 mt-1 mb-2 surface-bg-surface rounded-8 py-3 border-1 border-color-gray-100 ${theme}`}>
                                                <div className={`content-default-primary label-small-regular ${theme}`}>{props.language.invoice_content_text_reactive_factor}</div>
                                                <div className={`content-default-primary label-small-semibold mt-1 ${theme}`}>{c.reactive_factor}</div>
                                            </div>
                                            <div className={`flex-grow-1 surface-bg-surface rounded-8 py-3 border-1 border-color-gray-100 ${theme}`}>
                                                <div className={`content-default-primary label-small-regular ${theme}`}>{props.language.invoice_content_text_billed_inductive}</div>
                                                <div className={`content-default-primary label-small-semibold mb-3 mt-1 ${theme}`}>{c.amount_billed_reactive_inductive}</div>
                                                <div className={`content-default-primary label-small-regular ${theme}`}>{props.language.invoice_content_text_billed_capacity}</div>
                                                <div className={`content-default-primary label-small-semibold mb-3 mt-1 ${theme}`}>{c.amount_billed_reactive_capacitive}</div>
                                                <div className={`content-default-primary label-small-regular ${theme}`}>{props.language.invoice_content_text_total_billed}</div>
                                                <div className={`content-default-primary label-small-semibold mt-1 ${theme}`}>{c.amount_billed_reactive}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="w-100 mt-2">
                                    <div className={`row-between text-center label-small-regular flex-grow-1 mx-4 ${theme}`}>
                                        <div className={`surface-bg-on-surface rounded-8 py-3 border-1 border-color-gray-100 flex-grow-1 w-30 ${theme}`}>
                                            <div className={`content-default-primary label-small-regular ${theme}`}>{props.language.invoice_content_text_or}</div>
                                            <div className={`content-default-primary label-small-semibold mt-1 ${theme}`}>{c.or}</div>
                                        </div>
                                        <div className={`surface-bg-on-surface rounded-8 mx-2 py-3 border-1 border-color-gray-100 flex-grow-1 w-30 ${theme}`}>
                                            <div className={`content-default-primary label-small-regular ${theme}`}>{props.language.invoice_content_text_distr_circuit}</div>
                                            <div className={`content-default-primary label-small-semibold mt-1 ${theme}`}>{c.distr_circuit}</div>
                                        </div>
                                        <div className={`surface-bg-on-surface rounded-8 py-3 border-1 border-color-gray-100 flex-grow-1 w-30 ${theme}`}>
                                            <div className={`content-default-primary label-small-regular ${theme}`}>{props.language.invoice_content_text_transf_number}</div>
                                            <div className={`content-default-primary label-small-semibold mt-1 ${theme}`}>{c.transf_number}</div>
                                        </div>
                                        <div className={`surface-bg-on-surface rounded-8 ms-2 py-3 border-1 border-color-gray-100 flex-grow-1 w-30 ${theme}`}>
                                            <div className={`content-default-primary label-small-regular ${theme}`}>{props.language.invoice_content_text_meter_code}</div>
                                            <div className={`content-default-primary label-small-semibold mt-1 ${theme}`}>{c.meter_code}</div>
                                        </div>
                                    </div>
                                </div>

                                <div className={`content-default-primary label-medium-semibold mx-4 my-3 ${theme}`}>{props.language.invoice_content_text_tariff_detail}</div>
                                <div className={`content-default-primary label-small-regular mx-4 text-wrap ${theme}`} style={{ textAlign: 'justify' }}>
                                    {props.language.invoice_content_first_text_tariff} <strong className={`content-color-green ${theme}`}>{props.language.invoice_content_first_text_strong_tariff}</strong> {props.language.invoice_content_second_text_tariff} <strong>{c.is_unregulated_market ? 'Mercado no regulado' : 'Mercado regulado'}</strong>, {props.language.invoice_content_third_text_tariff} <strong>{props.language.invoice_content_third_text_strong_tariff} {c.voltage_level}</strong>, {props.language.invoice_content_fourth_text_tariff} <strong>{props.language.invoice_content_fourth_text_strong_tariff} {c.social_stratum}</strong>, {props.language.invoice_content_fifth_text_tariff}
                                    <strong> {c.asset_ownership}</strong> {props.language.invoice_content_sixth_text_tariff} <strong>{c.location_type} {c.tariff_type}</strong>
                                </div>
                                <div className="row-between align-items-end px-4 py-3 w-100">
                                    {((start = 0, gap = 2) => (
                                        <>
                                        <div className="column-start align-items-center">
                                            <div style={{ height: 70, width: 70 }}>
                                            <CircularProgressbarWithChildren
                                                value={c.g_percentage}
                                                strokeWidth={12}
                                                styles={{
                                                path: {
                                                    stroke: `#7DA60C`,
                                                    strokeLinecap: "butt",
                                                    transform: `rotate(${start}deg)`,
                                                    transformOrigin: "center center",
                                                },
                                                trail: {
                                                    stroke: "#D3D3D3",
                                                },
                                                }}
                                            >
                                                <i className={`fa-regular fa-industry-windows txt-black txt-14 ${theme}`}></i>
                                            </CircularProgressbarWithChildren>
                                            </div>
                                            <div className={`content-default-primary label-small-semibold text-center w-100 ${theme}`}>G</div>
                                            <div className={`content-default-primary label-small-regular text-center w-100 ${theme}`}>{props.language.invoice_content_text_tariff_g}</div>
                                            <div className={`content-color-green label-small-semibold text-center w-100 ${theme}`}>${c.g}</div>
                                        </div>
                                        <div className="column-start align-items-center">
                                            <div style={{ height: 70, width: 70 }}>
                                            <CircularProgressbarWithChildren
                                                value={c.t_percentage}
                                                strokeWidth={12}
                                                styles={{
                                                path: {
                                                    stroke: `#7DA60C`,
                                                    strokeLinecap: "butt",
                                                    transform: `rotate(${start + c.g_percentage * 3.6 + gap}deg)`,
                                                    transformOrigin: "center center",
                                                },
                                                trail: {
                                                    stroke: "#D3D3D3",
                                                },
                                                }}
                                            >
                                                <i className={`fa-regular fa-utility-pole txt-black txt-14 ${theme}`}></i>
                                            </CircularProgressbarWithChildren>
                                            </div>
                                            <div className={`content-default-primary label-small-semibold text-center w-100 ${theme}`}>T</div>
                                            <div className={`content-default-primary label-small-regular text-center w-100 ${theme}`}>{props.language.invoice_content_text_tariff_t}</div>
                                            <div className={`content-color-green label-small-semibold text-center w-100 ${theme}`}>${c.t}</div>
                                        </div>
                                        <div className="column-start align-items-center">
                                            <div style={{ height: 70, width: 70 }}>
                                            <CircularProgressbarWithChildren
                                                value={c.d_percentage}
                                                strokeWidth={12}
                                                styles={{
                                                path: {
                                                    stroke: `#7DA60C`,
                                                    strokeLinecap: "butt",
                                                    transform: `rotate(${start + (c.g_percentage + c.t_percentage) * 3.6 + 2 * gap}deg)`,
                                                    transformOrigin: "center center",
                                                },
                                                trail: {
                                                    stroke: "#D3D3D3",
                                                },
                                                }}
                                            >
                                                <i className={`fa-regular fa-transformer-bolt txt-black txt-14 ${theme}`}></i>
                                            </CircularProgressbarWithChildren>
                                            </div>
                                            <div className={`content-default-primary label-small-semibold text-center w-100 ${theme}`}>D</div>
                                            <div className={`content-default-primary label-small-regular text-center w-100 ${theme}`}>{props.language.invoice_content_text_tariff_d}</div>
                                            <div className={`content-color-green label-small-semibold text-center w-100 ${theme}`}>${c.d}</div>
                                        </div>
                                        <div className="column-start align-items-center">
                                            <div style={{ height: 70, width: 70 }}>
                                            <CircularProgressbarWithChildren
                                                value={c.cv_percentage}
                                                strokeWidth={12}
                                                styles={{
                                                path: {
                                                    stroke: `#7DA60C`,
                                                    strokeLinecap: "butt",
                                                    transform: `rotate(${start + (c.g_percentage + c.t_percentage + c.d_percentage) * 3.6 + 3 * gap}deg)`,
                                                    transformOrigin: "center center",
                                                },
                                                trail: {
                                                    stroke: "#D3D3D3",
                                                },
                                                }}
                                            >
                                                <i className={`fa-regular fa-circle-bolt txt-black txt-14 ${theme}`}></i>
                                            </CircularProgressbarWithChildren>
                                            </div>
                                            {c.cot ? (
                                            <div className={`content-default-primary label-small-semibold text-center w-100 ${theme}`}>Cv + COT</div>
                                            ) : (
                                            <div className={`content-default-primary label-small-semibold text-center w-100 ${theme}`}>Cv</div>
                                            )}
                                            <div className={`content-default-primary label-small-regular text-center w-100 ${theme}`}>{props.language.invoice_content_text_tariff_cv}</div>
                                            <div className={`content-color-green label-small-semibold text-center w-100 ${theme}`}>${c.cv}</div>
                                        </div>
                                        <div className="column-start align-items-center">
                                            <div style={{ height: 70, width: 70 }}>
                                            <CircularProgressbarWithChildren
                                                value={c.pr_percentage}
                                                strokeWidth={12}
                                                styles={{
                                                path: {
                                                    stroke: `#7DA60C`,
                                                    strokeLinecap: "butt",
                                                    transform: `rotate(${start + (c.g_percentage + c.t_percentage + c.d_percentage + c.cv_percentage) * 3.6 + 4 * gap}deg)`,
                                                    transformOrigin: "center center",
                                                },
                                                trail: {
                                                    stroke: "#D3D3D3",
                                                },
                                                }}
                                            >
                                                <i className={`fa-regular fa-triangle-exclamation txt-black txt-14 ${theme}`}></i>
                                            </CircularProgressbarWithChildren>
                                            </div>
                                            <div className={`content-default-primary label-small-semibold text-center w-100 ${theme}`}>P</div>
                                            <div className={`content-default-primary label-small-regular text-center w-100 ${theme}`}>{props.language.invoice_content_text_tariff_pr}</div>
                                            <div className={`content-color-green label-small-semibold text-center w-100 ${theme}`}>${c.pr}</div>
                                        </div>
                                        <div className="column-start align-items-center">
                                            <div style={{ height: 70, width: 70 }}>
                                            <CircularProgressbarWithChildren
                                                value={c.r_percentage}
                                                strokeWidth={12}
                                                styles={{
                                                path: {
                                                    stroke: `#7DA60C`,
                                                    strokeLinecap: "butt",
                                                    transform: `rotate(${start + (c.g_percentage + c.t_percentage + c.d_percentage + c.cv_percentage + c.pr_percentage) * 3.6 + 5 * gap}deg)`,
                                                    transformOrigin: "center center",
                                                },
                                                trail: {
                                                    stroke: "#D3D3D3",
                                                },
                                                }}
                                            >
                                                <i className={`fa-regular fa-plug-circle-exclamation txt-black txt-14 ${theme}`}></i>
                                            </CircularProgressbarWithChildren>
                                            </div>
                                            <div className={`content-default-primary label-small-semibold text-center w-100 ${theme}`}>R</div>
                                            <div className={`content-default-primary label-small-regular text-center w-100 ${theme}`}>{props.language.invoice_content_text_tariff_r}</div>
                                            <div className={`content-color-green label-small-semibold text-center w-100 ${theme}`}>${c.r}</div>
                                        </div>
                                        {c.is_unregulated_market && (
                                            <div className="column-start align-items-center">
                                            <div style={{ height: 70, width: 70 }}>
                                                <CircularProgressbarWithChildren
                                                value={c.cr_percentage}
                                                strokeWidth={12}
                                                styles={{
                                                    path: {
                                                    stroke: `#7DA60C`,
                                                    strokeLinecap: "butt",
                                                    transform: `rotate(${start + (c.g_percentage + c.t_percentage + c.d_percentage + c.cv_percentage + c.pr_percentage + c.r_percentage) * 3.6 + 6 * gap}deg)`,
                                                    transformOrigin: "center center",
                                                    },
                                                    trail: {
                                                    stroke: "#D3D3D3",
                                                    },
                                                }}
                                                >
                                                <i className={`fa-regular fa-meter-bolt txt-black txt-14 ${theme}`}></i>
                                                </CircularProgressbarWithChildren>
                                            </div>
                                            <div className={`content-default-primary label-small-semibold text-center w-100 ${theme}`}>CR</div>
                                            <div className={`content-default-primary label-small-regular text-center w-100 ${theme}`}>{props.language.invoice_content_text_tariff_cr}</div>
                                            <div className={`content-color-green label-small-semibold text-center w-100 ${theme}`}>${c.cr}</div>
                                            </div>
                                        )}
                                        </>
                                    ))()}
                                </div>
                                <div className='px-4 w-100'>
                                    <div className={`row-between align-items-center bg-gray-50 p-2 w-100 rounded-8 ${theme}`}>
                                        {c.is_unregulated_market ?
                                            <div className={`row-start content-default-primary label-medium-semibold ${theme}`}>
                                                <p className={`me-2 content-color-blue ${theme}`}>CU</p><strong className={`content-color-green ${theme}`}>=</strong>
                                                <p className='mx-2'>G</p><strong className={`content-color-green ${theme}`}>+</strong>
                                                <p className='mx-2'>T</p><strong className={`content-color-green ${theme}`}>+</strong>
                                                <p className='mx-2'>D</p><strong className={`content-color-green ${theme}`}>+</strong>
                                                {c.cot ? <p className='mx-2'>CV + COT</p> : <p className='mx-2'>CV</p>}<strong className={`content-color-green ${theme}`}>+</strong>
                                                <p className='mx-2'>P</p><strong className={`content-color-green ${theme}`}>+</strong>
                                                <p className='ms-2'>R</p><strong className={`content-color-green ${theme}`}>+</strong>
                                                <p className='ms-2'>CR</p>
                                            </div> :
                                            <div className={`row-start content-default-primary label-medium-semibold ${theme}`}>
                                                <p className={`me-2 content-color-blue ${theme}`}>CU</p><strong className={`content-color-green ${theme}`}>=</strong>
                                                <p className='mx-2'>G</p><strong className={`content-color-green ${theme}`}>+</strong>
                                                <p className='mx-2'>T</p><strong className={`content-color-green ${theme}`}>+</strong>
                                                <p className='mx-2'>D</p><strong className={`content-color-green ${theme}`}>+</strong>
                                                {c.cot ? <p className='mx-2'>CV + COT</p> : <p className='mx-2'>CV</p>}<strong className={`content-color-green ${theme}`}>+</strong>
                                                <p className='mx-2'>P</p><strong className={`content-color-green ${theme}`}>+</strong>
                                                <p className='ms-2'>R</p>
                                            </div>
                                        }
                                        <div className={`content-color-blue label-medium-semibold ${theme}`}>{c.cot ? props.language.invoice_content_text_tariff_cu_cot : props.language.invoice_content_text_tariff_cu} = ${c.cu}</div>
                                    </div>
                                </div>
                            </Fragment>))}
                        </div>
                        <div className={`surface-bg-on-surface w-100 py-3 column-end ${theme}`} style={{ bottom: '0', left: '0', right: '0', position: 'relative' }}>
                            <div className="row-between px-4">
                                <div className="text-start" style={{ paddingRight: '0 10px' }}>
                                    <div className={`content-default-primary label-small-regular ${theme}`}>{props.language.invoice_content_text_support}</div>
                                    <div className={`content-default-primary label-small-semibold txt-bold ${theme}`}><i className={`fa-regular fa-envelope txt-10 txt-black me-1 ${theme}`}></i>soporte@erco.energy</div>
                                </div>
                                <div className="text-start w-30" style={{ padding: '0 10px' }}>
                                    <div className={`content-default-primary label-small-regular ${theme}`}>{props.language.power_factor_label_phase} {props.language.invoices_account_total_selection_separator} {props.language.invoice_content_text_support_emergency}</div>
                                    <div className={`content-default-primary label-small-semibold txt-bold ${theme}`}><i className={`fa-solid fa-phone txt-10 txt-black me-1 ${theme}`}></i>(+57) 601 6659652</div>
                                </div>
                                <div className="text-start" style={{ paddingLeft: '10px' }}>
                                    <div className={`content-default-primary label-small-regular ${theme}`}>{props.language.invoice_content_text_footer_page}</div>
                                    <div className={`content-default-primary label-small-semibold txt-bold ${theme}`}>1/3</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Pagina 2 */}
                    <div className={`position-relative column-between align-items-center surface-bg-surface mt-4 ${theme}`} style={{ minHeight: 1440 }}>
                        <div className="column-start align-items-start w-100">
                            {invoiceDetailsContent.content.map((c, index) => (
                                <div key={index} className='row-between w-100'>
                                    <div className={`position-absolute right-0 txt-2 txt-phrase txt-regular txt-black vertical-banner-invoice ${theme}`}>Impreso por CENET SA NIT 830057860 - MISFACTURAS | {c.dian_resolution}</div>
                                    <div className={`row-between align-items-center w-100 py-3 ${theme}`}>
                                        <div className='w-100 my-2'>
                                            <div className="row-between align-items-center align-items-stretch mx-4">
                                                <img src={Logo} alt="Logo Empresa" width={120} />
                                                <div className={`row-center align-items-center surface-bg-on-surface rounded-18 px-2 ${theme}`} style={{ height: '35px' }}>
                                                    <div className={`content-default-secondary label-medium-regular ${theme}`}>{props.language.invoice_content_text_bill}</div>
                                                    <div className={`content-default-primary label-medium-semibold ms-1 ${theme}`}>{c.bill}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            <div className="column-start align-items-center px-4 w-100">
                                {Object.entries(groupConceptsByServiceType(invoiceDetailsContent.concepts)).map(([serviceType, items], index) => (
                                    <Fragment key={index}>
                                        <div className={`row-start align-items-center py-2 w-100 rounded-8 w-100 mt-2
                                        ${serviceType === 'SALDOS A FAVOR DDV' || serviceType === 'SALDOS A FAVOR' ? `button-fill-brand` : serviceType.split(" (")[0] === 'INTERESES DE MORA' ? `button-fill-red` : `surface-bg-fill`} dark ${theme}`}>
                                            <i className={`${serviceType === 'SALDOS A FAVOR DDV' || serviceType === 'SALDOS A FAVOR' ? `fa-solid fa-bolt content-default-primary` : serviceType.split(" (")[0] === 'INTERESES DE MORA' ? `fal fa-coin content-default-primary` : `fak fa-${serviceType.toLocaleLowerCase()}-symbol content-color-green`} txt-10 ms-3 dark ${theme}`}></i>
                                            <div className={`content-default-primary label-small-medium ms-3 dark ${theme}`}>{serviceType}</div>
                                        </div>
                                        <div className={`border-1 mt-2 border-color-gray-200 rounded-10 w-100 ${theme}`} style={{ overflow: 'hidden' }}>
                                            {/* titulo */}
                                            <div className={`tr row-start align-items-center py-1 w-100 ${serviceType.split(" (")[0] === 'INTERESES DE MORA' ? `content-color-red` : `content-default-primary`} ${theme}`}>
                                                <div className="col-6 ps-1">
                                                    <div className={`label-small-semibold text-start ${theme}`}>{props.language.invoice_content_text_header_table_detail}</div>
                                                </div>
                                                <div className="col-2">
                                                    <div className={`label-small-semibold text-start ${theme}`}>
                                                        {serviceType === 'SALDOS A FAVOR DDV'
                                                            ? props.language.invoice_content_text_header_table_month_value
                                                            : serviceType.split(" (")[0] === 'INTERESES DE MORA'
                                                                ? props.language.invoice_content_text_header_table_concept_value
                                                                : props.language.invoice_content_text_header_table_unit_value}
                                                    </div>
                                                </div>
                                                <div className="col-2">
                                                    <div className={`label-small-semibold text-center ${theme}`}>
                                                        {serviceType.split(" (")[0] === 'INTERESES DE MORA'
                                                            ? props.language.invoice_content_text_header_table_days_quantity
                                                            : props.language.invoice_content_text_header_table_quantity}
                                                    </div>
                                                </div>
                                                <div className="col-2">
                                                    <div className={`label-small-semibold text-end me-2 ${theme}`}>{props.language.invoice_content_text_header_table_value}</div>
                                                </div>
                                            </div>
                                            {/* contenido */}
                                            {items.map((cp, conceptIndex) => (
                                                <div key={conceptIndex} className={` tr row-start align-items-center py-1 w-100 ${serviceType.split(" (")[0] === 'INTERESES DE MORA' ? `content-color-red` : `content-default-primary`} ${theme}`}>
                                                    <div className='col-6 ps-1'>
                                                        <div className='row-start align-items-center gap-1'>
                                                            {props.language[`tooltip_${cp.code.replace(/\./g, '_').toLocaleLowerCase()}`] !== "" ?
                                                                <TooltipInfo
                                                                    text={props.language[`tooltip_${cp.code.replace(/\./g, '_').toLocaleLowerCase()}`]}
                                                                    changeFocus={() => { }}
                                                                    fontSize={6}
                                                                    fontIcon={6}
                                                                    typeIcon={'solid'}
                                                                    colorIcon={'black'}
                                                                    bgColor={'gray-550'}
                                                                /> : null}
                                                            <div className={`label-small-semibold text-start pe-pointer ${theme}`}>
                                                                {serviceType === 'SALDOS A FAVOR DDV'
                                                                    ? cp?.item.replace(/\s*\(.*?\)\s*/g, '').trim()
                                                                    : cp.item}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-2">
                                                        <div className={`label-small-regular text-start pe-pointer ${theme}`}>
                                                            {serviceType === 'SALDOS A FAVOR DDV'
                                                                ? cp?.item.match(/\(([^)]+)\)/)[1]
                                                                : `$${cp.unit_value}`}
                                                        </div>
                                                    </div>
                                                    <div className="col-2">
                                                        <div className={`label-small-regular text-center pe-pointer ${theme}`}>{cp.quantity}</div>
                                                    </div>
                                                    <div className="col-2 pe-1">
                                                        <div className={`label-small-semibold text-end pe-pointer me-2 ${theme}`}>${cp.total}</div>
                                                    </div>
                                                    
                                                </div>
                                            ))}
                                            {/* subtotal */}
                                            <div className={`tr align-items-center row-start text-center py-1 w-100 pe-pointer ${serviceType.split(" (")[0] === 'INTERESES DE MORA' ? `content-color-red` : `content-default-primary`} ${theme}`}>
                                                <div className="col-6 ps-1">
                                                    <div></div>
                                                </div>
                                                <div className="col-2">
                                                    <div></div>
                                                </div>
                                                <div className="col-2">
                                                    <div className="label-small-semibold">
                                                        {serviceType.split(" (")[0] === 'INTERESES DE MORA' 
                                                            ? 'Total adeudado' 
                                                            : 'Subtotal'}
                                                    </div>
                                                </div>
                                                <div className="col-2 pe-1">
                                                    <div className="label-small-semibold text-end pe-3">
                                                        {(
                                                            invoiceDetailsContent?.content?.length &&
                                                            invoiceDetailsContent?.content[0]?.subtotal_concepts && 

                                                            invoiceDetailsContent.content[0].subtotal_concepts[serviceType]) ?
                                                            `$${invoiceDetailsContent.content[0].subtotal_concepts[serviceType]}` 
                                                        : ''}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Fragment>
                                ))}
                            </div>
                            {invoiceDetailsContent.content.map((c, index) => (
                                <div key={index} className={`column-center align-items-stretch w-100 ${theme}`}>
                                    {c.creg_1050072024 === "upper_deviation" && (
                                        <div className="mt-3 w-100">
                                            <div className="row-between align-items-stretch mx-4">
                                                <div className="row-start align-items-center bg-gray-100 p-3 rounded-14">
                                                    <i className={`fa-solid fa-do-not-enter txt-10 content-color-orange me-2 ${theme}`}></i>
                                                    <div className={`label-small-regular content-default-primary row-start text-right text-wrap w-100 me-2 ${theme}`}>
                                                        {c.creg_1050072024 === "upper_deviation" ? (
                                                            <p>
                                                                <strong className={`content-color-orange ${theme}`}>{props.language.invoice_content_text_creg_resolulion}</strong>
                                                                {props.language.invoice_content_creg_resolution_text_1_first} ({c.consumption} kWh).{props.language.invoice_content_creg_resolution_text_1_second} {c.consumption_month} ({c.creg_1050072024_consumption} kWh) {props.language.invoice_content_creg_resolution_text_1_third} {c.difference_abs} {props.language.invoice_content_creg_resolution_text_1_fourth}.
                                                            </p>
                                                        ) : (
                                                            <p>
                                                                <strong className={`content-color-orange ${theme}`}>{props.language.invoice_content_text_creg_resolulion}</strong>
                                                                {props.language.invoice_content_creg_resolution_text_2_first} ({c.consumption} kWh). {props.language.invoice_content_creg_resolution_text_2_second} ({c.creg_1050072024_consumption} kWh), {props.language.invoice_content_creg_resolution_text_2_third}.
                                                            </p>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {c.creg_1050072024_r === "upper_deviation" && (
                                        <div className="row-between align-items-stretch w-100">
                                            <div className="mt-3 w-100">
                                                <div className="row-start align-items-center bg-gray-100 p-3 rounded-14 mx-4">
                                                    <i className={`fa-solid fa-do-not-enter txt-10 content-color-orange me-2 ${theme}`}></i>
                                                    <div className={`label-small-regular content-default-primary row-start text-right text-wrap w-100 me-2 ${theme}`}>
                                                        {c.creg_1050072024_r_state === "no_collect" ? (
                                                            c.creg_1050072024_r === "upper_deviation" ? (
                                                                null
                                                            ) : (
                                                                <p>
                                                                    <strong className={`content-color-orange ${theme}`}>{props.language.invoice_content_text_creg_resolulion} </strong>
                                                                    {props.language.invoice_content_creg_resolution_r_text_3_first} {c.creg_1050072024_r_consumption_v0} {props.language.invoice_content_creg_resolution_r_text_3_second} {c.consumption_month_r}.
                                                                </p>
                                                            )
                                                        ) : c.creg_1050072024_r_state === "yes_collect" ? (
                                                            c.creg_1050072024_r === "upper_deviation" ? (
                                                                <p>
                                                                    <strong className={`content-color-orange ${theme}`}>{props.language.invoice_content_text_creg_resolulion} </strong>
                                                                    {props.language.invoice_content_creg_resolution_r_text_4_first} {Math.abs(parseFloat(c.creg_1050072024_r_consumption_v0) - parseFloat(c.creg_1050072024_r_consumption)).toFixed(2)} kWh {props.language.invoice_content_creg_resolution_r_text_4_second} {c.consumption_month_r}.
                                                                </p>
                                                            ) : (
                                                                null
                                                            )
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    <div className="w-100 mt-3">
                                        <div className={`column-start align-items-center bg-white border-1 border-color-gray-100 p-2 rounded-8 mx-4 ${theme}`}>
                                            <div className={`row-start align-items-center py-1 w-100 rounded-8 ${theme}`}>
                                                <div className="col-6">
                                                    <div className={`content-default-primary label-small-semibold text-start ms-2 ${theme}`}>{props.language.invoice_content_text_billing_charges}</div>
                                                </div>
                                                <div className="col-6">
                                                    <div className={`content-default-primary label-small-semibold text-end me-2 ${theme}`}>${c.charges}</div>
                                                </div>
                                            </div>
                                            <div className={`row-start align-items-center py-1 w-100 rounded-8 ${theme}`}>
                                                <div className="col-6">
                                                    <div className={`content-default-primary label-small-semibold text-start ms-2 ${theme}`}>{props.language.invoice_content_text_iva}</div>
                                                </div>
                                                <div className="col-6">
                                                    <div className={`content-default-primary label-small-semibold text-end me-2 ${theme}`}>${c.iva}</div>
                                                </div>
                                            </div>
                                            {c.negative_balance !== '0' ? (
                                                <div className={`row-start align-items-center py-1 w-100 rounded-8 ${theme}`}>
                                                    <div className="col-6">
                                                        <div className={`content-default-primary label-small-semibold text-start ms-2 ${theme}`}>{props.language.invoice_content_text_negative_balance}</div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className={`content-default-primary label-small-semibold text-end me-2 ${theme}`}>${c.negative_balance}</div>
                                                    </div>
                                                </div>
                                            ) : null}
                                            <div className={`row-start align-items-center py-1 w-100 rounded-8 ${theme}`}>
                                                <div className="col-6">
                                                    <div className={`content-default-primary label-small-semibold text-start ms-2 ${theme}`}>{props.language.invoice_content_text_subtotal}</div>
                                                </div>
                                                <div className="col-6">
                                                    <div className={`content-default-primary label-small-semibold text-end me-2 ${theme}`}>${c.subtotal}</div>
                                                </div>
                                            </div>
                                            {c.capital && (
                                                <div className={`row-start align-items-center py-1 w-100 rounded-8 ${theme}`}>
                                                    <div className="col-6">
                                                        <div className={`content-default-primary label-small-semibold text-start ms-2 ${theme}`}>{props.language.invoice_content_text_capital_record}</div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className={`content-default-primary label-small-semibold text-end me-2 ${theme}`}>${c.payments_capital}</div>
                                                    </div>
                                                </div>
                                            )}
                                            <div className={`row-between align-items-center bg-green-550 py-2 w-100 rounded-8 ${theme}`}>
                                                <div className="col-6">
                                                    <div className={`content-default-primary label-medium-semibold text-start ms-2 ${theme}`}>{`${props.language.invoice_content_text_total} de la factura ${c.bill}`}</div>
                                                </div>
                                                <div className="col-6">
                                                    <div className={`content-default-primary label-medium-semibold text-end me-2 ${theme}`}>${c.total}</div>
                                                </div>
                                            </div>
                                            {c.new_negative_balance !== '0' ? (
                                                <div className={`row-between align-items-center mt-2 py-2 w-100 rounded-8 ${theme}`} style={{background:'#E5F8FB'}}>
                                                    <div className="col-6">
                                                        <div className={`content-default-primary label-medium-semibold text-start ms-2 ${theme}`}>{props.language.invoice_content_text_new_negative_balance}</div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className={`content-default-primary label-medium-semibold text-end me-2 ${theme}`}>${c.new_negative_balance}</div>
                                                    </div>
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                    {c.notices && c.notices.length > 0 ?
                                        <div className="row-between align-items-stretch mt-2 w-100">
                                            <div className="w-100 mx-4">
                                                <div className="row-start align-items-center bg-gray-100 p-3 rounded-14">
                                                    <i className={`fa-solid fa-triangle-exclamation content-color-orange txt-10 me-2 ${theme}`}></i>
                                                    <div className={`content-default-primary label-small-regular text-right text-wrap w-100 me-2 ${theme}`}>
                                                        <strong className={`content-color-orange ${theme}`}>Importante</strong>
                                                        {c.notices}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : null
                                    }
                                </div>
                            ))}
                        </div>

                        <div className='w-100 column-end' style={{ bottom: '0', left: '0', right: '0', position: 'relative' }}>
                            <a className="mt-3 row-start aling-items-center rounded-8 my-1 pe-pointer mx-4" href="https://erco.energy/co/servicios/efficiency">
                                <img src="https://dqeqym4vbi7vy.cloudfront.net/images/banner_factura_V2_P2.png" alt="banner-user-data-update" style={{ width: '100%' }} />
                            </a>
                            <div className={`surface-bg-on-surface row-between mt-3 py-3 px-4 ${theme}`}>
                                <div className="text-start" style={{ paddingRight: '0 10px' }}>
                                    <div className={`content-default-primary label-small-regular ${theme}`}>{props.language.invoice_content_text_support}</div>
                                    <div className={`content-default-primary label-small-semibold ${theme}`}><i className={`fa-regular fa-envelope txt-10 txt-black me-1 ${theme}`}></i>soporte@erco.energy</div>
                                </div>
                                <div className="text-start w-30" style={{ padding: '0 10px' }}>
                                    <div className={`content-default-primary label-small-regular ${theme}`}>{props.language.power_factor_label_phase} {props.language.invoices_account_total_selection_separator} {props.language.invoice_content_text_support_emergency}</div>
                                    <div className={`content-default-primary label-small-semibold ${theme}`}><i className={`fa-solid fa-phone txt-10 txt-black me-1 ${theme}`}></i>(+57) 601 6659652</div>
                                </div>
                                <div className="text-start" style={{ paddingLeft: '10px' }}>
                                    <div className={`content-default-primary label-small-regular ${theme}`}>{props.language.invoice_content_text_footer_page}</div>
                                    <div className={`content-default-primary label-small-semibold ${theme}`}>2/3</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Pagina 3 */}
                    <div className={`position-relative column-between align-items-center surface-bg-surface mt-4 ${theme}`} style={{ minHeight: 1440 }}>
                        <div className="column-start align-items-start w-100">
                            {invoiceDetailsContent.content.map((c, index) => (<Fragment key={index}>
                                <div className={`position-absolute right-0 txt-2 txt-phrase txt-regular txt-black vertical-banner-invoice ${theme}`}>Impreso por CENET SA NIT 830057860 - MISFACTURAS | {c.dian_resolution}</div>
                                <div className='w-100 my-2'>
                                    <div className="row-between align-items-center align-items-stretch mx-4">
                                        <img src={Logo} alt="Logo Empresa" width={120} />
                                        <div className={`row-center align-items-center surface-bg-on-surface rounded-18 px-2 ${theme}`} style={{ height: '35px' }}>
                                            <div className={`content-default-primary label-medium-regular ${theme}`}>{props.language.invoice_content_text_bill}</div>
                                            <div className={`content-default-primary label-medium-semibold ms-1 ${theme}`}>{c.bill}</div>
                                        </div>
                                    </div>
                                </div>
                                {c.creg_0152018_month && (
                                    <div className='w-100 mt-3'>
                                        <div className='align-items-stretch row-between mx-4'>
                                            <div className={`surface-bg-surface bg-gray-100  border-1 border-color-gray-200 p-2 rounded-10 row-between align-items-center w-80 ${theme}`}>
                                                <div className='w-50'>
                                                    <div className='position-relative column-center align-items-center flex-grow-1'>
                                                        <div className='column-center align-items-center'>
                                                            <div className={`content-default-secondary label-small-semibold ${theme}`}>{props.language.invoice_content_text_interruption_duration}</div>
                                                            <div className={`content-default-secondary label-small-regular ${theme}`}>{props.language.invoice_content_text_interruption_duration_unit}</div>
                                                        </div>
                                                        <div className='column-end mt-2'>
                                                            <div className='row-between align-items-stretch'>
                                                                <div className="row-start align-items-end mb-3 me-1">
                                                                    <div className="zindex-1 text-end">
                                                                        {[100, 75, 50, 25, 0].map((number, index) => (
                                                                        <div key={index} className={`content-default-secondary label-XSmall-regular ${index === 0 ? "mt-1" : "mt-3"} ${index === 4 ? "mb-2" : ""} ${theme}`}>
                                                                            {number}
                                                                        </div>
                                                                        ))}
                                                                    </div>
                                                                    <div className={`content-default-secondary label-XSmall-semibold zindex-2 ms-1 ${theme}`}>
                                                                        {Array.from({ length: 12 }).map((_, index) => (
                                                                        <div key={index}>-</div>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                                <div className={`column-end align-items-center mx-2 ${theme}`}>
                                                                    <div className={`content-default-secondary label-XSmall-semibold  ${theme}`}>{c.diug_pixels}</div>
                                                                    <div className={`bg-gray-400 rounded-10 my-1 ${theme}`} style={{ height: `${c.diug_pixels}px`, width: 40 }}></div>
                                                                    <div className={`content-default-primary label-small-medium ${theme}`}>DIUG</div>
                                                                </div>
                                                                <div className={`column-end align-items-center mx-2 ${theme}`}>
                                                                    <div className={`content-default-secondary label-XSmall-semibold ${theme}`}>{c.diu_pixels}</div>
                                                                    <div className={`bg-gray-400 rounded-10 my-1 ${theme}`} style={{ height: `${c.diu_pixels}px`, width: 40 }}></div>
                                                                    <div className={`content-default-primary label-small-medium ${theme}`}>DIU</div>
                                                                </div>
                                                                <div className={`column-end align-items-center mx-2 ${theme}`}>
                                                                    <div className={`content-default-secondary label-XSmall-semibold  ${theme}`}>{c.dium_pixels}</div>
                                                                    <div className={`bg-gray-400 rounded-10 my-1 ${theme}`} style={{ height: `${c.dium_pixels}px`, width: 40 }}></div>
                                                                    <div className={`content-default-primary label-small-medium ${theme}`}>DIUM</div>
                                                                </div>
                                                                <div className={`column-end align-items-center mx-2 ${theme}`}>
                                                                    <div className={`content-default-secondary label-XSmall-semibold  ${theme}`}>{c.thc_pixels}</div>
                                                                    <div className={`bg-gray-400 rounded-10 my-1 ${theme}`} style={{ height: `${c.thc_pixels}px`, width: 40 }}></div>
                                                                    <div className={`content-default-primary label-small-medium ${theme}`}>THC</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='w-50'>
                                                    <div className='position-relative column-center align-items-center flex-grow-1'>
                                                        <div className='column-center align-items-center'>
                                                            <div className={`content-default-secondary label-small-semibold ${theme}`}>{props.language.invoice_content_text_interruption_frequency}</div>
                                                            <div className={`content-default-secondary label-small-regular ${theme}`}>{props.language.invoice_content_text_interruption_frequency_unit}</div>
                                                        </div>
                                                        <div className='column-end mt-2'>
                                                            <div className='row-between align-items-stretch'>
                                                                <div className="row-start align-items-end mb-3 me-1">
                                                                    <div className="zindex-1 text-end">
                                                                        {[100, 75, 50, 25, 0].map((number, index) => (
                                                                        <div key={index} className={`content-default-secondary label-XSmall-regular ${index === 0 ? "mt-1" : "mt-3"} ${index === 4 ? "mb-2" : ""} ${theme}`}>
                                                                            {number}
                                                                        </div>
                                                                        ))}
                                                                    </div>
                                                                    <div className={`content-default-secondary label-XSmall-semibold zindex-2 ms-1 ${theme}`}>
                                                                        {Array.from({ length: 12 }).map((_, index) => (
                                                                        <div key={index}>-</div>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                                <div className={`column-end align-items-center mx-2 ${theme}`}>
                                                                    <div className={`content-default-secondary label-XSmall-semibold ${theme}`}>{c.fiug_pixels}</div>
                                                                    <div className={`bg-gray-400 rounded-10 my-1 ${theme}`} style={{ height: `${c.fiug_pixels}px`, width: 40 }}></div>
                                                                    <div className={`content-default-primary label-small-medium ${theme}`}>FIEG</div>
                                                                </div>
                                                                <div className={`column-end align-items-center mx-2 ${theme}`}>
                                                                    <div className={`content-default-secondary label-XSmall-semibold ${theme}`}>{c.fiu_pixels}</div>
                                                                    <div className={`bg-gray-400 rounded-10 my-1 ${theme}`} style={{ height: `${c.fiu_pixels}px`, width: 40 }}></div>
                                                                    <div className={`content-default-primary label-small-medium ${theme}`}>FIU</div>
                                                                </div>
                                                                <div className={`column-end align-items-center mx-2 ${theme}`}>
                                                                    <div className={`content-default-secondary label-XSmall-semibold ${theme}`}>{c.fium_pixels}</div>
                                                                    <div className={`bg-gray-400 rounded-10 my-1 ${theme}`} style={{ height: `${c.fium_pixels}px`, width: 40 }}></div>
                                                                    <div className={`content-default-primary label-small-medium ${theme}`}>FIUM</div>
                                                                </div>
                                                                <div className={`column-end align-items-center mx-2 ${theme}`}>
                                                                    <div className={`content-default-secondary label-XSmall-semibold ${theme}`}>{c.tvc_pixels}</div>
                                                                    <div className={`bg-gray-400 rounded-10 my-1 ${theme}`} style={{ height: `${c.tvc_pixels}px`, width: 40 }}></div>
                                                                    <div className={`content-default-primary label-small-medium ${theme}`}>TVC</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={`content-default-primary label-small-regular text-center ms-2 w-20 me-2 ${theme}`}>
                                                <div className={`flex-grow-1 border-1 border-color-gray-200 bg-gray-100 rounded-10 py-2 ${theme}`}>
                                                    <div><strong className={`content-color-pink ${theme}`}>GRUPO:</strong> {c.quality_group}</div>
                                                    <div><strong className={`content-color-pink ${theme}`}>DT:</strong> {c.dt}</div>
                                                </div>
                                                <div className={`flex-grow-1 border-1 border-color-gray-200 mt-1 bg-gray-100 rounded-10 py-2 ${theme}`}>
                                                    <div><strong className={`content-color-green ${theme}`}>HC:</strong> {c.hc}</div>
                                                    <div><strong className={`content-color-green ${theme}`}>CF:</strong> {c.cf}</div>
                                                </div>
                                                <div className={`flex-grow-1 border-1 border-color-gray-200 mt-1 bg-gray-100 rounded-10 py-2 ${theme}`}>
                                                    <div><strong className={`content-color-orange ${theme}`}>VC:</strong> {c.vc}</div>
                                                    <div><strong className={`content-color-orange ${theme}`}>MC:</strong> {c.mc}</div>
                                                </div>
                                                <div className={`flex-grow-1 border-1 border-color-gray-200 mt-1 bg-gray-100 rounded-10 py-2 ${theme}`}>
                                                    <div><strong className={`content-color-blue ${theme}`}>CEC:</strong> {c.cec}</div>
                                                    <div><strong className={`content-color-blue ${theme}`}>MF:</strong> {c.mf}</div>
                                                </div>
                                                <div className={`flex-grow-1 border-1 border-color-gray-200 mt-1 bg-gray-100 rounded-10 py-2 ${theme}`}>
                                                    <div><strong className={`content-color-red ${theme}`}>%t:</strong> {c.pdt}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div className="row-between w-100 my-3 content-default-primary">
                                    <div className="ms-4 w-50">
                                        <div className={`p-3 me-3 bg-gray-100 border-1 border-color-gray-200 rounded-10 ${theme}`}>
                                            <div className={`content-default-primary label-large-semibold  ${theme}`}>{props.language.invoice_content_text_pay_methods}</div>
                                            <div className={`content-default-primary label-small-semibold  my-1 ${theme}`}>{props.language.invoice_content_text_app_web_payment}</div>
                                            <div className={`content-default-primary label-small-regular  ${theme}`}>{props.language.invoice_content_text_app_web_payment_description} <strong>ENRG</strong> {props.language.invoice_content_text_web_payment_description} <strong>erco.energy.</strong></div>
                                            <div className="row-center align-items-center mt-2">
                                                <div className="w-100 row-center flex-grow-1">
                                                    <div className={`flex-grow-1 w-50 border-1 border-color-gray-200 rounded-6 align-items-end text-center me-1 p-3 bg-white ${theme}`}>
                                                        <img src={VisaLogo} alt="Logo Empresa" width={45} />
                                                        <img src={MastercardLogo} alt="Logo Empresa" width={45} />
                                                        <img src={AmericanLogo} alt="Logo Empresa" width={45} />
                                                        <div className={`content-default-primary label-small-regular mt-2 ${theme}`}>{props.language.invoice_content_text_debit_card} o crédito</div>
                                                    </div>
                                                    <div className={`flex-grow-1 w-50 border-1 border-color-gray-200 rounded-6 align-items-center text-center p-2 bg-white ${theme}`}>
                                                        <img src={PSELogo} alt="Logo Empresa" width={40} />
                                                        <div className={`content-default-primary label-small-regular mt-1 ${theme}`}>{props.language.invoice_content_text_PSE}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={`content-default-primary label-small-semibold py-2 ${theme}`}>{props.language.invoice_content_text_payment_methods}</div>
                                            <div className={`px-3 py-2 border-1 border-color-gray-200 rounded-6 bg-white ${theme}`}>
                                                <div className="row-start align-items-end">
                                                    <i className={`fa-regular fa-building-columns content-default-primary ${theme}`}></i>
                                                    <div className={`content-default-primary label-medium-semibold ms-2 ${theme}`}>{props.language.invoice_content_text_pay_wire_transfer}</div>
                                                </div>
                                                <div className={`content-default-primary label-small-regular mt-2 ${theme}`}>
                                                {props.language.invoice_content_text_bank_payment_instructions} <strong>24500012793</strong> {props.language.invoice_content_text_bank_account_name} <strong>cartera-clientes@erco.energy</strong> {props.language.invoice_content_text_related}.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="me-4 flex-grow-1 w-50">
                                        <div className={`content-default-primary label-large-semibold ${theme}`}>{props.language.invoice_content_text_legal_info}</div>
                                        <div className={`content-default-secondary label-XSmall-regular mt-2 ${theme}`} style={{ color: '#737373', textAlign: 'justify' }}>
                                            {props.language.invoice_content_first_text_legal_info}
                                            {props.language.invoice_content_second_text_legal_info}
                                            {props.language.invoice_content_third_text_legal_info} <a href="https://www.neu.com.co" style={{ textDecoration: 'none', color: 'black' }}>www.neu.com.co</a>, {props.language.invoice_content_fourth_text_legal_info}
                                            {props.language.invoice_content_fifth_text_legal_info} <a href="https://www.neu.com.co" style={{ textDecoration: 'none', color: 'black' }}>www.neu.com.co</a> {props.language.invoice_content_sixth_text_legal_info}
                                            {props.language.invoice_content_seventh_text_legal_info}
                                            {props.language.invoice_content_eighth_text_legal_info}
                                        </div>
                                    </div>
                                </div>

                                <div className="row-between w-100 content-default-primary">
                                    <div className="ms-4 w-50">
                                        <div className={`p-3 me-3 bg-gray-100 border-1 border-color-gray-200 rounded-10 ${theme}`}>
                                            <div className={`content-default-primary label-large-semibold  ${theme}`}>{props.language.invoice_content_text_verify_invoice_validity}</div>
                                            <div className={`content-default-primary label-small-regular mt-2 ${theme}`}>{props.language.invoice_content_text_verify_invoice_description}</div>
                                            <div className="row-center mt-2 pe-pointer">
                                                <div className="w-100 row-center align-items-center flex-grow-1">
                                                    <div className={`flex-grow-1 bg-white w-50 border-1 border-color-gray-200 rounded-6 flex-column content-default-primary p-3 ${theme}`} style={{ minHeight: '130px' }}>
                                                        <div className="row-start">
                                                            <div className={`content-default-primary label-small-regular ${theme}`}>1.</div>
                                                            <div className={`content-default-primary label-small-regular ms-1 ${theme}`}><strong>Escanea</strong>{props.language.invoice_content_text_scan_qr}.</div>
                                                        </div>
                                                        <div className="row-start">
                                                            <div className={`content-default-primary label-small-regular ${theme}`}>2.</div>
                                                            <div className={`content-default-primary label-small-regular ms-1 ${theme}`}><strong>Verifica</strong>{props.language.invoice_content_text_verify_information}.</div>
                                                        </div>
                                                        <div className="row-start">
                                                            <div className={`content-default-primary label-small-regular ${theme}`}>3.</div>
                                                            <div className={`content-default-primary label-small-regular ms-1 ${theme}`}><strong>Paga tu factura</strong>{props.language.invoice_content_text_pay_invoice}.</div>
                                                        </div>
                                                    </div>
                                                    <div className={`flex-grow-1 bg-white w-50 border-1 border-color-gray-200 rounded-6 p-3 ms-2 row-center align-items-center ${theme}`}>
                                                        {(() => {
                                                            const encryptedId = encryptId(c.bill, secretKey);
                                                            const encryptedLink = `${baseLink}/${encryptedId}`;
                                                            return (
                                                                <QRCode
                                                                    size={256}
                                                                    style={{ height: 155, width: 155 }}
                                                                    value={encryptedLink}
                                                                />
                                                            );
                                                        })()}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="me-4 flex-grow-1 w-50">
                                        <div className={`content-default-primary ${theme}`}>
                                            <div className={`content-default-primary label-large-semibold  ${theme}`}>NEU ENERGY S.A.S E.S.P - 901302727-0</div>
                                            <div className={`content-default-primary label-small-regular mt-1 ${theme}`}>{props.language.invoice_content_text_taxes}</div>
                                            <div className={`content-default-primary label-small-regular mt-1 ${theme}`}><strong>{props.language.invoice_content_text_address}:</strong> Carrera 49 #61SUR - 540</div>
                                            <div className={`content-default-primary label-small-regular mt-1 ${theme}`}><strong>{props.language.invoice_content_text_payment_way}:</strong> {props.language.invoice_content_text_payment_cash}</div>
                                            <div className={`content-default-primary label-small-regular mt-1 ${theme}`}><strong>{props.language.invoice_content_text_payment_method}:</strong> {props.language.invoice_content_text_debit_card}</div>
                                            <div className={`content-default-primary label-small-regular mt-1 ${theme}`}>{props.language.invoice_content_text_ica_tax}</div>
                                            <div className={`content-default-primary label-small-regular mt-1 ${theme}`}><strong>{props.language.invoice_content_text_large_taxpayers}:</strong> {props.language.invoice_content_text_large_taxpayers_resolution}</div>
                                            <div className={`content-default-primary label-small-regular mt-1 ${theme}`}><strong>{props.language.invoice_content_text_income_tax_withholder}:</strong> {props.language.invoice_content_text_income_tax_resolution}</div>
                                            <div className={`content-default-primary label-small-regular mt-1 ${theme}`}><strong>{props.language.invoice_content_text_ica_medellin_withholder}:</strong> {props.language.invoice_content_text_ica_medellin_resolution}</div>
                                        </div>
                                        <div className="row-center align-items-center mt-2">
                                            <div className="column-start align-items-start">
                                                <img src={signature} alt="jeh-signature" style={{ width: '110px', height: '30px' }} />
                                                <div className={`content-default-primary label-small-regula flex-grow-1 ${theme}`}><strong>Juan Esteban Hincapie</strong></div>
                                                <div className={`content-default-primary label-small-regular flex-grow-1 ${theme}`} style={{ color: '#A3A3A3' }}>Representante legal</div>
                                                <div className={`content-color-green label-small-regular flex-grow-1 ${theme}`}><strong>NEU Energy S.A.S E.S.P</strong></div>
                                            </div>
                                            <div className="row-center ms-3">
                                                <img src={VigiladoLogo} alt="Logo Empresa" width={90} />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* CUFE */}
                                <div className="row-center align-items-center mt-3 w-100">
                                    <QRCode
                                        size={256}
                                        style={{ height: 25, width: 25 }}
                                        value={c.qr}
                                    />
                                    <div className={`content-default-primary label-small-regular ms-3 ${theme}`}>CUFE: {c.cufe}</div>
                                </div>
                            </Fragment>))}
                        </div>

                        <div className='w-100 column-end' style={{ bottom: '0', left: '0', right: '0', position: 'relative' }}>
                            <a className="mt-3 row-start aling-items-center rounded-8 my-1 pe-pointer mx-4" href="https://erco.energy/co/servicios/efficiency">
                                <img src="https://dqeqym4vbi7vy.cloudfront.net/images/banner_factura_V2_P3.png" alt="banner-user-data-update" style={{ width: '100%' }} />
                            </a>
                            <div className={`surface-bg-on-surface row-between mt-3 py-3 px-4 ${theme}`}>
                                <div className="text-start" style={{ paddingRight: '0 10px' }}>
                                    <div className={`content-default-primary label-small-regular ${theme}`}>{props.language.invoice_content_text_support}</div>
                                    <div className={`content-default-primary label-small-semibold ${theme}`}><i className={`fa-regular fa-envelope txt-10 txt-black me-1 ${theme}`}></i>soporte@erco.energy</div>
                                </div>
                                <div className="text-start w-30" style={{ padding: '0 10px' }}>
                                    <div className={`content-default-primary label-small-regular ${theme}`}>{props.language.power_factor_label_phase} {props.language.invoices_account_total_selection_separator} {props.language.invoice_content_text_support_emergency}</div>
                                    <div className={`content-default-primary label-small-semibold ${theme}`}><i className={`fa-solid fa-phone txt-10 txt-black me-1 ${theme}`}></i>(+57) 601 6659652</div>
                                </div>
                                <div className="text-start" style={{ paddingLeft: '10px' }}>
                                    <div className={`content-default-primary label-small-regular ${theme}`}>{props.language.invoice_content_text_footer_page}</div>
                                    <div className={`content-default-primary label-small-semibold ${theme}`}>3/3</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> : null
            }
        </Fragment>
    )
};

const mapStateToProps = (state) => ({
    language: state.shared.language,
    invoiceDetails: state.invoices.invoiceDetails,
    invoiceDetailsContent: state.invoices.invoiceDetailsContent,
    invoiceShow: state.invoices.invoiceDetails,
    theme: state.theme.theme,
});

const mapDispatchToProps = dispatch => ({
    loadInvoiceDetailsContent: () => dispatch(invoicesActions.loadInvoiceDetailsContent())
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceDetails);