import React from "react";
import { connect } from "react-redux";

import * as geniusActions from "../../store/actions/Genius";
import * as themeActions from "../../store/actions/Theme";

const ActiveReports = (props) => {
  let activeReports = props.activeReports;
  let expanded = props.expanded;
  let reportDetailed = props.reportDetailed;
  let newReport = props.newReport;
  let language = props.language;
  let showActiveReports = props.showActiveReports;
  let selectedReport = props.selectedReport;
  const onChange = props.onChange;
  
  const setExpanded = props.setShowContentHeader;
  const setNewReport = props.setNewReport;
  const setReportDetailed = props.setReportDetailed;
  const setSelectedTypeOfReport = props.setSelectedTypeOfReport;
  const { theme } = props;

  return (
    <div className="row-start scrollspy gap-2">
      {props.totalReports.length && activeReports.length
        ? activeReports.slice(0, showActiveReports).map((item) =>{ 

          const isSelected = item.id_report === selectedReport?.id_report;

          return(
            <div
              href={`#r${item?.id_report}`}
              key={item.id_report}
              style={{ height: 36 }}
              className={`list-group-item list-group-item-action row-between align-items-center rounded-pill eye-transition px-2 py-1 gap-2 ${isSelected ? 'button-fill-default active': 'surface-bg-on-surface'} ${theme} ${
                newReport ? "pe-default" : "pe-pointer"
              }`}
              onClick={() => {   
                onChange(item)           
              }}
            >
              <i
                className={`fal fa-eye icon-default eye-transition ${isSelected ? 'button-content-inverse' : 'content-default-primary'} ${theme} txt-8 zindex-5`}
                style={{width: 20}}
              ></i>
              <i
                className={`fal fa-eye-low-vision icon-hover eye-transition ${isSelected ? 'button-content-inverse' : 'content-default-primary'} ${theme} txt-8 zindex-5`}
                style={{width: 20}}
              ></i>
              <div
                className={`label-small-regular ${isSelected ? 'button-content-inverse' : 'content-default-primary'} text-nowrap ${theme}`}
              >
                {item.file_name && item.file_name.split(".")[0]}
              </div>   
              <div 
                className="row-end align-items-end ms-3 zindex-5" style={{width: 20}}
                onClick={(e) => {
                  e.stopPropagation();                  
                  if (newReport) {
                    return
                  } else if (!expanded) {
                    setExpanded(true);
                    setReportDetailed(item);                    
                  } else if (
                    expanded &&
                    reportDetailed?.id_report !== item.id_report
                  ) {
                    setReportDetailed(item);                    
                  } else if (
                    expanded &&
                    reportDetailed?.id_report === item.id_report
                  ) {
                    setExpanded(false);
                  }                
                }}
              >           
                <i className={`fal ${expanded && reportDetailed?.id_report === item.id_report ? 'fa-xmark' : isSelected ? 'fa-circle-ellipsis': 'fa-circle-chevron-right'} txt-8 ${isSelected ? 'button-content-inverse' : 'content-default-primary'} ${theme}`}/>      
              </div>        
            </div>
          )})
        : !newReport && (
            <div className="row-between align-items-center bg-gray-450 rounded-pill px-2 py-1">
              <p
                className={`txt-phrase content-default-tertiary txt-6 mx-2 ${theme}`}
              >
                {language.genius_no_reports}
              </p>
            </div>
          )}
      {props.newReport && (
        <div
          className={`row-between align-items-center rounded-pill gap-3 px-2 py-1 content-default-primary surface-bg-on-surface-alpha border-fill-medium ${theme}`}
        >
          <div
            className={`text-nowrap label-small-regular content-default-primary ${theme}`}
          >
            {language.genius_new_report}
          </div>
          <i
            className={`fa-light fa-xmark txt-8 content-default-tertiary pe-pointer zindex-5 ${theme}`}
            onClick={() => {
              setNewReport(false);
              setExpanded(false);
              setSelectedTypeOfReport([]);
            }}
          ></i>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  language: state.shared.language,
  activeReports: state.genius.activeReports,
  expanded: state.genius.expanded,
  totalReports: state.genius.totalReports,
  newReport: state.genius.newReport,
  reportDetailed: ownProps.reportDetailed,
  showActiveReports: ownProps.showActiveReports,
  setReportDetailed: ownProps.setReportDetailed,  
  setSelectedTypeOfReport: ownProps.setSelectedTypeOfReport,
  theme: state.theme.theme,
  onChange: ownProps.onChange,
  selectedReport: ownProps.selectedReport
});

const mapDispatchToProps = (dispatch) => ({
  setActiveReports: (payload) =>
    dispatch(geniusActions.setActiveReports(payload)),
  setNewReport: (payload) => dispatch(geniusActions.setNewReport(payload)),
  setShowContentHeader: (payload) =>
    dispatch(geniusActions.setShowContentHeader(payload)),
  setTheme: (theme) => dispatch(themeActions.setTheme(theme)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ActiveReports);
