import { themes } from '../../utils/enums';

import * as theme from '../creators/Theme';

export const setTheme = (payload) => {
  if (Object.values(themes).includes(payload)){
    localStorage.setItem('theme', payload);
    return {
      type: theme.SET_THEME,
      payload
    }
  }
}
