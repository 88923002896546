import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import * as energyActions from "../../../store/actions/Energy";
import * as sharedActions from "../../../store/actions/Shared";
import * as themeActions from "../../../store/actions/Theme";

import { Popover } from "@idui/react-popover";

import SubMenuGraphs from "./SubMenuGraphs";
import { setDisbaledGraphsValues } from "../../../services/GraphsHandler";
import ButtonCircle from "../../shared/utilities/ButtonCircle";

import { track } from "@amplitude/analytics-browser";

import { Whisper, Tooltip } from "rsuite";

const GraphTypePicker = (props) => {
  let _graphs = props.graphs;
  let graphsRelations = props.graphsRelations;
  let activeGraphs = props.activeGraphs;
  let language = props.language;
  let graphTypePicker = props.graphTypePicker;
  let activeServicesEnergy = props.activeServicesEnergy;
  const { theme } = props;

  const setControlledOnboarding = props.setControlledOnboarding;

  const [search, setSearch] = useState("");
  const [graphGroups, setGraphGroups] = useState(_graphs.graphsGroups);
  const [focus, setFocus] = useState(false);
  const [show, setShow] = useState(false);
  /* To control de submenu from outside, so we can open the submenu from a click on the label and not just in the (fa-circle-chevron-right) icon */
  const [showSubmenuGraphs, setShowSubmenuGraphs] = useState("");
  const [controlRemoteClick, setControlRemoteClick] = useState(false);

  const [disbaledGraphs, setDisbaledGraphs] = useState([]);
  // These are the graphs that are selected but not necessarily activated
  // Graphs are activated when props.setGraphActive()
  const [selectedGraphs, setSelectedGraphs] = useState([]);

  useEffect(() => {
    setDisbaledGraphs(
      setDisbaledGraphsValues(activeGraphs, _graphs.options, graphsRelations),
    );
  }, [_graphs, graphsRelations, activeGraphs, setDisbaledGraphs]);

  useEffect(() => {
    setGraphGroups([
      ..._graphs.graphsGroups.filter((s) =>
        s.translatedGroup?.toLowerCase()?.includes(search.toLowerCase())
      ),
    ]);
  }, [search, _graphs]);

  useEffect(() => {
    setSelectedGraphs([
      ..._graphs.options
        .filter((s) => activeGraphs.includes(s.value))
        .map((v) => v.value),
    ]);
  }, [_graphs, activeGraphs]);

  useEffect(() => {
    if (show && graphTypePicker) {
      setControlledOnboarding({ key: "graph_type_picker", value: true });
    } else {
      setControlledOnboarding({ key: "graph_type_picker", value: false });
    }
  }, [show, setControlledOnboarding, graphTypePicker]);

  useEffect(() => {
    setGraphGroups([
      ...graphGroups.filter(x => ((x?.group !== "POWER_LIMITATION" || (activeServicesEnergy.length === 1 && [5790,1655,4683,5436,4685].includes(activeServicesEnergy[0]?.idService)))
     && (x?.group !== "POWER_FACTOR_PLUS" || (activeServicesEnergy.length === 1 && activeServicesEnergy[0]?.type?.typeService === 2)))),
      {
        translatedGroup: language.energy_graph_type_average_line,
        group: "AVERAGE",
        graphData: [],
      },
    ]);
    // eslint-disable-next-line
  }, [_graphs, language]);


  return (
    <Popover
      lazy
      closeOnRemoteClick={graphTypePicker ? false : !controlRemoteClick}
      fitMaxHeightToBoundsonChangeopen
      fitMaxWidthToBounds
      trigger={"click"}
      placement={"bottom"}
      className={`p-0 surface-bg-surface border-fill-medium rounded-16 ${theme}`}
      arrowColor={"#161617"}
      withArrow={false}
      isOpen={show}
      offset={[0, 0]}
      openingAnimationTranslateDistance={0}
      onChangeOpen={(state) => {
        props.setShowBlackScreen(state);
        setShow(state);
        setFocus(state);
      }}
      animation={{
        animate: { opacity: 1 },
        exit: { opacity: 0, transition: { duration: 0.1 } },
        initial: { opacity: 0 },
      }}
      content={
        <div className="w-100 pt-3 ps-3">
          <div className={`border-fill-medium-bottom pb-3 me-3 sticky-top surface-bg-surface ${theme}`}>
            <div className={`row-start align-items-center border-fill-regular px-3 py-2 rounded-8 ${theme}`}>
              <i className={`fal fa-search txt-10 me-2 content-default-secondary ${theme}`} />
              <input
                type="text"
                className={`label-medium-medium content-default-secondary surface-bg-surface border-0 w-100 ${theme}`}
                placeholder={language.energy_graph_type_picker_search}
                value={search}
                onChange={({ target }) => setSearch(target.value)}
              />
            </div>
          </div>
          <div
            id="graph_type_picker"
            className="column-start graphs-picker-container gap-2 pb-2 overflow-auto pe-1 max-height-300 max-width-300 scroll-without-border"
          >
            <div className={`rounded-8 my-3 p-2 text-wrap label-XSmall-regular content-default-primary ${theme}`}>
              {language.energy_graph_type_disclaimer}
            </div>
            {graphGroups.map((graphGroup, index) => {
              let isSelected;
              let isDisabled;
              let graphSelectedCount = 0;

              for (const _graphData of graphGroup.graphData) {
                if (activeGraphs.includes(_graphData.value)) {
                  isSelected = true;
                  graphSelectedCount++;
                } else if (
                  disbaledGraphs.includes(_graphData.value) &&
                  graphSelectedCount === 0
                ) {
                  isDisabled = true;
                }
              }

              if (graphGroup?.group === "AVERAGE" && props.enableAverageLine) {
                isSelected = true;
              }

              if (
                graphGroup?.group === "PREDICTION_ACTIVE_ENERGY" &&
                (activeGraphs.length > 1 || activeServicesEnergy.length > 1)
              ) {
                isDisabled = true;
              }

              return (
                <div
                  key={index}
                  className={`row-between align-items-center p-2 rounded-8 ${
                    isDisabled ? "" : "pe-pointer"
                  } ${isSelected ? "surface-bg-on-surface" : ""} ${theme}`}
                  onClick={() => {
                    if (graphGroup?.group === "AVERAGE") {
                      props.setEnableAverageLine(!props.enableAverageLine);
                      props.setShowBlackScreen(false);
                      setShow(false);
                      setFocus(false);
                    }
                    if (isDisabled) return;
                    /* Single Graph */
                    if (graphGroup.graphData.length === 1) {
                      if (isSelected) {
                        props.setGraphActive([
                          ...activeGraphs.filter(
                            (g) => g !== graphGroup.graphData[0].value,
                          ),
                        ]);
                      } else {
                        props.setGraphActive([
                          ...activeGraphs,
                          graphGroup.graphData[0].value,
                        ]);
                        track("Graphic variable selected", {
                          Variable_ID: graphGroup.graphData[0].value,
                        });
                      }
                    }
                  }}
                >
                  {isDisabled ? (
                    <Whisper
                      delay={200}
                      placement="bottom"
                      trigger="hover"
                      speaker={
                        <Tooltip>{`${
                          language[
                            `energy_graph_group_${graphGroup.group?.toLowerCase()}`
                          ]
                        } ${language.tooltip_graph_energy_no_match} ${
                          language[
                            `energy_graph_label_${selectedGraphs[0]?.toLowerCase()}`
                          ]
                        } ${
                          language.tooltip_graph_energy_no_match_reason
                        }`}</Tooltip>
                      }
                    >
                      <div
                        className={`label-small-medium content-default-tertiary w-100 ${theme}`}
                      >
                        {
                          language[
                            `energy_graph_group_${graphGroup.group?.toLowerCase()}`
                          ]
                        }{" "}
                        {graphGroup.graphData[0]?.unit !== "" &&
                          ` (${graphGroup.graphData[0].unit})`}
                      </div>
                    </Whisper>
                  ) : (
                    <div
                      className={
                        `label-small-medium pe-pointer w-100 content-default-primary ${theme}`
                      }
                      onClick={() => {
                        /* To open the submenu from here */
                        setControlRemoteClick(true);
                        setShowSubmenuGraphs(graphGroup.group);
                      }}
                    >
                      {
                        language[
                          `energy_graph_group_${graphGroup.group.toLowerCase()}`
                        ]
                      }{" "}
                      {graphGroup.graphData.length === 1 && graphGroup.graphData[0]?.unit !== "" &&
                        `(${graphGroup.graphData[0].unit})`}
                    </div>
                  )}
                  <div className="row-center align-items-center gap-2">
                    {graphSelectedCount !== 0 &&
                      graphGroup.graphData.length > 1 && (
                        <div
                          className={`position-relative row-center align-items-center pe-pointer button-fill-disabled ${theme}`}
                          style={{ borderRadius: "50%", height: 18, width: 18 }}
                        >
                         <p className={`position-absolute label-small-regular content-default-primary text-center ${theme}`}>{graphSelectedCount}</p> 
                        </div>
                      )}
                    {graphGroup.graphData.length > 1 ? (
                      <SubMenuGraphs
                        graphData={graphGroup.graphData}
                        activeGraphs={activeGraphs}
                        setGraphActive={props.setGraphActive}
                        selectedGraphs={selectedGraphs}
                        setControlRemoteClick={setControlRemoteClick}
                        showSubmenuGraphs={showSubmenuGraphs}
                        setShowSubmenuGraphs={setShowSubmenuGraphs}
                        language={language}
                        isDisabled={isDisabled}
                        theme={theme}
                      />
                    ) : (
                      <i
                        className={`fa-check-circle ${
                          isSelected
                            ? "content-color-green fa-solid"
                            : isDisabled
                            ? "content-default-tertiary fal"
                            : "content-default-secondary fal"
                        } txt-10 ${theme}`}
                      ></i>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      }
    >
      <ButtonCircle
        focus={focus}
        icon={focus ? "xmark" : "plus"}
        showPulse={graphTypePicker && props.showPulseAnimation}
      />
    </Popover>
  );
};

const mapStateToProps = (state) => ({
  graphs: state.energy.graphs[0],
  graphsRelations: state.energy.graphsRelations,
  activeGraphs: state.energy.activeGraphs,
  language: state.shared.language,
  controlledTypePicker: state.shared.controlledOnboarding.graph_type_picker,
  graphTypePicker: state.shared.startOnboarding.graph_type_picker,
  enableAverageLine: state.energy.enableAverageLine,
  showPulseAnimation: state.shared.showPulseAnimation,
  activeServicesEnergy: state.services.activeServicesEnergy,
  theme: state.theme.theme,
});

const mapDispatchToProps = (dispatch) => ({
  setGraphActive: (payload) => dispatch(energyActions.setGraphActive(payload)),
  setShowBlackScreen: (payload) =>
    dispatch(sharedActions.setShowBlackScreen(payload)),
  setControlledOnboarding: (payload) =>
    dispatch(sharedActions.setControlledOnboarding(payload)),
  setEnableAverageLine: (payload) =>
    dispatch(energyActions.setEnableAverageLine(payload)),
  setTheme: (theme) => dispatch(themeActions.setTheme(theme)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GraphTypePicker);
