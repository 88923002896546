import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";

import { useLocation, useHistory } from "react-router-dom";

import * as sharedActions from "../../store/actions/Shared";
import * as themeActions from "../../store/actions/Theme";

import { Transition } from "react-transition-group";

import User from "./User";

import DynamicMenu from "./DynamicMenu";
import RoleSelection from "./RoleSelection";

import { themes } from "../../utils/enums";

import LogoLight from "../../imgs/logo-enrg-light.svg";
import LogoDark from "../../imgs/logo-enrg-dark.svg";

const getActivePath = (location) => {
  if (location.pathname === "/home") return "dashboard";
  const parts = location.pathname.split("/");
  return parts[1].toLowerCase();
};

const Menu = (props) => {
  let user = props.user;
  const { theme } = props;
  const { user_modules } = user?.capabilities;
  const location = useLocation();
  const history = useHistory();

  const is_worker = user
    ? user_modules.some((module) => module?.worker === true)
    : false;

  const [active, setActive] = useState(getActivePath(location));

  const [showRoles, setShowRoles] = useState(false);

  const [role, setRole] = useState(
    is_worker ? { type: 1, name: "eWorker" } : { type: 2, name: "Customer" }
  );

  const availableRoles = [
    { type: 1, name: "eWorker" },
    { type: 2, name: "Customer" },
  ];

  useEffect(() => setActive(getActivePath(location)), [location]);

  useEffect(() => {
    props.setMenu(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const worker = user_modules.find(
      (module) => `/${active}` === module.redirect
    )?.worker;

    if (worker && role.type !== 1) {
      setRole(availableRoles[0]);
    } else if (!worker && role.type !== 2) {
      setRole(availableRoles[1]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active]);

  const sortModules = (modules, roleType) => {
    return modules
      .filter((module) => module.redirect && module.worker === (roleType === 1))
      .sort((a, b) => {
        const priority = ["Dashboard", "Analytics"];

        if (priority.includes(a.name) && !priority.includes(b.name)) return -1;
        if (priority.includes(b.name) && !priority.includes(a.name)) return 1;
        return 0;
      });
  };

  const handleRoleSelection = (s_role) => {
    setRole(s_role);

    const modules = sortModules(user_modules, s_role.type).filter(
      (mod) => mod.redirect
    );

    modules &&
      history.push(
        modules[0].redirect === "/dashboard" ? "/home" : modules[0].redirect
      );

    setTimeout(() => {
      setShowRoles(false);
    }, 200);
  };

  return (
    <Fragment>
      <Transition in={props.showBlackScreenIframe} timeout={0}>
        {(state) => (
          <>
            <div
              className={`position-fixed left-0 top-0 zindex-5 trans-fade  surface-bg-blackout trans-fade-${state} ${theme}`}
              style={{
                width: "270px",
                height: "100vh",
                display: props.showBlackScreenIframe ? "block" : "hidden",
              }}
            />
            <div
              className={`position-fixed left-0 top-0 zindex-5 trans-fade  surface-bg-blackout trans-fade-${state} ${theme}`}
              style={{
                width: "100vw",
                height: "72px",
                display: props.showBlackScreenIframe ? "block" : "hidden",
              }}
            />
          </>
        )}
      </Transition>

      <Transition in={props.showMenu} timeout={100}>
        {(state) => (
          <div
            className={`position-fixed left-0 top-0 w-100 vh-100 zindex-4 trans-fade trans-fade-${state}`}
            onClick={() => props.setMenu(false)}
          >
            <div className={`w-100 h-100 surface-bg-blackout ${theme}`}></div>
          </div>
        )}
      </Transition>
      <Transition in={props.showMenu} timeout={150}>
        {(state) => (
          <aside
            id="navigate_modules"
            style={{
              paddingTop: 16,
              paddingBottom: 40,
              zIndex: 1040
            }}
            className={`position-fixed column-between align-items-center left-0 top-0 vh-100 surface-bg-fill px-3 pe-pointer trans-menu trans-menu-${state} ${theme}`}
            onMouseEnter={() => props.setMenu(true)}
            onMouseLeave={() => {
              props.setMenu(false);
              setTimeout(() => {
                setShowRoles(false);
              }, 150);
            }}
          >
            <div className={`d-flex flex-column w-100 h-100 gap-5 ${theme}`}>
              {is_worker ? (
                <div className="d-inline-flex px-2" style={{ height: "52px" }}>
                  <div
                    className={`position-relative d-flex justify-content-center overflow-hidden align-items-center w-100 gap-2 ${
                      state !== "exited"
                        ? "surface-bg-surface"
                        : "surface-bg-fill"
                    } border-fill-medium ${theme}`}
                    style={{
                      height: "52px",
                      borderRadius: "12px",
                    }}
                    onClick={() => {
                      if (is_worker) {
                        setTimeout(() => {
                          setShowRoles(!showRoles);
                        }, 150);
                      }
                    }}
                  >
                    <RoleSelection role={role} theme={theme} state={state} />
                  </div>
                </div>
              ) : (
                <div
                  className={`rounded-3 overflow-hidden d-flex align-items-center justify-content-center gap-2`}
                  style={{
                    minHeight: "52px",
                  }}
                >
                  <img
                    src={theme === themes.DARK ? LogoDark : LogoLight}
                    style={{ width: 76, height: 24 }}
                    alt="logo-neu-energy"
                  />
                </div>
              )}
              <DynamicMenu
                userModules={sortModules(user_modules, role.type)}
                role={role}
                active={active}
                setCurrentModule={props.setCurrentModule}
                language={props.language}
                theme={theme}
                state={state}
              />
            </div>
            <User
              size={50}
              progress={100}
              trackWidth={2}
              indicatorWidth={2}
              indicatorColor={"#9ecc14"}
              setShowAccount={props.setShowAccount}
              state={state}
            />
            <Transition in={showRoles} timeout={150}>
              {(state) => (
                <div
                  className={`${state} flex-column position-absolute surface-bg-surface border-fill-medium rounded-16 p-2 ${theme}`}
                  style={{
                    left: "250px",
                    width: "250px",
                    transition: "all 0.25s ease-in-out",
                    opacity: state === "entered" ? 1 : 0,
                    display: state === "exited" ? "none" : "flex",
                    transform:
                      state === "entered"
                        ? "translateX(0)"
                        : "translateX(-20px)",
                  }}
                >
                  {availableRoles.map((role) => (
                    <div
                      key={role.type}
                      className={`item-menu d-flex align-items-center gap-2 px-3 py-2 rounded-3 ${theme}`}
                      onClick={() => handleRoleSelection(role)}
                      style={{
                        cursor: "pointer",
                        minHeight: "52px",
                        transition: "all 0.25s ease-in-out",
                      }}
                    >
                      <span className="d-flex items-center">
                        <i
                          className={`fa-kit ${
                            role.type === 2
                              ? "fa-energy-symbol"
                              : "fa-generation-symbol"
                          } content-color-green h-100 ${theme}`}
                          style={{
                            marginLeft: "0.10rem",
                            width: "25px",
                            fontSize: "25px",
                          }}
                        />
                      </span>
                      <p
                        className={`label-medium-semibold content-default-primary ps-2 ${theme}`}
                      >
                        {role.name}
                      </p>
                    </div>
                  ))}
                </div>
              )}
            </Transition>
          </aside>
        )}
      </Transition>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  showMenu: state.shared.showMenu,
  language: state.shared.language,
  showBlackScreenIframe: state.shared.showBlackScreenIframe,
  user: state.users.user,
  theme: state.theme.theme,
});

const mapDispatchToProps = (dispatch) => ({
  setMenu: (payload) => dispatch(sharedActions.setMenu(payload)),
  setCurrentModule: (payload) =>
    dispatch(sharedActions.setCurrentModule(payload)),
  setTheme: (theme) => dispatch(themeActions.setTheme(theme)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
