import React from 'react';

const StepProgressBar = ({ status, steps, theme }) => {  
  const step = (status % steps) + 1;  
  const maxSpaceWidth = 10;
  const spaceWidth = Math.min(maxSpaceWidth, (100 / steps) * 0.2);
  const totalSpaceWidth = (steps - 1) * spaceWidth;
  const barWidth = (100 - totalSpaceWidth) / steps;

  const progressBars = Array(steps).fill(null).map((_, index) => {
    const isFilled = index < step;
    const barColor = isFilled ? theme === 'dark' ? 'blue-500' : 'blue-600' : theme === 'dark' ? 'alpha-white-6' : 'alpha-black-6';
    return (
      <div
        key={index}
        className={`rounded-8 bg-${barColor}`}       
        style={{ height: 4, width: `${barWidth}%` }}
      />
    );
  });

  return (
    <div className="row-between align-items-center w-100 mt-2">
      {progressBars}
    </div>
  );
};

export default StepProgressBar;
