import React from "react"

// import ThemedImage from "../utils/ThemedImage"

export const stepsUser = (language) => {
  return ([
    {
      content: (
        <div className="row-center align-items-center w-100  rounded-8 mt-3" style={{ height: 142, width: 300 }}>
          {/* <ThemedImage imageName={'step1-new-user.png'}/> */}
        </div>
      ),
      title: language.onboarding_user_title_step1,
      description: language.onboarding_user_description_step1,
      placement: "right",
      width: 340,
      height: 350,
      target: "#user_modal",
      disableBeacon: true,
      steps: 3
    },
    {
      content: (
        <div className="row-center align-items-center w-100  rounded-8 mt-3" style={{ height: 142, width: 300 }}>
          {/* <img src={language.language_locale === 'es'? step2 : step2En} className="w-100 h-100" alt="step 2 new user" /> */}
        </div>
      ),
      title: language.onboarding_user_title_step2,
      description: language.onboarding_user_description_step2,
      placement: "right",
      width: 340,
      height: 350,
      target: "#user_form",
      disableBeacon: true,
      steps: 3,
    },
    {
      content: (
        <div className="row-center align-items-center w-100  rounded-8 mt-3" style={{ height: 142, width: 300 }}>
          {/* <img src={language.language_locale === 'es'? step3 : step3En} className="w-100 h-100" alt="step 3 new user" /> */}
        </div>
      ),
      title: language.onboarding_user_title_step3,
      description: language.onboarding_user_description_step3,
      placement: "right-end",
      width: 340,
      target: "#user_edit",
      disableBeacon: true,
      steps: 3,
    },
  ])
}
