import React, { useEffect, useRef, useState, Fragment } from "react";
import { connect } from 'react-redux';

import * as servicesActions from '../../store/actions/Services';
import * as plusActions from "../../store/actions/Plus";

import Graph from "./dashboard/graphs/Graph";
import Cards from "./dashboard/cards/Cards";
import Radar from "./dashboard/modules/Radar";
import AlwaysOn from "./dashboard/modules/AlwaysOn";
import Rank from "./dashboard/modules/Rank";
import ServicesPlus from "./dashboard/shared/ServicesContainer";

import { useWindowSize } from '../shared/utilities/useWindowSize';

import ContentLoader from "react-content-loader";
import Header from "../shared/Header";

const IndicatorsLoading = props => {
    const {theme} = props;
    return (
    <div className="position-absolute w-100 h-100 left-0 top-0 zindex-1">
        <ContentLoader
            seed={2}
            backgroundColor= {theme === 'dark' ? "var(--gray-950)" : "var(--white)"}
            foregroundColor={theme === 'dark' ? "#323234" : "var(--gray-200)" }
            style={{ width: "100%", height: "100%" }}>
            <rect x="0" y="0" width="calc(100%)" height="calc(100%)" rx="16" ry="16"></rect>
        </ContentLoader>
    </div>)
};


const Plus = props => {

    const {theme} = props;

    const size = useWindowSize();

    const graphContiner = useRef(null);

    const [graphContinerWidth, setGraphContinerWidth] = useState(0);

    const empty = props.activeServicesPlus.length === 0 || typeof (props.activeServicesPlus[0]) == 'undefined';

    let account = props.account;

    const setIndicators = props.setIndicators;
    const setEnergyQuality = props.setEnergyQuality;
    const setAlwaysOn = props.setAlwaysOn;
    const setComparatives = props.setComparatives;
    const setOpenQualityEnergy = props.setOpenQualityEnergy;

    useEffect(() => {
        if (!props.activeServicesPlus?.length) {
            props.loadServicesAsync();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.account]);

    useEffect(() => {
        if (!empty) setGraphContinerWidth(graphContiner.current.getBoundingClientRect().width)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [size, empty]);

    useEffect(() => {
        if (props.dataGraph.length) {
            props.resetData();
        }
        if (props.activeServicesPlus.length) {
            props.loadIndicators();
        } else {
            setIndicators([]);
            setEnergyQuality(null);
            setAlwaysOn([]);
            setComparatives([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.activeServicesPlus, account]);

    useEffect(() => {
        setOpenQualityEnergy(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const RenderEmptyService = () => {
        return (
            <div className="modal-plus p-4 rounded-16 border-2 border-color-purple-500">
                <p className="txt-title txt-bold txt-purple-pink-gradient txt-14 text-center text-wrap">{props.language.plus_modal_title_empty_state}</p>
                <p className="txt-phrase txt-regular txt-white txt-10 text-center text-wrap mt-3">{props.language.plus_modal_description_empty_state}</p>
                <p className="txt-phrase txt-regular txt-white txt-10 text-center text-wrap mt-3">{props.language.plus_modal_phrase_empty_state}</p>
                <div className="row-center w-100 mt-3">
                    <button className="row-center align-items-center py-2 px-4 bg-purple-pink-gradient rounded-8" onClick={() => {
                        window.open('https://api.whatsapp.com/send?phone=573168778181&text=Estamos%20felices%20de%20poder%20ayudarte!');
                    }}>
                        <i className="fa-regular fa-headset txt-8 me-3 txt-white pe-pointer"></i>
                        <p className="txt-phrase txt-bold txt-white txt-8 pe-pointer">{props.language.plus_modal_button_empty_state}</p>
                    </button>
                </div>
            </div>
        )
    };

    return (
        <Fragment>
            {props.activeServicesPlus.length ?
                <section className="column-start align-items-center vh-100 w-100">
                    <Header moduleName={props.language.plus_title_main_dashboard} backToButton={true} />
                    <div className="column-center align-items-center flex-grow-1 pt-3 w-100">
                        <ServicesPlus />
                        <div className="row-between align-items-start w-100 p-3">
                            <div className="d-flex flex-column justify-content-between h-100 gap-3" style={{ width: 300, minWidth: 300 }}>
                                <Radar indexVariable={2}/>
                                <Radar indexVariable={1}/>
                                <Radar indexVariable={0}/>
                            </div>
                            <div ref={graphContiner} className="column-start align-items-start align-self-stretch" style={{ width: "calc(100% - 300px)" }}>
                                <div className="row-between align-items-center w-100 ps-3 mb-3">
                                    {props.loadingIndicators ?
                                        <div className="position-relative w-100" style={{ height: 120 }}><IndicatorsLoading theme={theme}/></div> :
                                        <Cards />}
                                </div>
                                <Graph graphContinerWidth={graphContinerWidth} />
                            </div>
                        </div>
                    </div>
                </section> :
                <section className="column-start align-items-center vh-100 w-100">
                    <Header moduleName={props.language.plus_title_main_dashboard} backToButton={false} />
                    <div className="column-center align-items-center flex-grow-1 pt-3 w-100 h-100 position-relative">
                        <div className="position-absolute left-0 top-0 w-100 vh-100 zindex-3">
                            <div className="bg-black opacity-80 w-100 h-100"></div>
                        </div>
                        <div className="row-between align-items-center w-100 p-3">
                            <div className="column-start align-items-start" style={{ width: 300, minWidth: 300 }}>
                                <Radar />
                                <AlwaysOn />
                                <Rank />
                            </div>
                            <div ref={graphContiner} className="column-start align-items-start" style={{ width: "calc(100% - 300px)" }}>
                                <div className="row-between align-items-center w-100 ps-3 mb-4">
                                    {props.loadingIndicators ?
                                        <div className="position-relative w-100" style={{ height: 120 }}><IndicatorsLoading theme={theme}/></div> :
                                        <Cards />}
                                </div>
                                <Graph graphContinerWidth={graphContinerWidth} />
                            </div>
                        </div>
                        <RenderEmptyService />
                    </div>
                </section>
            }
        </Fragment>);
}

const mapStateToProps = (state) => ({
    activeServicesPlus: state.services.activeServicesPlus,
    dataGraph: state.plus.dataGraph,
    loadingIndicators: state.plus.loadingIndicators,
    indicators: state.plus.indicators,
    account: state.accounts.account,
    comparatives: state.plus.comparatives,
    loadingComparatives: state.plus.loadingComparatives,
    energyQuality: state.plus.energyQuality,
    loadingEnergyQuality: state.plus.loadingEnergyQuality,
    alwaysOn: state.plus.alwaysOn,
    loadingAlwaysOn: state.plus.loadingAlwaysOn,
    language: state.shared.language,
    screenPaddingBottom: state.shared.screenPaddingBottom,
    theme: state.theme.theme,
});

const mapDispatchToProps = dispatch => ({
    setActiveServicesPlus: (payload) => dispatch(servicesActions.setActiveServicesPlus(payload)),
    setActiveServicesEnergy: (payload) => dispatch(servicesActions.setActiveServicesEnergy(payload)),
    resetData: () => dispatch(plusActions.resetData()),
    loadServicesAsync: () => dispatch(servicesActions.loadServicesAsync()),
    loadIndicators: () => dispatch(plusActions.loadIndicators()),
    setIndicators: (payload) => dispatch(plusActions.setIndicators(payload)),
    setEnergyQuality: (payload) => dispatch(plusActions.setEnergyQuality(payload)),
    setAlwaysOn: (payload) => dispatch(plusActions.setAlwaysOn(payload)),
    setComparatives: (payload) => dispatch(plusActions.setComparatives(payload)),
    setOpenQualityEnergy: (payload) => dispatch(plusActions.setOpenQualityEnergy(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Plus);


