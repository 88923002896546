import React, { useRef, useEffect } from "react";
import { connect } from "react-redux";

import Header from "../shared/Header";
import { track } from "@amplitude/analytics-browser";

const Volt = (props) => {
  let user = props.user;
  let account = props.account;
  let screenPaddingBottom = props.screenPaddingBottom;
  let theme = props.theme;

  const urlFrame = process.env.REACT_APP_VOLT_HOST;

  const frameVolt = useRef(null);

  useEffect(() => {
    if (account !== null) {
      frameVolt.current.addEventListener("load", () => {
        let window = frameVolt.current.contentWindow;

        let token = localStorage.getItem("token");

        setTimeout(() => {
          window.postMessage(
            JSON.stringify({
              user: user.idUser,
              email: user.email,
              account: account.idAccount,
              token_key: "token",
              token_value: token,
              screenPaddingBottom,
              theme,
            }),
            urlFrame
          );
        }, [100]);
      });
      track("Volt viewed");
    }
    // eslint-disable-next-line
  }, [user, account, urlFrame]);

  useEffect(() => {
    let window = frameVolt.current.contentWindow;

    window.postMessage(
      JSON.stringify({
        screenPaddingBottom,
      }),
      urlFrame
    );
  }, [screenPaddingBottom, urlFrame]);

  useEffect(() => {
    let window = frameVolt.current.contentWindow;

    window.postMessage(
      JSON.stringify({
        theme,
      }),
      urlFrame
    );
    // eslint-disable-next-line
  }, [theme]);

  return (
    <div className="position-relative w-100 vh-100">
      <div
        className={`position-absolute left-0 top-0 zindex-2 ${theme}`}
        style={{ height: 74, width: "calc(100% - 18px)" }}
      >
        <Header
          moduleName={props.language.menu_volt_service}
          backToButton={false}
        />
      </div>
      <iframe
        key={account?.idAccount}
        ref={frameVolt}
        title="volt-section"
        className="position-absolute w-100 h-100 left-0 top-0"        
        src={`${urlFrame}`}
      ></iframe>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.users.user,
  account: state.accounts.account,
  language: state.shared.language,
  screenPaddingBottom: state.shared.screenPaddingBottom,
  theme: state.theme.theme,
});

export default connect(mapStateToProps)(Volt);
