import React, { useState } from "react";
import { connect } from "react-redux";

import { Popover } from "@idui/react-popover";

import { track } from "@amplitude/analytics-browser";

const Tooltip = ({
  text,
  changeFocus = () => {},
  fontIcon = 12,
  fontSize = 8,
  typeIcon = "solid",
  colorIcon = "white",
  bgColor = "gray-800",
  amplitudeEvent = false,
  theme,
}) => {
  const [focus, setFocus] = useState(false);

  return (
    <Popover
      lazy
      closeOnEnter
      fitMaxHeightToBounds
      fitMaxWidthToBounds
      trigger={"hover"}
      placement={"bottom"}
      arrowColor={"#323234"}
      className={`p-0 surface-bg-on-surface ${theme}`}
      arrowSize={10}
      offset={[0, 0]}
      openingAnimationTranslateDistance={0}
      onChangeOpen={(state) => {
        setFocus(state);
        changeFocus(state);
        focus && amplitudeEvent && track("Standby information hover");
      }}
      animation={{
        animate: { opacity: 1 },
        exit: { opacity: 0, transition: { duration: 0.1 } },
        initial: { opacity: 0 },
      }}
      content={
        <div className="px-3 py-2" style={{ width: 250 }}>
          <p
            className={`txt-${fontSize} text-wrap text-center content-default-primary ${theme}`}
          >
            {text}
          </p>
        </div>
      }
    >
      <div className="row-center align-items-center pe-pointer">
        <i
          className={`fa-${typeIcon} fa-circle-info txt-${fontIcon} ${colorIcon} ${
            focus && "zindex-5"
          }`}
        ></i>
      </div>
    </Popover>
  );
};

const mapStateToProps = (state, ownProps) => ({
  text: ownProps.text,
});

export default connect(mapStateToProps)(Tooltip);
