import "bootstrap/dist/css/bootstrap.css";
import React from "react";
import { Provider } from "react-redux";
import Store from "./store/Store";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <Provider store={Store}>
    <BrowserRouter basename={baseUrl}>
      <App tab="home" />
    </BrowserRouter>
  </Provider>,
);
