import { themes } from "../../utils/enums";

import * as theme from '../creators/Theme';

const savedTheme = localStorage.getItem('theme');

const initialState = {
  theme: Object.values(themes).includes(savedTheme) ? savedTheme : themes.DARK
};

export const themeReducer = (state = initialState, action) => {
  switch (action.type) {
    case theme.SET_THEME:
      return {
        ...state,
        theme: action.payload,
      };
    default:
      return state;
  }
};
