import React from "react";

import { Popover } from "@idui/react-popover";

const TextTooltip = ({ text, content, bgColor = "gray-800" }) => {
  return (
    <Popover
      lazy
      closeOnEnter
      fitMaxHeightToBounds
      fitMaxWidthToBounds
      trigger={"hover"}
      placement={"bottom"}
      className={`p-0 ${bgColor}`}
      withArrow={false}
      offset={[0, 0]}
      openingAnimationTranslateDistance={0}
      animation={{
        animate: { opacity: 1 },
        exit: { opacity: 0, transition: { duration: 0.1 } },
        initial: { opacity: 0 },
      }}
      content={content}
    >
      {text}
    </Popover>
  );
};

export default TextTooltip;
