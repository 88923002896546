import React, { useState } from "react";
import { Popover } from "@idui/react-popover";
import { connect } from "react-redux";
import * as themeActions from "../../../store/actions/Theme";

const Select = ({
  label,
  options,
  value,
  onChange,
  keyName = "value",
  labelName = "label",
  width = 280,
  height = 120,
  theme,
  language,
  error,
  background = "surface-bg-surface",
  type_label = "label-small-medium",
  required,
  defaultMessage = 'Seleccionar',
  completeHeight = false,
  renderOptionContainer = null,
  renderCustomOption = null,
  renderCustomValue = null
}) => {
  const [active, setActive] = useState(false);

  const renderOption = () => {
    return options.map((item) => {
      const isSelected = value === item[keyName];

      const handleClick = () => {
        onChange(item[keyName]);
        setActive(false);
      }

      if(renderCustomOption){
        return renderCustomOption({ 
          value: item[keyName], 
          item: item,
          onClick: handleClick, 
          isSelected
        })
      }

      return (
        <div
          key={item[keyName]}
          className={`row-between w-100 align-items-center pe-pointer py-2 px-2 my-1 rounded-8 ${
            isSelected
              ? "surface-bg-on-surface"
              : "surface-bg-surface"
          } ${theme}`}
          onClick={handleClick}
        >
          <div className="row-between align-items-center w-100">
            <div
              className={`label-small-medium content-default-primary ${theme}`}
            >
              {language ? language[item[labelName]] : item[labelName]}
            </div>
            {isSelected && (
              <i
                className={`fa-solid fa-check-circle content-color-green txt-10 ${theme}`}
              />
            )}
          </div>
        </div>
      );
    })
  }

  return (
    <Popover
      lazy
      closeOnEnter
      fitMaxHeightToBounds
      fitMaxWidthToBounds
      trigger={"click"}
      placement={"bottom"}
      arrowColor={"#161617"}
      className={`p-0 bg-popover-none rounded-16`}
      zIndex={10000}
      arrowSize={20}
      offset={[0, 0]}
      openingAnimationTranslateDistance={0}
      animation={{
        animate: { opacity: 1 },
        exit: { opacity: 0, transition: { duration: 0.1 } },
        initial: { opacity: 0 },
      }}
      withArrow={false}
      content={
        <div className="w-100" style={{backgroundColor:'transparent'}}>
          {
            renderOptionContainer ? 
              renderOptionContainer({ children: renderOption(), width, height}) 
            : (
              <div
                className={`column-start align-items-center border-fill-regular overflow-auto px-2 py-1 rounded-16 surface-bg-surface scroll-without-border ${theme}`}
                style={{ width: width, maxHeight: height }}
              >
                {renderOption()}
              </div>
            )
          }
        </div>
      }
    >
      <div className={`column-start align-items-start w-100 ${completeHeight ? 'h-100' : ''}`}>
        <div className="column-start w-100 gap-2">
          {label && (
            <p className={`row-start label-small-medium content-default-primary ${theme}`}>
              {label}
              {required ? <span className={`ms-2 content-color-green ${theme}`}>*</span> : <></>}
            </p>
          )}
          <button
            className={`row-between align-items-center rounded-30 border-fill-regular ${background} py-2 px-3 w-100 ${theme}`}
            onClick={() => setActive(!active)}
          >
            <p
              className={`${type_label} content-default-secondary ${theme}`}
            >
              {
                options.find((item) => item[keyName] === value)?.[labelName] 
                ? (renderCustomValue ? renderCustomValue(options.find((item) => item[keyName] === value)?.[labelName]) : options.find((item) => item[keyName] === value)?.[labelName])
                : defaultMessage
              }
            </p>
            <i
              className={`fa-regular ${
                active ? "fa-circle-chevron-right" : "fa-circle-chevron-down"
              } txt-10 content-default-secondary ${theme}`}
            ></i>
          </button>
        </div>
        {error && (
          <p className="txt-phrase txt-regular txt-6 txt-red-500 mt-1 w-100 text-wrap">
            {error}
          </p>
        )}
      </div>
    </Popover>
  );
};

const mapStateToProps = (state) => ({
  theme: state.theme.theme,
});

const mapDispatchToProps = (dispatch) => ({
  setTheme: (theme) => dispatch(themeActions.setTheme(theme)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Select);