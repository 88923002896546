import React from "react";

const RoleSelection = ({ role, theme, state }) => {
  return (
    <div className="d-flex flex-column justify-content-start w-100 h-100">
      <div
        className={`item-menu rounded-top-3 d-flex align-items-center justify-content-start px-3 py-2 gap-2`}
        style={{
          cursor: "pointer",
          minHeight: "52px",
        }}
      >
        <span className="d-flex align-items-center">
          <i
            className={`fa-kit ${
              role.type === 2 ? "fa-energy-symbol" : "fa-generation-symbol"
            } content-color-green h-100 ${theme}`}
            style={{ marginLeft: "0.10rem", fontSize: role.type === 2 ? '24px' : '26px' }}
          />
        </span>

        <div className="w-100 h-100 ps-2 d-flex justify-content-between align-items-center">
          <span
            className={`d-flex h-100 align-items-center text-expand ${
              state !== "exited" ? "text-expand-active" : ""
            }`}
          >
            <p
              className={`label-medium-semibold content-default-primary ${theme}`}
            >
              {role.name}
            </p>
          </span>

          <span
            className={`text-expand ${
              state !== "exited" ? "text-expand-active" : ""
            }`}
          >
            <i
              className={`fa-light fa-angles-up-down heading-h6-regular content-default-secondary ${theme}`}
            />
          </span>
        </div>
      </div>
    </div>
  );
};

export default RoleSelection;
