import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import * as usersActions from '../../../store/actions/Users';

import { track } from "@amplitude/analytics-browser";

import { Modal, Loader } from 'rsuite';

import Input from '../utilities/Input';
import MultipleSelect from '../utilities/MultipleSelect';

import { Transition } from "react-transition-group";

const initUserForm = {
    name: null,
    lastname: null,
    email: null,
    phone: null,
    idDocument: null,
    roleIds: [],
    servicesIds: []
}

const isValidateEmail = email => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}

const isValidateString = val => {
    return /^[ñíóáéú a-zA-Z ]+$/.test(val);
}

const isValidUser = user => {

    if (user.name === null || user.name === "" || !isValidateString(user.name)) {
        return false;
    }

    if (user.lastname === null || user.lastname === "" || !isValidateString(user.lastname)) {
        return false;
    }

    if (!isValidateEmail(user.email)) {
        return false;
    }

    if (user.phone === null || user.phone === "") {
        return false;
    }

    if (user.roleIds === null || !user.roleIds.length) {
        return false;
    }

    if (user.servicesIds === null || !user.servicesIds.length) {
        return false;
    }

    return true;

}

const showError = (user, setFocus, setError, language) => {

    if (user.name === null || user.name === "" || !isValidateString(user.name)) {
        setFocus(1);
        setError(language.alert_user_popup_name);
        return;
    }

    if (user.lastname === null || user.lastname === "" || !isValidateString(user.lastname)) {
        setFocus(2);
        setError(language.alert_user_popup_lastname);
        return;
    }

    if (user.idDocument === null || user.idDocument === "") {
        setFocus(3);
        setError(language.alert_user_popup_id_document);
        return;
    }

    if (user.phone === null || user.phone === "") {
        setFocus(4);
        setError(language.alert_create_user_popup_phone);
        return;
    }

    if (!isValidateEmail(user.email)) {
        setFocus(5);
        setError(language.alert_create_user_popup_email);
        return;
    }

    if (user.roleIds === null || !user.roleIds.length) {
        setFocus(6);
        setError(language.alert_create_user_popup_job_area);
        return;
    }

    if (user.servicesIds === null || !user.servicesIds.length) {
        setFocus(7);
        setError(language.alert_create_user_popup_selected_services);
        return;
    }

    return setFocus(0);
}

const userLoadingContainer = (loading, language) => (
    <div className="position-absolute h-100 w-100 left-0 top-0 zindex-1 p-4">
        <Transition in={loading} timeout={100}>
            {(state) => (
                <div className={`trans-fade trans-fade-${state} position-absolute w-100 h-100 top-0 left-0 zindex-1`}>
                    <div className="column-center align-items-center w-100 h-100">
                        <Loader inverse center size="md" content={language.loader_text_content} />
                    </div>
                </div>
            )}
        </Transition>
    </div>
)

const UserCreate = (props) => {

    let userJobs = props.userJobs;
    let userAccessEdit = props.userAccessEdit;
    let language = props.language;

    const { theme } = props;

    const [userForm, setUserForm] = useState(initUserForm);
    const [isEnable, setEnable] = useState(false);
    const [focus, setFocus] = useState(0);
    const [error, setError] = useState("");

    const loadUserJobsAsync = props.loadUserJobsAsync;
    const createUserAsync = props.createUserAsync;
    const updateUserAsync = props.updateUserAsync;

    useEffect(() => {
        if (!userJobs.length)
            loadUserJobsAsync();
    }, [userJobs, loadUserJobsAsync]);

    useEffect(() => {
        userAccessEdit !== null ? setUserForm(userAccessEdit) : setUserForm(initUserForm)
    }, [userAccessEdit]);

    useEffect(() => {
        setEnable(isValidUser(userForm));
    }, [userForm]);

    useEffect(() => {
        if (focus !== 0) { showError(userForm, setFocus, setError, language) }
    }, [userForm, language, focus]);

    const handleClose = () => {
        props.setShowCreateUserModal(false);
        props.setUserAccessEdit(null);
    }

    const formatLabel = (array1, array2, keyName, labelName) => {

        let array3 = array1.map(x => array2.filter(y => y[keyName] === x))
            .flat()

        return array3.map((s, index) => {
            if (index === array3.length - 1) {
                return `${s[labelName]}. `;
            } else {
                return `${s[labelName]}, `;
            }

        }).join("");
    }

    return (
        <Modal
            style={{
                width: 500,
                marginLeft: 'auto',
                marginRight: 'auto',
                display: 'block'
            }}
            className={`rounded-24 surface-bg-surface border-fill-medium ${theme}`}
            backdropClassName={`${theme}`}
            id={"user_modal"}
            show={false}
            onHide={() => handleClose()}>
            <Modal.Header closeButton={false}>
                <div className="row-between align-items-center h-100 w-100 ps-3">
                    <h2 className={`heading-h6-bold content-default-primary ${theme}`}>{props.showCreateUserModal === 2 ? props.language.user_title_edit_user : props.language.user_title_create_user}</h2>
                    <button  onClick={() => handleClose()} className={`column-center align-items-center rounded-30 border-fill-medium surface-bg-on-surface-alpha ${theme}`} style={{width:'34px', height: '34px'}}>
                     <i className={`fa-light fa-xmark txt-10 content-default-primary ${theme}`}></i>
                    </button>
                </div>
            </Modal.Header>
            <Modal.Body>
                {props.usersLoading ? <div style={{ height: 400 }}>{userLoadingContainer(props.usersLoading, props.language)}</div> :
                    <div id='user_form' className="column-start px-3 pb-3 gap-2">
                        <div className="row-center align-items-start flex-wrap my-2">
                            <div className="col-md-6 col-12 pe-1">
                                <Input
                                    label={props.language.user_input_label_names}
                                    placeholder={props.language.user_input_placeholder_name}
                                    type={'text'}
                                    value={userForm ? userForm.name : ''}
                                    onChange={({ target }) => {
                                        setUserForm({ ...userForm, name: target.value })
                                    }}
                                    error={focus === 1 ? error : ""}
                                />
                            </div>
                            <div className="col-md-6 col-12 ps-1">
                                <Input
                                    label={props.language.user_input_label_lastname}
                                    placeholder={props.language.user_input_placeholder_lastname}
                                    type={'text'}
                                    value={userForm ? userForm.lastname : ''}
                                    onChange={({ target }) => {
                                        setUserForm({ ...userForm, lastname: target.value })
                                    }}
                                    error={focus === 2 ? error : ""}
                                />
                            </div>
                        </div>
                        <div className="row-center align-items-start flex-wrap my-2">
                            <div className="col-md-6 col-12 pe-1">
                                <Input
                                    label={props.language.user_input_label_id_document}
                                    placeholder={props.language.user_input_placeholder_id}
                                    type={'number'}
                                    value={userForm ? userForm.idDocument : ''}
                                    onChange={({ target }) => {
                                        setUserForm({ ...userForm, idDocument: target.value })
                                    }}
                                    error={focus === 3 ? error : ""}
                                />
                            </div>
                            <div className="col-md-6 col-12 ps-1">
                                <Input
                                    label={props.language.user_input_label_phone}
                                    placeholder={props.language.user_input_placeholder_phone}
                                    type={'number'}
                                    value={userForm ? userForm.phone : ''}
                                    onChange={({ target }) => {
                                        setUserForm({ ...userForm, phone: target.value })
                                    }}
                                    error={focus === 4 ? error : ""}
                                />
                            </div>
                        </div>
                        <div className="row-center align-items-center flex-wrap my-2">
                            <div className="col-12">
                                <Input
                                    label={props.language.user_input_label_email}
                                    placeholder={props.language.user_input_placeholder_email}
                                    type={'email'}
                                    value={userForm ? userForm.email : ''}
                                    onChange={({ target }) => {
                                        setUserForm({ ...userForm, email: target.value })
                                    }}
                                    error={focus === 5 ? error : ""}
                                />
                            </div>
                        </div>
                        <div className="row-center align-items-start flex-wrap my-2">
                            <div className="col-md-6 col-12 pe-1">
                                <MultipleSelect
                                    keyName='idJob'
                                    labelName='valueText'
                                    language={props.language}
                                    label={props.language.user_select_label_job_area}
                                    width={230}
                                    options={userJobs}
                                    selectedData={userForm.roleIds}
                                    onChange={(value) => setUserForm({ ...userForm, roleIds: value })}
                                    error={focus === 6 ? error : ""}
                                    value={userForm?.roleIds.length && `${props.language.user_selected_label_job_area}: ${userForm?.roleIds.length}`}
                                />
                            </div>
                            <div className="col-md-6 col-12 ps-1">
                                <MultipleSelect
                                    keyName='idService'
                                    labelName='name'
                                    label={props.language.user_select_label_services}
                                    width={230}
                                    options={props.totalServices}
                                    selectedData={userForm.servicesIds}
                                    onChange={(value) => setUserForm({ ...userForm, servicesIds: value })}
                                    error={focus === 7 ? error : ""}
                                    value={userForm?.servicesIds.length && `${props.language.user_select_label_services_selected}: ${userForm?.servicesIds.length}`}
                                />
                            </div>
                        </div>
                        {userForm ?
                            <div id='user_edit' className="row-center align-items-center w-100 my-3">
                                <button className={`row-center align-items-center py-2 rounded-30 px-3 label-small-regular button-fill-brand button-content-equal ${theme}`}
                                    onClick={async () => {                                                                          
                                        if (isEnable) {
                                            if (props.showCreateUserModal === 2) {
                                                await updateUserAsync(userForm);
                                                track('User edited', { User_Profile: formatLabel(userForm.roleIds, userJobs, 'idJob', 'name'), Assigned_Project_ID: formatLabel(userForm.servicesIds, props.totalServices, 'idService', 'name'), Project_Type:props.activeServicesEnergy.map(function (service) { return service?.type?.name }) })
                                            } else {
                                                createUserAsync(userForm);
                                                track('User created', { User_Profile: formatLabel(userForm.roleIds, userJobs, 'idJob', 'name'), Assigned_Project_ID: formatLabel(userForm.servicesIds, props.totalServices, 'idService', 'name'), Project_Type:props.activeServicesEnergy.map(function (service) { return service?.type?.name }) })
                                            }
                                        }
                                        else {
                                            showError(userForm, setFocus, setError, language);
                                        }
                                    }}>
                                    {props.language.user_save_button}
                                </button>
                                <button className={`row-center align-items-center label-small-regular py-2 rounded-30 px-3 ms-3 button-fill-error txt-white ${theme}`} onClick={() => { handleClose() }}>
                                    {props.language.user_cancel_button}
                                </button>
                            </div> :
                            <div className="row-center align-items-center w-100 my-3">
                                <button className="row-center align-items-center txt-phrase txt-bold txt-8 bg-green-500 txt-neutral-800 py-2 rounded-8 px-4">
                                    <i className="fa-regular fa-user-plus txt-10 me-3" />{props.language.user_button_add_user}
                                </button>
                                <button className="row-center align-items-center txt-phrase txt-bold txt-8 bg-gray-500 border-2 border-color-green-500 txt-green-500 py-2 rounded-8 px-4 ms-3">
                                    <i className="fa-regular fa-gear txt-10 me-3" />{props.language.user_button_edit_user}
                                </button>
                            </div>
                        }
                    </div>}
            </Modal.Body>
        </Modal>
    )
}

const mapStateToProps = (state) => ({
    userAccessEdit: state.users.userAccessEdit,
    userJobs: state.users.userJobs,
    totalServices: state.services.totalServices,
    showCreateUserModal: state.users.showCreateUserModal,
    language: state.shared.language,
    usersLoading: state.users.usersLoading,
    activeServicesEnergy: state.services.activeServicesEnergy,
    theme: state.theme.theme,
})

const mapDispatchToProps = dispatch => ({
    setShowCreateUserModal: (payload) => dispatch(usersActions.setShowCreateUserModal(payload)),
    loadUserJobsAsync: () => dispatch(usersActions.loadUserJobsAsync()),
    setUserAccessEdit: (payload) => dispatch(usersActions.setUserAccessEdit(payload)),
    createUserAsync: (payload) => dispatch(usersActions.createUserAsync(payload)),
    updateUserAsync: (payload) => dispatch(usersActions.updateUserAsync(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(UserCreate)