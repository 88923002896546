import React from "react";
import { connect } from "react-redux";

import { CircularProgressbarWithChildren } from "react-circular-progressbar";

const User = (props) => {
  let user = props.user;
  let state = props.state;

  const { theme } = props;

  const getProgressStyle = () => {
    return {
      path: { stroke: theme === "dark" ? "#9ecc14" : "#7da60c" },
      trail: { stroke: theme === "dark" ? "#262626" : "#fafafa" },
    };
  };

  return (
    <div
      style={{
        borderRadius: 16,
        minWidth: "54px",
        maxWidth: "270px",
        transition: "max-width 0.25s ease-in-out, background-color 0.25s ease-in-out",
      }}
      className={`w-100 container-hover p-2 ${
        state !== "exited" ? "hover-surface-bg-on-surface" : ""
      } ${theme}`}
    >
      <div className="overflow-hidden">
        <div
          className="d-inline-flex gap-2 align-items-center"
          onClick={() => props.setShowAccount(true)}
        >
          <div
            style={{
              height: 54,
              width: 54,
            }}
          >
            <CircularProgressbarWithChildren
              value={props.progress * 100}
              strokeWidth={6}
              styles={getProgressStyle()}
            >
              <div
                className="row-center align-items-center h-100 w-100"
                style={{ padding: 6 }}
              >
                <div className="row-center align-items-center rounded-circle bg-gray-400 h-100 w-100">
                  {!user.image ? (
                    <i className="fa-solid fa-user txt-gray-500 txt-12"></i>
                  ) : (
                    <img
                      className="user-img w-100 h-100 overflow-hidden rounded-circle"
                      src={`${process.env.REACT_APP_IMAGES_URL}/profile-photos/${user.idUser}/150x150/${user.image}`}
                      alt="user-profile"
                    />
                  )}
                </div>
              </div>
            </CircularProgressbarWithChildren>
          </div>
          {state !== "exited" && (
            <div
              className={`txt-phrase txt-bold txt-8 content-default-primary ${theme} pe-pointer text-nowrap text-ellipsis`}
              style={{
                maxWidth: "150px",
                opacity: state !== "exited" ? 1 : 0,
                transition: "opacity 0.25s ease-in-out",
              }}
            >
              {user.name} {user.lastname}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.users.user,
  progress: state.users.profileProgress,
  account: state.accounts.account,
  theme: state.theme.theme,
});

export default connect(mapStateToProps)(User);
