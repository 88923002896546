import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as plusActions from "../../../../store/actions/Plus";
import { Modal, Animation, Alert } from 'rsuite';
import CreateDevice from './CreateDevice';
import empty_modules_plus from "../../../../imgs/box.svg";
import { track } from "@amplitude/analytics-browser";
import InputRounded from '../../../shared/utilities/InputRounded';

const DataAppliances = ({ value, property, unit, theme }) => {
    return (
        <div className="row-between w-100">
            <div className="txt-phrase txt-regular txt-gray-400 txt-6">{property}</div>
            <div className="row-end align-items-center">
                <div className={`label-small-semibold content-default-primary ${theme}`}>{value}</div>
                <div className={`label-small-semibold content-default-primary ${theme} ${unit ? 'ms-2' : 'ms-0'}`}>{unit}</div>
            </div>
        </div>
    );
};

const Devices = ({ item, language, onChange, appliancesSelected, theme }) => {
    const [showInfoDevice, setShowInfoDevice] = useState(false);

    useEffect(() => {
        onChange(appliancesSelected);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appliancesSelected]);

    return (
        <Fragment>
            <div className={`row-between align-items-center w-100 pe-pointer mt-3 p-2 rounded-${showInfoDevice ? 'top-8' : '8'} ${showInfoDevice || appliancesSelected.includes(item.id_appliance) ? `surface-bg-on-surface` : `surface-bg-fill`} ${theme}`} onClick={() => setShowInfoDevice(!showInfoDevice)}>
                <div className="row-start align-items-center">
                    <div className={`column-center rounded-8 surface-bg-on-surface p-2 ${theme}`} style={{ width: 40, height: 40 }}>
                        <i className={`fa-regular fa-plug-circle-bolt txt-14 content-color-green ${theme}`}></i>
                    </div>
                    <div className={`col-8 text-wrap label-small-semibold content-default-primary ms-3 pe-pointer ${theme}`}>{item.custom_name}</div>
                </div>
                <i className={`fa-regular fa-chevron-down txt-10 pe-pointer content-default-primary ${theme} ${!showInfoDevice ? 'device-detail-open' : 'device-detail-close'}`}></i>
            </div>
            <Animation.Collapse in={showInfoDevice} dimension={'height'}>
                <div className={`column-start surface-bg-on-surface w-100 rounded-bottom-8 p-2 ${theme}`}>
                    {item.id_tag ?
                        <Fragment>
                            <DataAppliances value={item.active_cons.toFixed(4)} property={language.tag_new_tag_text_average_comsuption} unit={"Wh"} theme={theme} />
                            <DataAppliances value={item.duration} property={language.tag_new_tag_text_duration} unit={"s"} theme={theme} />
                        </Fragment>
                        :
                        <div className="row-between w-100">
                            <div className={`label-small-semibold content-default-primary ${theme}`}>{language.tag_new_tag_text_no_tag}</div>
                        </div>
                    }
                    <div className='row-center'>
                        <button
                            onClick={() => {
                                if (!appliancesSelected.includes(item.id_appliance)) {
                                    onChange([...appliancesSelected, item.id_appliance]);
                                    Alert.success(language.alert_create_new_tag_associated_tags);
                                    setShowInfoDevice(false);
                                }
                            }}
                            className={`button-fill-brand gap-2 py-2 px-3 rounded-pill label-medium-medium button-content-equal pe-pointer ${theme}`}>{language.tag_new_tag_button_link_tag}</button>
                    </div>
                </div>
            </Animation.Collapse>
        </Fragment>
    );
};

const CreateTag = ({ theme, language, appliances, activeServicesPlus, show, setShow, pointOne, pointTwo, loadAppliances, addTags }) => {
    const [showCreateDevice, setShowCreateDevice] = useState(false);
    const [devices, setDevices] = useState([]);
    const [search, setSearch] = useState('');
    const [appliancesSelected, setAppliancesSelected] = useState([]);

    useEffect(() => {
        setDevices([...appliances.filter(d => d?.custom_name?.toLowerCase().includes(search?.toLowerCase()))]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    useEffect(() => {
        if (activeServicesPlus.length) loadAppliances();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeServicesPlus]);

    useEffect(() => {
        setDevices(appliances);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appliances]);

    return (
        <Fragment>
            <Modal
                size="sm"
                overflow={false}
                className={`rounded-24 surface-bg-surface border-fill-medium ${theme}`}
                backdropClassName={`w-100 h-100 surface-bg-blackout ${theme}`}
                show={show}
                onHide={() => { setAppliancesSelected([]); setShow(false); }}
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
                <div className={`column-between surface-bg-surface align-items-start rounded-24 w-100 ${theme}`}>
                    <div className={`row-between align-items-center py-3 px-3 surface-bg-fill w-100 rounded-top-24 ${theme}`}>
                        <div className={`content-color-green label-small-medium pe-pointer ${theme}`} onClick={() => { setAppliancesSelected([]); setShow(false); }}>{language.tag_new_tag_header_label_cancel}</div>
                        <div className={`label-large-semibold content-default-primary ${theme}`}>{language.tag_new_tag_header_label_tag}</div>
                        <div className={`content-color-green label-small-medium pe-pointer ${theme}`}
                            onClick={() => {
                                addTags({ id_appliance: appliancesSelected, initial_timestamp: pointOne / 1000, final_timestamp: pointTwo ? pointTwo / 1000 : null });
                                track('Plus graphic tag created');
                                setAppliancesSelected([]);
                                setShow(false);
                            }}>
                            {language.tag_new_tag_header_label_save}
                        </div>
                    </div>
                    <div className={`column-start items-align-center w-100 p-3 ${theme}`}>
                        <InputRounded
                            placeholder={language.tag_new_tag_input_label_search}
                            type={'text'}
                            onChange={({ target }) => setSearch(target.value)}
                            value={search}
                            icon={search}
                            py={2}
                            theme={theme}
                        />
                        <div className={`label-small-medium mt-3 content-default-primary ${theme}`}>{language.tag_new_tag_title_devices_list}</div>
                        {devices.length ? devices.map((item, index) =>
                            <Devices key={index} item={item} language={language} onChange={value => setAppliancesSelected(value)} appliancesSelected={appliancesSelected} theme={theme} />
                        ) 
                        :<div className={`column-center align-items-center w-100 mt-3 ${theme}`}>
                            <img src={empty_modules_plus} alt="empty_radar_data" style={{ width: 144, height: 144 }} />
                            <div className={`label-small-medium content-default-tertiary mt-3 w-50 text-center ${theme}`}>
                                {language.tag_new_tag_text_no_devices}
                            </div>
                            <div className={`label-small-medium content-default-tertiary mt-3 w-50 text-center ${theme}`}>{language.tag_new_tag_text_no_devices_description}</div>
                        </div>}
                    </div>
                    <div className={`row-end align-items-center w-100 px-3 gap-2 surface-bg-fill w-100 rounded-bottom-24 ${theme}`} style={{ flexShrink: 0, height: '7vh' }}>
                        <button className={`button-fill-brand gap-2 py-2 px-3 rounded-pill label-medium-medium button-content-equal pe-pointer ${theme}`} onClick={() => setShowCreateDevice(true)}>{language.tag_new_tag_button_new_device}</button>
                    </div>
                </div>
            </Modal>
            <CreateDevice show={showCreateDevice} setShow={setShowCreateDevice} theme={theme} />
        </Fragment>
    );
};

const mapStateToProps = (state) => ({
    activeServicesPlus: state.services.activeServicesPlus,
    appliances: state.plus.appliances,
    language: state.shared.language,
    theme: state.theme.theme
});

const mapDispatchToProps = dispatch => ({
    loadAppliances: () => dispatch(plusActions.loadAppliances()),
    addTags: (payload) => dispatch(plusActions.addTags(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateTag);