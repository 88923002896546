import React, { Fragment, useState, useEffect } from "react";
import { connect } from 'react-redux';

import * as loginActions from '../../store/actions/Login';

import { Redirect } from "react-router-dom";

import { Transition } from 'react-transition-group';

import { Loader, Alert } from 'rsuite';

import { getAnalytics, logEvent } from "firebase/analytics";

import InputRounded from "../shared/utilities/InputRounded";

import AuthCode from 'react-auth-code-input';

import { themes } from "../../utils/enums";

const analytics = getAnalytics();

const showAlert = message => {
  Alert.error(message);
}

const Login = (props) => {

  let step = props.step;
  let theme = props.theme;
  let language = props.language;
  let loading = props.loading;

  const loadInitInfoAsync = props.loadInitInfoAsync;

  const logo = require(`../../imgs/logo-enrg-${theme}.svg`);

  const [inputValue, setInputValue] = useState("");
  const [data, setData] = useState("");

  const setInput = ((input) => {

    let value = input ? input : inputValue;

    if (step === 2) {

      props.loadInfoUserAsync(value, showAlert);
      setInputValue("");

    } else {

      props.generateCodeAsync(value, showAlert);

      setData(value);
      setInputValue("");

    }
  });

  useEffect(() => loadInitInfoAsync(), [loadInitInfoAsync]);

  useEffect(() => logEvent(analytics, "login"), []);  

  return (
    <section className={`row-between align-items-center vh-100 w-100 surface-bg-fill ${theme}`}>
      <div className="position-relative column-between align-items-center w-100 h-100 py-5 px-2">
        <Transition
          in={loading}
          timeout={100}>
          {state => (
            <div className={`trans-fade trans-fade-${state} position-absolute w-100 h-100 top-0 left-0 zindex-1`}>
              <div className="column-center align-items-center w-100 h-100">
                <Loader inverse={theme === themes.DARK} center size="md" content={language.loader_text_content} />
              </div>
            </div>
          )}
        </Transition>
        {step === 3 ?
          <Redirect to="/home" /> :
          (step === 1 || step === 2) && !loading &&
            <Fragment>
              <div className="column-start align-items-center gap-3">
                <img src={logo} alt="logo-enrg" style={{ width: 100 }} />
                <h1 className={`content-default-primary heading-h6-semibold text-center text-wrap ${theme}`}>{step === 2 ? language.login_title_step_two : language.login_main_title}</h1>
              </div>
              <div className="column-center align-items-center flex-grow-1">
                <div className="column-center align-items-center gap-3">
                  {step === 2 ?
                    <Fragment>
                      <p className={`label-small-medium content-default-primary ${theme}`}>{language.login_input_label_code} <br/> {data}</p>
                      <div className={`row-center align-items-center custom-verification-code surface-bg-surface border-fill-regular rounded-pill py-3 px-5 ${theme}`}>
                        <AuthCode 
                          autoFocus={true} 
                          containerClassName='row-between gap-4 ps-3' 
                          inputClassName={`login-input-code bg-transparent heading-h3-semibold content-default-secondary ${theme}`}
                          placeholder="-" 
                          allowedCharacters='numeric' 
                          onChange={(value) => {
                            if (value.length === 6){
                              setInputValue(value);
                              setInput(value);
                            }
                          }} />
                      </div>
                      <div className="row-between align-items-center gap-2">
                        <p className={`label-small-regular content-default-secondary ${theme}`}>{language.login_question_code}</p>
                        {props.resending ?
                          <p className={`label-XSmall-regular content-default-primary m-0 ${theme}`}>{language.login_await_resend_code} {props.counter} {language.login_unit_await_resend_code}</p> :
                          <p className={`label-XSmall-regular content-default-primary pe-pointer m-0 ${theme}`} onClick={() => props.generateCodeAsync(data, showAlert)}><ins>{language.login_resend_code}</ins></p>
                        }
                      </div>
                    </Fragment> :
                    <Fragment>
                      <p className={`label-medium-medium content-default-primary ${theme}`}>{language.login_title_description}</p>
                      <InputRounded
                        placeholder={language.login_input_label_data}
                        icon='user'
                        type='text'
                        focus={true}
                        onChange={({ target }) => setInputValue(target.value.trimStart().trimEnd())}
                        onPressEnter={() => setInput()} />
                    </Fragment>}
                </div>
              </div>
              <button className={`row-center align-items-center gap-2 px-3 py-2 button-fill-default button-border-default rounded-pill label-small-regular button-content-inverse ${theme}`} onClick={() => setInput()}>
                {language.login_button_enter}
                <i className="fa-regular fa-chevron-right label-medium-regular"></i>
              </button>
            </Fragment>
        }
      </div>
    </section>);
};

const mapStateToProps = (state) => ({
  step: state.login.step,
  loading: state.login.loading,
  resending: state.login.resending,
  counter: state.login.counter,
  language: state.shared.language,
  theme: state.theme.theme
})

const mapDispatchToProps = dispatch => ({
  generateCodeAsync: (payload, showAlert) => dispatch(loginActions.generateCodeAsync(payload, showAlert)),
  loadInfoUserAsync: (payload, showAlert) => dispatch(loginActions.loadInfoUserAsync(payload, showAlert)),
  loadInitInfoAsync: () => dispatch(loginActions.loadInitInfoAsync())
})

export default connect(mapStateToProps, mapDispatchToProps)(Login)
