import React from "react";
import { connect } from "react-redux";

const ThemedImage = ({ imageName, ...props }) => {
    let theme = props.theme;
    let language = props.language.language_locale;    

    const getImagePath = (theme, language, imageName) => {        
        try {                
            return require(`../../../imgs/onboarding/${theme}/${language}/${imageName}`);
        } catch (error) {           
            return null;
        }        
    };


    return <img src={getImagePath(theme, language, imageName)} alt={imageName} className="w-100 h-100" style={{ objectFit: 'contain' }} />;
};

const mapStateToProps = (state) => ({
    language: state.shared.language,
    theme: state.theme.theme
});

export default connect(mapStateToProps)(ThemedImage);
