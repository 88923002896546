const current = process.env.REACT_APP_API_URL;

const SETData = async (url, method, params, endpoint = current, simple = false) => {

    let token = localStorage.getItem('token');

    const headers_simple = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    };

    const response = await fetch(`${endpoint}${url}`, {
        method: method,
        headers: simple ? headers_simple :
            new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
                'Authorization': `Bearer ${token}`
            }),
        body: JSON.stringify(params)
    });

    if (response.ok) {

        const body = await response.text().then(response);

        if (body !== "") { return JSON.parse(body); } else { return null; }

    } else {
        return null;
    }

}

const SETFile = async (url, method, params, endpoint = current) => {

    let token = localStorage.getItem('token');

    const response = await fetch(`${endpoint}${url}`, {
        method: method,
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: params
    });

    if (response.ok) {

        const body = await response.text().then(response);

        if (body !== "") { return JSON.parse(body); } else { return null; }

    } else {
        return null;
    }

}

const SETBlobFile = async (url, method, params, endpoint = current) => {

    let token = localStorage.getItem('token');

    const response = await fetch(`${endpoint}${url}`, {
        method: method,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: params
    });

    if (response.ok) {
        const blob = await response.blob();
        return blob;
    } else {
        return null;
    }

}

const GETFile = async (url, method, params, endpoint = current) => {

    let token = localStorage.getItem('token');

    const response = await fetch(`${endpoint}${url}`, {
        method: method,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(params)
    });

    if (response.ok) {
        return response;
    } else {
        return null;
    }

}

const GETData = async (url, method, endpoint = current, simple = false) => {

    let token = localStorage.getItem('token');

    const headers_simple = {
        'Accept': 'application/json'
    };

    const response = await fetch(`${endpoint}${url}`, {
        method: method,
        mode: "cors",
        headers: simple ? headers_simple :
            new Headers({
                "Access-Control-Allow-Origin": "*",
                'Authorization': `Bearer ${token}`
            })
    });
    if (response.ok) {

        const body = await response.text().then(response);

        if (body !== "") { return JSON.parse(body); } else { return null; }

    } else { return null; }

};

const GETS3PublicUrl = async (path, bucket = process.env.REACT_APP_S3_BUCKET, region = process.env.REACT_APP_S3_REGION) => {

  const objectDataBody = {
    bucket: bucket,
    path: path,
    region: region,
  }

  try {
    const response = await SETData("bills/url", "POST", objectDataBody)
    if (response !== null) {
      return response.url
    } else {
      return null
    }
  } catch (error) {
    console.error(error, "Error in GETS3PublicUrl")
  }
}

export { SETData, SETFile, SETBlobFile, GETData, GETFile, GETS3PublicUrl };