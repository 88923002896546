import { track } from "@amplitude/analytics-browser";
import React from "react";
import { Link } from "react-router-dom";

const moduleIcons = {
  dashboard: "fa-energy-symbol",
  genius: "fa-genius-symbol",
  efficiency: "fa-efficiency-symbol",
  plus: "fa-plus-symbol",
  invoices: "fa-invoice-symbol",
  analytics: "fa-analytics-symbol",
  equote: "fa-equote-symbol",
  pm: "fa-folders",
};

const DynamicMenu = ({
  userModules,
  role,
  active,
  setCurrentModule,
  language,
  theme,
  state,
}) => {
  const filteredModules = userModules.filter(
    (module) => module.redirect && module.worker === (role.type === 1)
  );

  return (
    <div
      style={{ paddingLeft: 18, paddingRight: 18 }}
      className={`container-hover d-flex flex-column justify-content-center align-items-center w-100 gap-2`}
    >
      {filteredModules.map((module, index) => {
        const parts = module.redirect.split("/")[1].toLowerCase();

        return (
          <Link
            className="dynamic-container-hover"
            key={module.idModule}
            style={{
              height: 40,
              width: "100%",
              transition: "all 0.25s ease-in-out",
            }}
            to={module.redirect === "/dashboard" ? "/home" : module.redirect}
          >
            <div
              className={`overflow-hidden rounded-8`}
              onClick={() => {
                setCurrentModule(index);
                module.name.toLowerCase() === "genius" &&
                  track("Genius viewed");
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: 40,
                  gap: 16,
                  transition: "all 0.25s ease-in-out",
                }}
                className={`d-flex dynamic-container-hover align-items-center p-2 ${
                  `/${active}` === module.redirect.toLowerCase() &&
                  `button-fill-default border-fill-medium ${theme}`
                }`}
              >
                <div
                  style={{
                    width: "auto",
                    marginLeft: 3,
                  }}
                >
                  <i
                    style={{
                      width: 18,
                      fontSize: 18,
                    }}
                    className={`fak ${moduleIcons[parts] || "fa-default"} ${
                      `/${active}` === module.redirect.toLowerCase()
                        ? "button-content-inverse"
                        : "content-default-primary"
                    } ${theme} d-flex justify-content-center align-items-center`}
                  ></i>
                </div>

                <div
                  className={`d-flex align-items-center label-medium-regular ${
                    `/${active}` === module.redirect.toLowerCase()
                      ? "button-content-inverse"
                      : "content-default-primary"
                  } ${theme}`}
                >
                  <span
                    className={`text-expand ${
                      state !== "exited" ? "text-expand-active" : ""
                    }`}
                  >
                    {language[`menu_${module.name.toLowerCase()}_service`] ??
                      module.name}
                  </span>
                </div>
              </div>
            </div>
          </Link>
        );
      })}
    </div>
  );
};

export default DynamicMenu;
