import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import moment from "moment-timezone";

import CurrencyFormat from 'react-currency-format';

const formatDateLabel = (intervalGraph, domain, lang) => {
  let dateFormated;
  let intervalLeft;
  let intervalRight;
  moment.locale(lang);
  switch (intervalGraph) {
    case 0:
      dateFormated = moment
        .utc(domain?.x[1] * 1000)
        .tz("America/Bogota")
        .format("DD MMMM YYYY");
      break;
    case 1:
    case 2:
      intervalLeft = moment.utc((domain?.x[0] + 86400) * 1000).format("MMM DD");
      intervalRight = moment.utc(domain?.x[1] * 1000).format("MMM DD");
      dateFormated = `${intervalLeft} ${lang === 'es' ? 'a' : '-'} ${intervalRight}`;
      break;
    case 3:
      intervalLeft = moment.utc((domain?.x[0] + 2592000) * 1000).format("MMM YYYY");
      intervalRight = moment.utc(domain?.x[1] * 1000).format("MMM YYYY");
      dateFormated = `${intervalLeft} ${lang === 'es' ? 'a' : '-'} ${intervalRight}`;
      break;
    default:
      dateFormated = "";
      break;
  }
  return dateFormated;
};

const GraphIndicators = (props) => {

  let data = props.data;
  let domain = props.domain;
  let activeGraphs = props.activeGraphs;
  let language = props.language;
  let theme = props.theme;
  let intervalGraph = props.intervalGraph;

  const [indexes, setIndexes] = useState({ tot: 0, max: 0, avg: 0, min: 0, unit: "" });
  const [showIndexes, setShowIndexes] = useState(true);

  useEffect(() => {
    const handlerIndexes = () => {
      let tot;
      let min;
      let max;
      let avg;
      let unit;
      let array = [];

      if (data.length === 1 && data[0]?.data.length) {

        const keys = [];
        const uniqueArray = [];
        const dataFiltered = data.map((value) => value.data.filter((value) => value.y > 0 && value?.x_sec > domain?.x[0] && value?.x_sec <= domain?.x[1]));
        dataFiltered[0].forEach(item => {
          if (!keys.includes(item?.x_sec)) {
            uniqueArray.push(item);
            keys.push(item?.x_sec);
          }
        })

        array = uniqueArray.map((value) => value.y).reduce((a, b) => a.concat(b), []);

        unit = data[0].data[0].unit;

        if (array.length) {
          tot = array.reduce((prev, curr) => (curr += prev)).toFixed(2);
          min = Math.min(...array).toFixed(2);
          max = Math.max(...array).toFixed(2);
          avg = (tot / array.length).toFixed(2);

          setIndexes({ tot, max, avg, min, unit });

          setShowIndexes(true);

          return;
        }
      } else {
        setShowIndexes(false);
      }
    };

    handlerIndexes();
  }, [data, domain]);

  const capitalizedText = (text) => {
    let label = "";
    var phrase = text.toLowerCase().split(" ");

    for (var i = 0; i < phrase.length; i++) {
      if (i !== 2) {
        phrase[i] = phrase[i].charAt(0).toUpperCase() + phrase[i].substring(1);
      }
    }

    label = phrase.join(" ");

    return label;
  };

  return (
    showIndexes && (
      <div className="row-center align-items-center w-100 gap-3 my-3">
        <div className="row-between align-items-center align-self-stretch">
          <div className={`row-center align-items-center h-100 rounded-pill px-3 py-2 surface-bg-on-surface border-fill-medium ${theme}`}>
            <i className={`fa-solid fa-calendar txt-8 me-3 content-default-primary ${theme}`}></i>
            <div className={`label-XSmall-regular txt-truncate content-default-primary ${theme}`}>{capitalizedText(formatDateLabel(props.intervalGraph, props.domain, language.language_locale))}</div>
          </div>
        </div>
        <div className={`row-between align-items-center px-3 py-2 rounded-pill surface-bg-on-surface border-fill-medium ${theme}`}>
          <div className="row-center align-items-center">
            <i className={`fa-solid fa-chart-simple txt-12 content-color-blue ${theme}`}></i>
          </div>
          {activeGraphs?.includes("PANEL_TEMP") || activeGraphs?.includes("ENVIRONMENT_TEMP")?
          <div className="column-start mx-3 gap-1">
            <p className={`txt-truncate content-default-secondary label-XSmall-regular ${theme}`}>{props.language.energy_graph_indicator_min} {language[`energy_graph_label_${activeGraphs[0]?.toLowerCase()}`]}</p>
            <CurrencyFormat
              value={indexes.min}
              displayType={'text'}
              thousandSeparator={true}
              renderText={value => <p className={`label-medium-semibold mt-0 content-color-blue ${theme}`}>
                  {value} {indexes.unit}
              </p>} 
            />
          </div> :
          <div className="column-start mx-3 gap-1">
            <p className={`txt-truncate content-default-secondary label-XSmall-regular ${theme}`}>{props.language.energy_graph_indicator_total} {language[`energy_graph_label_${activeGraphs[0]?.toLowerCase()}`]}</p>
            <CurrencyFormat
              value={indexes.tot}
              displayType={'text'}
              thousandSeparator={true}
              renderText={value => <p className={`label-medium-semibold mt-0 content-color-blue ${theme}`}>
                  {value} {intervalGraph === 0 && (activeGraphs[0] === "IRRADIANCE" || activeGraphs[0] === "IRRADIANCE_GEN") ? props.language.energy_graph_unit_irradiance : indexes.unit}
              </p>} 
            />
          </div>}
        </div>
        <div className={`row-between align-items-center px-3 py-2 rounded-pill surface-bg-on-surface border-fill-medium ${theme}`}>
          <div className="row-center align-items-center">
            <i className={`fa-solid fa-arrow-up-to-line txt-12 content-color-orange ${theme}`}></i>
          </div>
          <div className="column-start mx-3 gap-1">
            <p className={`txt-truncate content-default-secondary label-XSmall-regular ${theme}`}>
              {props.language.energy_graph_indicator_max} {language[`energy_graph_label_${intervalGraph === 0 && (activeGraphs[0] === "IRRADIANCE" || activeGraphs[0] === "IRRADIANCE_GEN") ? "irradiance_hourly" : activeGraphs[0]?.toLowerCase()}`]}
            </p>
            <CurrencyFormat
              value={indexes.max}
              displayType={'text'}
              thousandSeparator={true}
              renderText={value => <p className={`label-medium-semibold mt-0 content-color-orange ${theme}`}>
                  {value} {indexes.unit}
              </p>} 
            />
          </div>
        </div>
        <div className={`row-between align-items-center px-3 py-2 rounded-pill surface-bg-on-surface border-fill-medium ${theme}`}>
          <div className="row-center align-items-center">
            <i className={`fa-solid fa-objects-align-center-vertical txt-12 content-color-green ${theme}`}></i>
          </div>
          <div className="column-start mx-3 gap-1">
            <p className={`txt-truncate content-default-secondary label-XSmall-regular ${theme}`}>
              {props.language.energy_graph_indicator_average} {language[`energy_graph_label_${intervalGraph === 0 && (activeGraphs[0] === "IRRADIANCE" || activeGraphs[0] === "IRRADIANCE_GEN") ? "irradiance_hourly" : activeGraphs[0]?.toLowerCase()}`]}
            </p>
            <CurrencyFormat
              value={indexes.avg}
              displayType={'text'}
              thousandSeparator={true}
              renderText={value => <p className={`label-medium-semibold mt-0 content-color-green ${theme}`}>
                  {value} {indexes.unit}
              </p>} 
            />
          </div>
        </div>
      </div>
    )
  );
};

const mapStateToProps = (state) => ({
  graphData: state.energy.graphData,
  language: state.shared.language,
  activeGraphs: state.energy.activeGraphs,
  theme: state.theme.theme,
  intervalGraph: state.energy.intervalGraph,
});

export default connect(mapStateToProps)(GraphIndicators);
