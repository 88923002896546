import React from 'react'

const BackButton = ({ onClick, theme }) => {

    return (
        <button className={`row-center align-items-center rounded-pill surface-bg-on-surface border-fill-medium ${theme}`} style={{ width: 32, height: 32 }} onClick={onClick}>
            <i className={`fa-light fa-chevron-left txt-8 content-default-primary pr-pointer ${theme}`}></i>
        </button>
    )
}

export default BackButton