import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import * as plusActions from "../../../../store/actions/Plus";
import { GETS3PublicUrl, SETData } from "../../../../services/WebServices";
import { Modal } from 'rsuite';
import moment from "moment";
import { Loader } from "rsuite";
import { Calendar as Datepicker } from 'react-calendar';
import { Transition } from 'react-transition-group';
import Input from '../../../shared/utilities/Input';
import Select from '../../../shared/utilities/Select';
import { track } from "@amplitude/analytics-browser";

const downloadBlob = async (url, name) => {
    try {
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error("Error al obtener el archivo.");
        }
        const blob = await response.blob();
        const objectUrl = URL.createObjectURL(blob);

        const anchor = document.createElement("a");
        anchor.href = objectUrl;
        anchor.download = name;
        document.body.appendChild(anchor);
        anchor.dispatchEvent(
            new MouseEvent("click", {
                bubbles: true,
                cancelable: true,
                view: window,
            }),
        );
        document.body.removeChild(anchor);
        URL.revokeObjectURL(objectUrl);
    } catch (error) {
        console.error("Error al descargar el archivo:", error);
    }
};

const handleDownloadCSV = async (path, name, type, setStepDownload) => {
    const url = await GETS3PublicUrl(path, "neu-plus", "us-east-2");
    await downloadBlob(url, name);
    setStepDownload(0);
};

const exportFile = async (payload, setStepDownload) => {
    const endpoint = 'https://g5c5659uk1.execute-api.us-east-2.amazonaws.com/PDN/file';
    setStepDownload(1);
    await SETData('', 'POST', payload, endpoint, true)
        .then(async response => {
            if (response.statusCode === 200) {
                const path = response.body;
                const name = path.split("/")[2];
                const type = name.split(".")[1];
                setStepDownload(2);
                await handleDownloadCSV(path, name, type, setStepDownload);
            }
        })
        .catch(response => {
            console.error(response);
        });
};

const showError = (payloadExport, setFocus, setError, language) => {
    if (payloadExport.startTime === null) {
        setFocus(1);
        setError(language.alert_date_report_error);
        return;
    }

    if (payloadExport.nameFile === null || payloadExport.nameFile === "") {
        setFocus(2);
        setError(language.alert_filename_report_error);
        return;
    }

    if (payloadExport.request === null) {
        setFocus(3);
        setError(language.alert_format_filename_report_error);
        return;
    }

    return setFocus(0);
};

const validate = (payloadExport) => {
    if (payloadExport.nameFile === null || payloadExport.nameFile === "") {
        return false;
    }

    if (payloadExport.request === null) {
        return false;
    }

    if (payloadExport.startTime === null) {
        return false;
    }

    return true;
};

const ExportGraph = (props) => {
    const { theme, language } = props;

    const formatTypes = [
        {
            value: 'CSV',
            label: 'CSV'
        },
        {
            value: 'HTML',
            label: 'HTML (Gráfica interactiva)'
        }
    ];

    const payloadToExportInit = {
        request: "CSV",
        nameFile: null,
        startTime: null,
        endTime: null,
        deviceId: null,
        projectName: ""
    };

    const [payloadToExport, setPayloadToExport] = useState(payloadToExportInit);
    const [stepDownload, setStepDownload] = useState(0);
    const [showCalendar, setShowCalendar] = useState(false);
    const [focus, setFocus] = useState(0);
    const [error, setError] = useState("");

    useEffect(() => {
        if (props.devices.length) {
            const idService = props.activeServicesPlus[0]?.idService;
            const deviceActive = props.devices.filter(value => value?.id_service === idService)[0];
            setPayloadToExport({ ...payloadToExport, deviceId: deviceActive?.id_device, projectName: props.activeServicesPlus[0]?.name });
        }
        // eslint-disable-next-line
    }, [props.devices, props.activeServicesPlus]);

    useEffect(() => {
        if (stepDownload === 0) {
            props.setShow(false);
        }
        // eslint-disable-next-line
    }, [stepDownload]);

    useEffect(() => {
        if (focus !== 0) { showError(payloadToExport, setFocus, setError, language); }
    }, [payloadToExport, language, focus]);

    return (
        <Modal
            size="sm"
            overflow={false}
            className={`rounded-24 surface-bg-surface border-fill-medium ${theme}`}
            backdropClassName={`w-100 h-100 surface-bg-blackout ${theme}`}
            show={props.show}
            onHide={() => props.setShow(false)}
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
            <Fragment>
                <div className={`column-between surface-bg-surface align-items-start rounded-24 w-100 ${theme}`}>
                    <div className={`row-between align-items-center py-3 px-3 surface-bg-fill w-100 rounded-top-24 ${theme}`}>
                        <div className='w-100 row-center align-items-center'>
                            <p className={`label-large-semibold content-default-primary ${theme}`}>{language.plus_export_graph_title}</p>
                        </div>
                        <button onClick={() => { props.setShow(false); setPayloadToExport(payloadToExportInit); setStepDownload(0); }}
                            className={`column-center align-items-center rounded-30 border-fill-medium surface-bg-on-surface ${theme}`}
                            style={{ width: '25px', height: '25px' }} >
                            <i className={`fa-light fa-xmark txt-10 content-default-primary ${theme}`} style={{ fontSize: 10 }} />
                        </button>
                    </div>
                    <div className={`column-start items-align-center w-100 p-3 ${theme}`}>
                        <div className="w-100 mt-2">
                            <div className={`label-small-medium mb-2 content-default-primary ${theme}`}>{language.plus_export_graph_date}</div>
                            <div className={`row-between align-items-center py-2 px-3 gap-2 border-fill-medium surface-bg-surface rounded-pill pe-pointer ${theme}`} onClick={() => setShowCalendar(!showCalendar)}>
                                <p className={`content-default-tertiary label-small-regular ${theme}`}>{payloadToExport.startTime ? payloadToExport.startTime.split(" ")[0] : "Selecciona la fecha"}</p>
                                <i className={`fa-solid fa-calendar-days content-default-secondary txt-8 ${theme}`}></i>
                            </div>
                            {focus === 1 ? <p className='txt-phrase txt-regular txt-6 txt-red-500 mt-1 w-100 text-wrap'>{error}</p> : null}
                            <Transition
                                in={showCalendar}
                                timeout={50}>
                                {state => (
                                    <div className={`trans-fade trans-fade-${state}`}>
                                        <Datepicker
                                            next2Label={null}
                                            prev2Label={null}
                                            maxDate={new Date()}
                                            className={`rounded-16 border-fill-medium surface-bg-on-surface border-fill-medium ${theme}`}
                                            nextLabel={<i className={`fa-regular fa-chevron-right content-default-secondary txt-8 ${theme}`}></i>}
                                            prevLabel={<i className={`fa-regular fa-chevron-left content-default-secondary txt-8 ${theme}`}></i>}
                                            onChange={value => {
                                                const date = moment(value).format("YYYY-MM-DD");
                                                setPayloadToExport({ ...payloadToExport, startTime: `${date} 00:00:00`, endTime: `${date} 23:59:59` });
                                                setShowCalendar(false);
                                            }} />
                                    </div>
                                )}
                            </Transition>
                        </div>
                        <div className="w-100 my-3">
                            <Input
                                label={'Nombre del documento'}
                                placeholder='Nombre del documento'
                                type={'text'}
                                onChange={({ target }) => setPayloadToExport({ ...payloadToExport, nameFile: target.value })}
                                value={payloadToExport?.nameFile}
                                error={focus === 2 ? error : ""}
                                theme={theme}
                            />
                        </div>
                        <div className="w-100 mb-2">
                            <Select
                                label={"Formato del documento"}
                                options={formatTypes}
                                value={payloadToExport.request}
                                onChange={(value) => setPayloadToExport({ ...payloadToExport, request: value })}
                                width={353}
                                theme={theme}
                                type_label='label-small-regular'
                            />
                        </div>
                        <div className={`${stepDownload === 0 ? null : "my-3"} txt-8 txt-phrase txt-bold ${stepDownload === 1 ? "txt-blue-700" : "txt-green-500"} ${theme}`}>
                            {stepDownload === 1 ?
                                <div className="row-start align-items-center">
                                    <i className="fa-regular fa-circle-info txt-10"></i>
                                    <div className="txt-phrase txt-semibold txt-8 ms-2 pe-pointer">{`El reporte ${payloadToExport.nameFile} se está creando.`}</div>
                                </div>
                                :
                                stepDownload === 2 ?
                                    <div className="row-start align-items-center">
                                        <i className="fa-regular fa-circle-check txt-10"></i>
                                        <div className="txt-phrase txt-semibold txt-8 ms-2 pe-pointer">{`El reporte ${payloadToExport.nameFile} se está descargando.`}</div>
                                    </div>
                                    : null}
                        </div>
                    </div>
                    <div className={`row-end align-items-center w-100 px-3 gap-2 surface-bg-fill w-100 rounded-bottom-24 ${theme}`}
                        style={{ flexShrink: 0, height: '7vh' }}>
                        <button className={`button-fill-brand gap-2 py-2 px-3 rounded-pill pe-pointer ${theme}`}
                            onClick={async () => {
                                if (validate(payloadToExport) && stepDownload === 0) {
                                    await exportFile(payloadToExport, setStepDownload);
                                    track('Plus graphic downloaded', { Rank_ID: payloadToExport?.startTime });
                                }
                                else {
                                    showError(payloadToExport, setFocus, setError, language);
                                }
                            }}>
                            {stepDownload === 0 ?
                                <div className="row-start align-items-center">
                                    <i className={`fa-regular fa-file-xmark button-content-equal txt-8 ${theme}`}></i>
                                    <div className={`label-medium-medium button-content-equal ms-2 ${theme}`}>{language.plus_export_graph_button}</div>
                                </div> : <div className='column-center align-items-center h-100'><Loader inverse /></div>}
                        </button>
                    </div>
                </div>
            </Fragment>
        </Modal>
    );
};

const mapStateToProps = (state) => ({
    activeServicesPlus: state.services.activeServicesPlus,
    devices: state.plus.devices,
    language: state.shared.language,
    theme: state.theme.theme
});

const mapDispatchToProps = dispatch => ({
    addAppliance: (payload) => dispatch(plusActions.addAppliance(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExportGraph);