import * as creators from '../creators/Alerts';

const initialState = {
    alerts: [],
    loading: false,
    alertRegisters: [],
    alertRegistersPagination: null,
    alertRegistersLoading: true,
    totalalertRegistersInfo: null,
    showDrawerAlerts: false,
    graphs: null,    
}

export const alertsReducer = (state = initialState, { type, payload }) => {

    switch (type) {
        case creators.SET_ALERTS:
            return { ...state, alerts: payload };
        case creators.SET_ALERTS_LOADING:
            return { ...state, loading: payload };
        case creators.SET_ALERT_REGISTERS:
            return { ...state, alertRegisters: payload };
        case creators.SET_TOTAL_ALERT_REGISTERS_INFO:
            return { ...state, totalalertRegistersInfo: payload };
        case creators.SET_ALERT_REGISTERS_PAGINATION:
            return { ...state, alertRegistersPagination: payload };
        case creators.SET_ALERT_REGISTERS_LOADING:
            return { ...state, alertRegistersLoading: payload };
        case creators.SET_SHOW_DRAWER_ALERTS:
            return { ...state, showDrawerAlerts: payload };      
        case creators.SET_GRAPHS_ALERTS:
            return { ...state, graphs: payload };     
        default:
            return state;
    }

}