import React, { useRef, useEffect } from 'react'
import { connect } from 'react-redux';

import * as energyActions from '../../store/actions/Energy';
import * as servicesActions from '../../store/actions/Services';
import * as sharedActions from '../../store/actions/Shared';
import * as invoicesActions from '../../store/actions/Invoices';
import * as accountsActions from '../../store/actions/Accounts';

import { useHistory } from 'react-router-dom';

import Header from "../shared/Header";

import useTour from '../onboarding/hook/useTour';
import { stepsDashboard } from '../onboarding/steps/DashboardSteps';

const Dashboard = (props) => {

    let user = props.user;
    let account = props.account;
    let activeServicesEnergy = props.activeServicesEnergy;
    let startDashboardOnboarding = props.startDashboardOnboarding;
    let totalAccounts = props.totalAccounts;
    let screenPaddingBottom = props.screenPaddingBottom;
    let theme = props.theme;

    const loadDetailService = props.loadDetailService;
    const loadServicesAsync = props.loadServicesAsync;
    const setActiveServicesEnergy = props.setActiveServicesEnergy;
    const setActiveServicePreonboarding = props.setActiveServicePreonboarding;    
    const setShowBlackScreen = props.setShowBlackScreen;
    const setStartOnboarding = props.setStartOnboarding;
    const setAccount = props.setAccount;
    const setFilterStatus = props.setFilterStatus;

    const history = useHistory();

    const frameEfficiency = useRef(null);

    const handleFirstTourFinish = () => {
        setStartOnboarding({ key: 'dashboard', value: true })
    };

    const tour = useTour({ steps: stepsDashboard(props.language, theme), localStorageKey: "main-tutorial", isOpenDrawer: null, setRunTour: setStartOnboarding, onTourFinish: handleFirstTourFinish });

    useEffect(() => {
        if (!activeServicesEnergy?.filter((item) => item?.idAccount === account?.idAccount).length) {
            account && loadServicesAsync()
        }
        // eslint-disable-next-line
    }, [account, loadServicesAsync])

    useEffect(() => {
        if (activeServicesEnergy.length) {
            setActiveServicesEnergy([])
        }
    }, [setActiveServicesEnergy, activeServicesEnergy])

    useEffect(() => {

        let window = frameEfficiency.current.contentWindow;

        window.postMessage(JSON.stringify({
            screenPaddingBottom
        }
        ), process.env.REACT_APP_DASHBOARD_HOST);

    }, [screenPaddingBottom])

    useEffect(() => {

        let window = frameEfficiency.current.contentWindow;

        window.postMessage(JSON.stringify({
            theme
        }
        ), process.env.REACT_APP_DASHBOARD_HOST);

    }, [theme])

    useEffect(() => {

        const userId = user?.idUser

        if (userId !== null) {

            frameEfficiency.current.addEventListener('load', () => {

                let window = frameEfficiency.current.contentWindow;

                let token = localStorage.getItem('token');

                setTimeout(() => {
                    window.postMessage(JSON.stringify({
                        token: token,
                        user: { idUser: userId, capabilities: user?.capabilities },
                        screenPaddingBottom,
                        theme
                    }
                    ), process.env.REACT_APP_DASHBOARD_HOST);
                }, [100]);

            });
        };

        if (startDashboardOnboarding) {

            let window = frameEfficiency.current.contentWindow;

            window.postMessage(JSON.stringify({
                onboarding: startDashboardOnboarding
            }
            ), process.env.REACT_APP_DASHBOARD_HOST);

            setShowBlackScreen(true);
        }
        // eslint-disable-next-line
    }, [user.idUser, startDashboardOnboarding]);

    //iframe listener
    useEffect(() => {

        window.addEventListener('message', handleMessageFromIframe);

        return () => {
            window.removeEventListener('message', handleMessageFromIframe);
        };
        // eslint-disable-next-line
    }, []);

    const handleMessageFromIframe = (event) => {
        if (event.origin === process.env.REACT_APP_DASHBOARD_HOST) {
            const data = event.data;            
            switch (data.type) {
                case 'Installation':
                    history.push('pre-onboarding');
                    setActiveServicePreonboarding(data);
                    break
                case 'Generation':
                case 'Consumption':
                    history.push({
                        pathname: 'home/detail',
                        state: { data }
                    });
                    loadDetailService(data)
                    break
                case 'consolidate_invoices_no_debt':
                case 'consolidate_invoices_overdue':
                case 'consolidate_invoices_last_period':                    
                    setFilterStatus(data?.statusInvoice);
                    history.push({
                        pathname: 'invoices',
                        state: { data }
                    });                    
                    setAccount((totalAccounts.find(a => a.idAccount === parseInt(data?.idAccount))));                                    
                    break
                case 'plus':
                    history.push({
                        pathname: 'plus',
                        state: { data }
                    });
                    loadDetailService(data)
                    break
                default:
                    break
            }

            if (data?.skippedOnboarding) {                
                setStartOnboarding({ key: 'walktrough', value: true })
            }

            if (data?.finishedOnboarding) {
                setShowBlackScreen(false);
                setStartOnboarding({ key: 'dashboard', value: false })
            }

        }
    };

    return (
        <div className="position-relative w-100 vh-100">
            {tour}
            <div id='navigate_header' className={`position-absolute left-0 top-0 zindex-2 ${theme}`} style={{ height: 74, width: "calc(100% - 18px)" }}>
                <Header moduleName={props.language.menu_main_dashboard} backToButton={false} />
            </div>            
            <iframe key={user?.idUser} ref={frameEfficiency} title="dashboard-section" className={`position-absolute w-100 h-100 left-0 top-0 zindex-${props.showBlackScreen ? 6 : 0}`} src={process.env.REACT_APP_DASHBOARD_HOST}></iframe>
        </div>        
    );

}

const mapStateToProps = (state) => ({
    user: state.users.user,
    activeServicesEnergy: state.services.activeServicesEnergy,
    account: state.accounts.account,
    language: state.shared.language,
    startDashboardOnboarding: state.shared.startOnboarding.dashboard,
    showBlackScreen: state.shared.showBlackScreen,
    totalAccounts: state.accounts.totalAccounts,
    totalServices: state.services.totalServices,
    screenPaddingBottom: state.shared.screenPaddingBottom,
    theme: state.theme.theme
});

const mapDispatchToProps = dispatch => ({
    loadDetailService: (payload) => dispatch(energyActions.loadDetailService(payload)),
    setActiveServicePreonboarding: (payload) => dispatch(servicesActions.setActiveServicePreonboarding(payload)),
    setActiveServicesEnergy: (payload) => dispatch(servicesActions.setActiveServicesEnergy(payload)),
    loadServicesAsync: (payload) => dispatch(servicesActions.loadServicesAsync(payload)),
    setShowBlackScreen: (payload) => dispatch(sharedActions.setShowBlackScreen(payload)),
    setStartOnboarding: (payload) => dispatch(sharedActions.setStartOnboarding(payload)),
    setAccount: (payload) => dispatch(accountsActions.setAccount(payload)),
    setFilterStatus: (payload) => dispatch(invoicesActions.setFilterStatus(payload)),
    setActiveServicesPlus: (payload) => dispatch(servicesActions.setActiveServicesPlus(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)