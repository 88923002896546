import * as users from "../creators/Users";

import { GETData, SETData } from "../../services/WebServices";

import { Alert } from "rsuite";

const url_api = 'https://api.neu.com.co/';

export const setUser = (payload) => {
  return {
    type: users.SET_USER,
    payload,
  };
};

export const setCurrentPageUsers = (payload) => {
  return {
    type: users.SET_CURRENT_PAGE_USERS,
    payload,
  };
};

export const setUserCapabilities = (payload) => {
  return {
    type: users.SET_USER_CAPABILITIES,
    payload,
  };
};

export const setProfileProgress = (payload) => {
  return {
    type: users.SET_PROFILE_PROGRESS,
    payload,
  };
};

export const setSavingInfoUser = (payload) => {
  return {
    type: users.SET_SAVING_INFO_USER,
    payload,
  };
};

export const setShowCreateUserModal = (payload) => {
  return {
    type: users.SET_SHOW_CREATE_USER_MODAL,
    payload,
  };
};

export const setShowUsersListModal = (payload) => {
  return {
    type: users.SET_SHOW_USERS_LIST_MODAL,
    payload,
  };
};

export const setUserJobs = (payload) => {
  return {
    type: users.SET_USER_JOBS,
    payload,
  };
};

export const setUserChannels = (payload) => {
  return {
    type: users.SET_USER_CHANNELS,
    payload,
  };
};

export const setUserPersonalInterests = (payload) => {
  return {
    type: users.SET_USER_PERSONAL_INTERESTS,
    payload,
  };
};

export const setBusinessUnits = (payload) => {
  return {
    type: users.SET_BUSINESS_UNITS,
    payload,
  };
};

export const setUsersAccount = (payload) => {
  return {
    type: users.SET_USERS_ACCOUNT,
    payload,
  };
};

export const setUserAccessEdit = (payload) => {
  return {
    type: users.SET_USER_ACCESS_EDIT,
    payload,
  };
};

export const setUsersAccountLoading = (payload) => {
  return {
    type: users.SET_USERS_ACCOUNT_LOADING,
    payload,
  };
};

export const setUserPreferenceInfo = (payload) => {
  return {
    type: users.SET_USER_PREFERENCE_INFO,
    payload,
  };
};

export const setShowModalUserPreference = (payload) => {
  return {
    type: users.SET_SHOW_MODAL_USER_PREFERENCE,
    payload,
  };
};

export const setUserTokenApi = (payload) => {
  return {
    type: users.SET_USER_TOKEN_API,
    payload,
  };
};

export const setLoadingNewUser = (payload) => {
  return {
    type: users.SET_LOADING_NEW_USER,
    payload,
  };
};

export const setListUsersLoading = (payload) => {
  return {
    type: users.SET_LIST_USERS_LOADING,
    payload,
  };
};

export const setUsers = (payload) => {
  return {
    type: users.SET_USERS,
    payload,
  };
};

export const setLoadingMoreUsers = (payload) => {
  return {
    type: users.SET_LOADING_MORE_USERS,
    payload,
  };
};

export const setTotalPagesUsers = (payload) => {
  return {
    type: users.SET_TOTAL_PAGES_USERS,
    payload,
  };
};

export const setTotalUsers = (payload) => {
  return {
    type: users.SET_TOTAL_USERS,
    payload,
  };
};

export const setShowUserTypeModal = (payload) => {
  return {
    type: users.SET_SHOW_USER_TYPE_MODAL,
    payload,
  };
};

export const setShowInviteSuccessModal = (payload) => {
  return {
    type: users.SET_SHOW_INVITE_SUCCESS_MODAL,
    payload,
  };
};

export const setUserServicesSelected = (payload) => {
  return {
    type: users.SET_USER_SERVICES_SELECTED,
    payload,
  };
};

export const setUserDetail = (payload) => {
  return {
    type: users.SET_USER_DETAIL,
    payload,
  };
};

export const setUserAccountSelected = (payload) => {
  return {
    type: users.SET_USER_ACCOUNT_SELECTED,
    payload,
  };
};

export const setUsersToInvite = (payload) => {
  return {
    type: users.SET_USERS_TO_INVITE,
    payload,
  };
};

export const setUserRegisteredLoading = (payload) => {
  return {
    type: users.SET_USER_REGISTERED_LOADING,
    payload,
  };
};

export const setInfoInviteSuccessModal = (payload) => {
  return {
    type: users.SET_INFO_INVITE_SUCCESS_MODAL,
    payload,
  };
};

export const setLoadingUpdateUser = (payload) => {
  return {
    type: users.SET_LOADING_UPDATE_USER,
    payload,
  };
};

export const setLoadingDeleteUser = (payload) => {
  return {
    type: users.SET_LOADING_DELETE_USER,
    payload,
  };
};

export const setUsersTypeSelect = payload => {
    return {
        type: users.SET_USER_TYPES_SELECT,
        payload
    }
}
export const setAccountSelectedUsersTable = (payload) => {
  return {
    type: users.SET_ACCOUNT_SELECTED_USERS_TABLE,
    payload,
  };
};

export const setUserTypes = (payload) => {
  return {
    type: users.SET_USER_TYPES,
    payload,
  };
};

export const getUserExtraDataAsync = () => {
  return (dispatch, getState) => {
    let user = getState().users.user;

    GETData(`users/users/extradata/${user.idUser}`, "GET")
      .then((response) => {
        if (response !== null) {
          dispatch(setUser({ ...user, ...response }));
        }
      })
      .catch((response) => console.error(response));
  };
};

export const getUserImageAsync = () => {
  return (dispatch, getState) => {
    let user = getState().users.user;

    GETData(`users/users/${user.idUser}`, "GET")
      .then((response) => {
        dispatch(setUser({ ...user, image: response.image }));
      })
      .catch((response) => console.error(response));
  };
};

export const updateInfoUserAsync = (payload) => {
  return (dispatch, getState) => {
    const user = getState().users.user;

    dispatch(setSavingInfoUser(true));

    SETData(`usersdev/users/${user.idUser}`, "PATCH", payload)
      .then(() => {
        dispatch(
          setUser({
            ...user,
            ...payload,
          })
        );
      })
      .catch((response) => console.error(response))
      .finally(() => {dispatch(setSavingInfoUser(false))});
  };
};

export const loadUserJobsAsync = () => {
  return (dispatch) => {
    GETData(`users/jobs`, "GET")
      .then((response) => {
        if (response !== null) {
          dispatch(setUserJobs(response));
        }
      })
      .catch((response) => console.error(response));
  };
};

export const loadUserChannelsAsync = () => {
  return (dispatch) => {
    GETData(`users/users/channels`, "GET")
      .then((response) => {
        if (response !== null) {
          dispatch(setUserChannels(response));
        }
      })
      .catch((response) => console.error(response));
  };
};

export const loadUserPersonalInterestsAsync = () => {
  return (dispatch) => {
    GETData(`users/users/interests`, "GET")
      .then((response) => {
        if (response !== null) {
          dispatch(setUserPersonalInterests(response));
        }
      })
      .catch((response) => console.error(response));
  };
};

export const createUserAsync = (payload) => {
  return (dispatch, getState) => {
    const user = getState().users.user;
    const language = getState().shared.language;

    dispatch(setLoadingNewUser(true));

    SETData(`users/users/create/${user.idUser}`, "POST", payload)
      .then((response) => {
        if (response !== null) {
          Alert.success(language.alert_new_user_popup_success);
          dispatch(setShowCreateUserModal(0));
          dispatch(loadUsersByAccountAsync());
          dispatch(setShowUsersListModal(true));
        } else Alert.error(language.alert_new_user_popup_failed);
      })
      .catch((response) => console.error(response))
      .finally(() => dispatch(setLoadingNewUser(false)));
  };
};

export const loadUserBusinessAsync = () => {
  return (dispatch,  getState) => {
    const user = getState().users.user;
    GETData(`usersdev/businessUnits/${user.userType.idUserType}`, "GET")
      .then((response) => {
        if (response !== null) {
          dispatch(setBusinessUnits(response));
        }
      })
      .catch((response) => console.error(response));
  };
};

export const loadUsersByAccountAsync = () => {
  return (dispatch, getState) => {
    const accountId = getState().accounts.account?.idAccount;

    dispatch(setUsersAccountLoading(true));

    GETData(`users/users/account/${accountId}`, "GET")
      .then((response) => {
        if (response !== null) dispatch(setUsersAccount(response));
        else dispatch(setUsersAccount([]));
      })
      .catch((response) => console.error(response))
      .finally(() => dispatch(setUsersAccountLoading(false)));
  };
};

export const createUserPreferenceInfoAsync = (payload) => {
  return (dispatch, getState) => {
    const language = getState().shared.language;

    SETData("users/users/preferencedata", "POST", payload)
      .then((response) => {
        if (response !== null) {
          Alert.success(language.alert_create_preference_info_user_success);
          dispatch(setShowModalUserPreference(true));
        } else Alert.error(language.alert_create_preference_info_user_error);
        dispatch(setShowModalUserPreference(false));
      })
      .catch((response) => console.error(response));
  };
};

export const getUserPreferenceInfoAsync = () => {
  return (dispatch, getState) => {
    let user = getState().users.user;

    GETData(`users/users/preferencedata/${user.idUser}`, "GET")
      .then((response) => {
        if (response !== null) {
          dispatch(setUserPreferenceInfo(response));
        }
      })
      .catch((response) => console.error(response));
  };
};

export const generateUserApiTokenAsync = () => {
  return (dispatch, getState) => {
    const user = getState().users.user;
    let language = getState().shared.language;

    const metadata = {
      id_user: user.idUser,
      email: user.email,
      credential: "JNKDind0348NJF38HSj739hn93h87B37IOJN0hB73B8h3uUJNh8",
    };

    SETData("token/create", "POST", metadata, url_api)
      .then((response) => {
        if (response != null) {
          dispatch(getUserApiTokenAsync());
        }
      })
      .catch((response) => {
        console.error(response);
        Alert.error(language.alert_generate_token_api_error);
      });
  };
};

export const getUserApiTokenAsync = () => {
  return (dispatch, getState) => {
    let user = getState().users.user;

        GETData(`users/users/tokenapi/${user.idUser}`, 'GET')
            .then((response) => {
                if (response!== null) {dispatch(setUserTokenApi(response?.token))}
            })
            .catch(response => console.error(response));
    }
}

export const loadUsersAsync = (payload = {}) => {
  return async (dispatch, getState) => {
      dispatch(setListUsersLoading(true));
      try {
          const { id_services = [], search, sortOrder, sortField, page } = payload;
          const requestBody = {
              id_services: Array.isArray(id_services) ? id_services : [],
              page: page,
              ...(search && { search }),
              ...(sortOrder && { sortOrder }),
              ...(sortField && { sortField }),
          };
          const data = await SETData("usersdev/users/table", "POST", requestBody);

          dispatch(setTotalPagesUsers(data.message.totalPages));
          dispatch(setTotalUsers(data.message.totalUsers));
          if (data && data.message && Array.isArray(data.message.users)) {
              const transformedUsers = data.message.users.map((user) => ({
                  idUser: user.idUser,
                  idAccount: user.services[0]?.account?.idAccount || 0,
                  nameAccount: user.services[0]?.account?.name || "Unknown",
                  servicesIds: user.services.map((service) => service.idService),
                  name: `${user.name} ${user.lastname}`,
                  area: user.businessUnit?.businessUnitName || "Unknown",
                  rol: user.userType?.userTypeDescription || "Unknown",
                  idRol: user.userType?.idUserType || 0,
                  email: user.email,
                  phone: user.phone,
                  recordDate: new Date(user.lastActivity).toLocaleDateString(),
              }));
              dispatch(setUsers(transformedUsers));
          } else {
              dispatch(setUsers([]));
              console.warn("No valid users array in response");
          }
      } catch (error) {
          console.error("Error loading users:", error);
          dispatch(setUsers([]));
      }
      dispatch(setListUsersLoading(false));
  };
};

export const getUserTypesAsync = (payload) => {
  return (dispatch, getState) => {
      GETData(`usersdev/userTypes/${payload}`, 'GET')
          .then((response) => {
              if (response !== null) {
                  if (payload === 17) {
                      const filteredResponse = response.filter(user => user.idUserType === 17);
                      dispatch(setUsersTypeSelect(filteredResponse));
                  } else {
                      dispatch(setUsersTypeSelect(response));
                  }
              }
          })
          .catch(response => console.error(response));
  };
};



//TODO: 
export const getUserRegisteredAsync = (payload) => {
  return (dispatch, getState) => {
    let usersToInvite = getState().users.usersToInvite;
    const language = getState().shared.language;
    dispatch(setUserRegisteredLoading(true));
    SETData(`usersdev/invite/exists`, "POST", payload)
      .then((response) => {
        const result = response?.user || {};
        const existsInUsersToInvite = (user) =>
          usersToInvite.some(
            (u) =>
              (user.email && u.email === user.email) ||
              (user.phone && u.phone === user.phone)
          );
        let newUser;
        if (response.user === null) {
          newUser = {
            ...payload,
            userExist: false,
            userType: { idUserType: 17, description: 'Colaborador' },
          };
        } else {
          newUser = { ...result, userExist: true}
        }
        if (newUser && !existsInUsersToInvite(newUser)) {
          dispatch(setUsersToInvite([...usersToInvite, newUser]));
        } else {
          Alert.error(language["user_alert_duplicate_user_to_invite"]);
        }
      })
      .catch((response) => console.error(response))
      .finally(() => {
        dispatch(setUserRegisteredLoading(false));
      });
  };
};

//TODO: 
export const loadInviteUserAsync = () => {
  return (dispatch, getState) => {
    let usersToInvite = getState().users.usersToInvite;
    const userId = getState().users.user.idUser;
    const serviceIds = getState().users.userServicesSelected.newServices;
    const language = getState().shared.language;
    const users = usersToInvite.map((user) => ({
      phone: user.phone,
      email: user.email,
      idTypeUser: user.userType.idUserType,
    }));
    const metaData = {
      users: users.length ? users : [],
      idServices: serviceIds.length ? serviceIds : [],
      idUserInviting: userId,
    };
    dispatch(setInfoInviteSuccessModal(metaData));
    dispatch(setLoadingNewUser(true));
    SETData(`usersdev/invite`, "POST", metaData)
      .then((response) => {
        if (response !== null) {
          dispatch(setShowInviteSuccessModal(true));
          dispatch(setUsersToInvite([]));
          dispatch(setUserServicesSelected({ key: "newServices", value: [] }));
          Alert.success(language["alert_new_user_popup_success"]);
        } else {
          Alert.error(language["alert_new_user_popup_failed"]);
        }
      })
      .catch((response) => console.error(response))
      .finally(() => dispatch(setLoadingNewUser(false)));
  };
};


//TODO: 
export const loadUserTypesAsync = () => {
    return (dispatch, getState) => {
        const userType = getState().users.user?.userType?.idUserType;
        
        GETData(`usersdev/userTypes/${userType}`, 'GET')
            .then((response) => {
                if (response!== null) {dispatch(setUserTypes(response))}
            })
            .catch(response => console.error(response));
    }
};

//TODO: 
export const updateUserAsync = (payload) => {
    return async (dispatch, getState) => {
      const language = getState().shared.language;
      const data = {
          idUserType: payload.user.idUserType || payload.user.idRol,
          servicesIds: payload.user.servicesIds || []
      };
      dispatch(setLoadingUpdateUser(true));
      await SETData(`usersdev/users/update/${payload.user.idUser}`, "PUT", data)
        .then((response) => {
            if (response !== null) {
                Alert.success(language['alert_edit_user_popup_success']);
                dispatch(setUserServicesSelected({ key: 'updatedServices', value: [] }));
                // payload.navigation.navigate('Users');
                // dispatch(loadUsersAsync());
            } else {
                Alert.error(language['alert_edit_user_popup_failed']);
            }
        })
        .catch(response => console.error(response))
        .finally(() => { dispatch(setLoadingUpdateUser(false)) });
    };
  };
//TODO: 
export const deleteUserAsync = (idUser, idAccount) => {
  return (dispatch, getState) => {
    const selectedAccount = getState().users.accountSelectedUsersTable;
    const accounts = getState().accounts.totalAccounts;
    const metaData = { idUser, idAccount };

    const accountFound = accounts.find(
      (currentAccount) => currentAccount.idAccount === selectedAccount
    );
    const idServices = accountFound
      ? accountFound.services.map((currentService) => currentService.idService)
      : [];

    dispatch(setLoadingDeleteUser(true));

        dispatch(setLoadingDeleteUser(true));

        SETData(`usersdev/users/removeAccount`, "POST", metaData)
            .then((response) => {
                if (response !== null) {
                    dispatch(loadUsersAsync({
                        id_services: idServices
                    }));
                } else {
                    console.error('Error: No se pudo desasociar al usuario');
                }
            })
            .catch((error) => {
                console.error('Error removing user account:', error);
            })
            .finally(() => {
                dispatch(setLoadingDeleteUser(false));
            });
    };
};
