import React, { Fragment, useState } from "react";
import { connect } from "react-redux";

import * as sharedActions from "../../store/actions/Shared";
import * as geniusActions from "../../store/actions/Genius";
import * as themeActions from "../../store/actions/Theme";

import { Popover } from "@idui/react-popover";

const ExportReport = ({ htmlUrl, excelUrl, ...props }) => {
  const [show, setShow] = useState(false);
  const [focus, setFocus] = useState(false);
  const [fileName, setFileName] = useState("");
  const { theme } = props;

  return (
    <Popover
      lazy
      fitMaxHeightToBounds
      fitMaxWidthToBounds
      trigger={"click"}
      placement={"bottomRight"}
      className={`p-0 rounded-16 surface-bg-surface border-fill-medium ${theme}`}
      withArrow={false}
      isOpen={show}
      width="400px"
      height="220px"
      offset={[0, 0]}
      openingAnimationTranslateDistance={0}
      onChangeOpen={(state) => {
        props.setShowBlackScreen(state);
        setShow(state);
        setFocus(state);
      }}
      animation={{
        animate: { opacity: 1 },
        exit: { opacity: 0, transition: { duration: 0.1 } },
        initial: { opacity: 0 },
      }}
      content={
        <Fragment>
          <div className="column-between h-100 w-100 p-3">
            <div className="row-start">
              <p className={`content-default-primary heading-h6-bold ${theme}`}>
                {props.language.genius_download_file_report}
              </p>
            </div>
            <div className="row-start align-items-center w-100 py-1">
              <div className="input-container">
                <label
                  htmlFor="document-name"
                  className={`label-small-medium content-default-primary ${theme}`}
                >
                  Nombre del documento
                </label>
                <input
                  value={fileName}
                  onChange={({ target }) => setFileName(target.value)}
                  placeholder="Nombre del documento"
                  type="text"
                  autoFocus={true}
                  className={`input-export-report label-small-medium content-default-secondary ${theme}`}
                  style={{ width: "100%" }}
                />
              </div>
            </div>
            <div className="row-center my-2">
              <button
                onClick={() => {
                  props.downloadReportsAsync({
                    url: htmlUrl,
                    filename: fileName !== "" ? `${fileName}.html` : "",
                  });
                  setFileName("");
                  setShow(false);
                  props.setShowBlackScreen(false);
                }}
                className={`me-${
                  excelUrl !== "" ? 3 : 0
                } rounded-16 py-2 px-4 button-fill-brand button-content-equal ${theme}`}
                style={{ minWidth: 155 }}
              >
                <div className="row-start align-items-center">
                  <i className="fal fa-file-code txt-10"></i>
                  <div
                    className={`ms-2 pe-pointer label-small-regular button-content-equal ${theme}`}
                  >
                    {props.language.genius_download_html_report}
                  </div>
                </div>
              </button>
              {excelUrl !== "" ? (
                <button
                  onClick={() => {
                    props.downloadReportsAsync({
                      url: excelUrl,
                      filename: fileName !== "" ? `${fileName}.xlsx` : "",
                    });
                    setFileName("");
                    setShow(false);
                    props.setShowBlackScreen(false);
                  }}
                  className={`rounded-16 py-2 px-4 button-fill-brand button-content-equal ${theme}`}
                  style={{ minWidth: 155 }}
                >
                  <div className="row-start align-items-center">
                    <i className="fal fa-file-excel txt-10"></i>
                    <div
                      className={`ms-2 pe-pointer label-small-regular button-content-equal ${theme}`}
                    >
                      {props.language.genius_download_excel_report}
                    </div>
                  </div>
                </button>
              ) : null}
            </div>
          </div>
        </Fragment>
      }
    >
      {!focus ? (
        <div
          className={`pe-pointer rounded-30 py-2 pe-3 surface-bg-on-surface ${theme}`}
        >
          <div className="row-start align-items-center h-100">
            <div className="row-center align-items-center min-width-32">
              <i
                className={`fa-light fa-arrow-down txt-8 content-default-tertiary ${theme}`}
              />
            </div>
            <span
              className={`txt-overflow-unset pe-pointer label-small-regular content-default-primary ${theme}`}
            >
              {props.language.genius_download_report}
            </span>
          </div>
        </div>
      ) : (
        <div
          className="row-center zindex-7 pe-pointer align-items-center bg-gray-800 rounded-circle"
          style={{ height: 30, width: 30 }}
        >
          <i
            className={`fa-light fa-circle-xmark txt-10 content-default-tertiary ${theme}`}
          />
        </div>
      )}
    </Popover>
  );
};

const mapStateToProps = (state) => ({
  language: state.shared.language,
  theme: state.theme.theme,
});

const mapDispatchToProps = (dispatch) => ({
  setShowBlackScreen: (payload) =>
    dispatch(sharedActions.setShowBlackScreen(payload)),
  downloadReportsAsync: (payload) =>
    dispatch(geniusActions.downloadReportsAsync(payload)),
  setTheme: (theme) => dispatch(themeActions.setTheme(theme)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExportReport);
