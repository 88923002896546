import React from "react"

import ThemedImage from "../utils/ThemedImage"

export const stepsGraphPeriod = (language) => {
  return ([
    {
      content: (
        <div className="row-center align-items-center w-100 mt-3" style={{ height: 152, width: 300 }}>
          <ThemedImage imageName={'step1-graph-period.png'}/>
        </div>
      ),
      title: language.onboarding_graph_period_title_step1,
      description: language.onboarding_graph_period_description_step1,
      placement: "auto",      
      target: "#date_range_picker",
      disableBeacon: true,
      steps: 5,
      disableOverlayClose: true,
      spotlightClicks: true,
      hideFooter: true,
      hideFooterTitle: "Haz click en el botón!",
      pulseEffect: true
    },
    {      
      title: language.onboarding_graph_period_title_step2,
      description: language.onboarding_graph_period_description_step2,
      placement: "auto",
      target: "#date_interval",
      disableBeacon: true,
      steps: 5,
      disableOverlayClose: true,
      hideBackButton: true,
    },
    {      
      title: language.onboarding_graph_period_title_step3,
      description: language.onboarding_graph_period_description_step3,
      placement: "auto",
      target: "#date_interval",
      disableBeacon: true,
      steps: 5,
      disableOverlayClose: true,
    },
    {      
      title: language.onboarding_graph_period_title_step4,
      description: language.onboarding_graph_period_description_step4,
      placement: "auto",      
      target: "#date_interval",
      disableBeacon: true,
      steps: 5,
      disableOverlayClose: true,
    },
    {     
      title: language.onboarding_graph_period_title_step5,
      description: language.onboarding_graph_period_description_step5,
      placement: "auto",
      target: "#date_interval",
      disableBeacon: true,
      steps: 5,
      disableOverlayClose: true,
    },
  ])
}
