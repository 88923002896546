import React, { Fragment, useEffect, useRef, useState } from "react";
import { connect } from 'react-redux';

import * as servicesActions from '../../../../store/actions/Services';
import * as plusActions from '../../../../store/actions/Plus';

import Header from "../../../shared/Header";
import GraphPower from "./GraphPower";
import ServicesPlus from "../shared/ServicesContainer";
import CreateDevice from "./CreateDevice"

import { Animation } from 'rsuite';

import moment from "moment";

import { useWindowSize } from '../../../shared/utilities/useWindowSize';

import { Link } from 'react-router-dom';

import empty_modules_plus from "../../../../imgs/empty-data-device.svg";
import empty_modules_plus_light from "../../../../imgs/empty-data-device-light.svg";

const DataAppliances = ({ value, property, unit, theme }) => {
    return (
        <div className="row-between w-100">
            <div className={`content-default-secondary label-small-medium ${theme}`}>{property}</div>
            <div className="row-end align-items-center">
                <div className={`content-default-secondary label-small-semibold ${theme}`}>{value}</div>
                <div className={`content-default-secondary label-small-semibold ${theme} ${unit ? 'ms-2' : 'ms-0'}`}>{unit}</div>
            </div>
        </div>
    )
}


const RenderCardEmpty = ({ language, theme }) => {
    return (
        <div className={`column-between w-100 align-items-center surface-bg-surface rounded-16 p-3 ${theme}`} style={{ height: 220 }}>
            { theme === 'dark' ? 
                <img src={empty_modules_plus} alt="empty_radar_data" style={{ width: 144, height: 144 }} /> :
                <img src={empty_modules_plus_light} alt="empty_radar_data" style={{ width: 144, height: 144 }} />
            }
            <div className={`row-center mx-5 mt-2 align-items-center text-center label-small-semibold content-default-secondary ${theme}`}>
                {language.tag_new_tag_text_no_devices}
            </div>
            <div className={`row-center text-center mt-1 text-wrap label-small-regular content-default-tertiary ${theme}`}>
                {language.tag_new_tag_text_no_devices_description}
            </div>
        </div>
    )
};


const Devices = (props) => {

    const [showInfoDevice, setShowInfoDevice] = useState(false);

    const [applianceSelected, setApplianceSelected] = useState(false);

    const isSelected = props.deviceSelected === props.item?.id_appliance;

    const theme = props.theme;

    useEffect(() => {
        if (applianceSelected) {
            props.onChange(props.item?.id_appliance);
            setApplianceSelected(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [applianceSelected]);

    return (
        <Fragment>
            <div className={`row-between align-items-center w-100 pe-pointer mt-3 px-2 py-3 ${showInfoDevice ? 'rounded-top-8' : 'rounded-8'} ${showInfoDevice || isSelected ? 'surface-bg-on-surface' : ''} ${theme}`}
                onClick={() => setApplianceSelected(true)}>
                <div className="row-start align-items-center">
                    {/* <div className="column-center rounded-8 bg-gray-500 p-2" style={{ width: 40, height: 40 }}>
                        <i className="fa-regular fa-plug-circle-bolt txt-green-50 txt-12"></i>
                    </div> */}
                    <div className={`content-default-primary label-small-medium pe-pointer ${theme}`}>{props.item.custom_name}</div>
                </div>
                <i className={`fa-regular fa-circle-chevron-right txt-10 pe-pointer ${isSelected ? "content-color-green" : "content-default-secondary"} ${!showInfoDevice ? 'device-detail-open' : 'device-detail-close'} ${theme}`} onClick={() => setShowInfoDevice(!showInfoDevice)}></i>
            </div>
            <Animation.Collapse in={showInfoDevice} dimension={'height'}>
                <div className={`column-start surface-bg-on-surface w-100 rounded-bottom-8 p-2 ${theme}`}>
                    {props.item.id_tag ?
                        <Fragment>
                            <DataAppliances value={props.item.active_cons.toFixed(4)} property={props.language.tag_new_tag_text_average_comsuption} unit={"Wh"} theme={theme} />
                            <DataAppliances value={props.item.duration} property={props.language.tag_new_tag_text_duration} unit={"s"} theme={theme} />
                        </Fragment>
                        :
                        <div className="row-between w-100">
                            <div className={`content-default-secondary label-small-medium ${theme}`}>{props.language.tag_new_tag_text_no_tag}</div>
                        </div>
                    }
                </div>
            </Animation.Collapse>
        </Fragment>
    );
}

const TagManager = (props) => {

    const { theme } = props;

    const size = useWindowSize();

    const graphContiner = useRef(null);

    const [graphContinerWidth, setGraphContinerWidth] = useState(0);
    const [showCreateDevice, setShowCreateDevice] = useState(false);
    const [deviceSelected, setDeviceSelected] = useState(null);
    const [search, _setSearch] = useState(''); // eslint-disable-line no-unused-vars
    let account = props.account;

    useEffect(() => setGraphContinerWidth(graphContiner.current.getBoundingClientRect().width), [size]);

    useEffect(() => {
        if (!props.activeServicesPlus?.filter(item => item?.idAccount === props.account?.idAccount).length) {
            account && props.loadServicesAsync();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account]);

    useEffect(() => {
        if (props.activeServicesPlus.length) props.loadAppliances();
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [props.activeServicesPlus]);

    useEffect(() => {
        if (props.appliances.length) {
            if (deviceSelected) props.loadTagRecords(deviceSelected);
        }
        else {
            props.setTagRecords([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.appliances, deviceSelected, props.activeServicesPlus]);

    useEffect(() => {
        if (props.appliances.length && !deviceSelected) setDeviceSelected(props.appliances[0]?.id_appliance);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.appliances])


    return (
        <section className="column-start align-items-center min-vh-100 w-100">
            <Header moduleName={props.language.tag_manager_title_main_dashboard} backToButton={true} />
            <div className="column-center align-items-center flex-grow-1 w-100">
                <ServicesPlus />
                <div className="row-between align-items-start w-100 p-3">
                    <div className={`column-start align-items-start surface-bg-surface border-fill-medium rounded-16 px-3 py-4 ${theme}`} style={{ width: 300, minWidth: 300, maxHeight: 516, height: 'fit-content' }}>
                        <div className={`heading-h6-semibold content-default-primary row-center w-100 mb-3 ${theme}`}>{props.language.tag_manager_title_devices}</div>
                        {/* <div className={`row-start w-100 align-items-center surface-bg-surface p-2 rounded-8 mb-3 ${theme}`}>
                            <input type="text" className={`label-small-medium content-default-secondary w-100 ${theme}`} placeholder={props.language.services_input_label_search} value={search} onChange={({ target }) => setSearch(target.value)} />
                            <i className={`fa-regular fa-search txt-8 content-default-secondary ${theme}`} />
                        </div> */}
                        <div className="w-100" style={{ overflowX: "hidden", overflowY: "auto", maxHeight: 330 }}>
                            {props.appliances.filter(item => item?.custom_name?.toLowerCase()?.includes(search.toLowerCase()) || search === '').length ? props.appliances.filter(item => item?.custom_name?.toLowerCase()?.includes(search.toLowerCase()) || search === '').map((item, index) =>
                                <Devices key={index} item={item} deviceSelected={deviceSelected} onChange={value => setDeviceSelected(value)} language={props.language} theme={theme}/>
                            ) : <RenderCardEmpty language={props.language} theme={theme}/>}
                        </div>
                        <div className="w-100 row-center align-items-center">
                            <button className={`button-fill-brand rounded-pill mt-3 ${theme}`} style={{ height: 32 }} onClick={() => { setShowCreateDevice(true); }}>
                                <div className={`label-small-regular button-content-equal px-3 ${theme}`}>{props.language.tag_new_device_header_label_device}</div>
                            </button>
                        </div>
                    </div>
                    <div ref={graphContiner} className="column-start align-items-start" style={{ width: "calc(100% - 300px)" }}>
                        <div className="column-start ps-3 align-items-center w-100">
                            <div className={`column-start surface-bg-surface border-fill-medium rounded-16 w-100 ${theme}`}>
                                <GraphPower graphContinerWidth={graphContinerWidth} data={props.tagRecords} />
                            </div>
                        </div>
                        <div className="column-start ps-3 align-items-center w-100 mt-3">
                            <div className={`column-start surface-bg-surface border-fill-medium rounded-16 w-100 p-3 ${theme}`}>
                                {props.tagRecords.length ?
                                    <Fragment>
                                        <div className={`w-100 row-between rounded-12 surface-bg-on-surface-alpha py-1 mb-3 ${theme}`}>
                                            <div className={`col-2 content-default-secondary border-fill-light-right label-small-medium text-wrap text-center py-3 ${theme}`}>
                                                {props.language.tag_manager_table_header_name}
                                            </div>
                                            <div className={`col-2 content-default-secondary border-fill-light-right label-small-medium text-wrap text-center py-3 ${theme}`}>
                                                {props.language.tag_manager_table_header_create_at}
                                            </div>
                                            <div className={`col-2 content-default-secondary border-fill-light-right label-small-medium text-wrap text-center py-3 ${theme}`}>
                                                {props.language.tag_manager_table_header_active_energy}
                                            </div>
                                            <div className={`col-2 content-default-secondary border-fill-light-right label-small-medium text-wrap text-center py-3 ${theme}`}>
                                                {props.language.tag_manager_table_header_active_power}
                                            </div>
                                            <div className={`col-2 content-default-secondary border-fill-light-right label-small-medium text-wrap text-center py-3 ${theme}`}>
                                                {props.language.tag_manager_table_header_reactive_energy}
                                            </div>
                                            <div className={`col-2 content-default-secondary label-small-medium text-wrap text-center py-3 ${theme}`}>
                                                {props.language.tag_manager_table_header_reactive_power}
                                            </div>
                                        </div>
                                        {props.tagRecords.map((rowData, index) =>
                                        <Fragment>
                                            <div className={`w-100 row-between ${index % 2 === 0 && "surface-on-surface-hover"} rounded-pill py-3 mb-1 ${theme}`}>
                                                <div className={`col-2 content-default-primary label-medium-medium text-wrap text-center ${theme}`}>
                                                    {`Tag 00${rowData?.id_tag}`}
                                                </div>
                                                <div className={`col-2 content-default-primary label-small-medium text-wrap text-center ${theme}`}>
                                                    {moment(rowData.create_timestamp).format('DD/MM/YYYY ')} <span className={`content-default-secondary ${theme}`}>{moment(rowData.create_timestamp).format('HH:mm:ss')}</span>
                                                </div>
                                                <div className={`col-2 ${rowData.active_cons ? 'content-default-primary label-small-semibold' : 'content-default-secondary label-small-medium'}  text-wrap text-center ${theme}`}>
                                                    {rowData.active_cons ? rowData.active_cons.toFixed(4) + " Wh" : rowData.processed ? props.language.tag_table_tag_label_processed : props.language.tag_table_tag_label_procesing}
                                                </div>
                                                <div className={`col-2 ${rowData.active_power ? 'content-default-primary label-small-semibold' : 'content-default-secondary label-small-medium'}  text-wrap text-center ${theme}`}>
                                                    {rowData.active_power ? rowData.active_power.toFixed(2) + " W" : rowData.processed ? props.language.tag_table_tag_label_processed : props.language.tag_table_tag_label_procesing}
                                                </div>
                                                <div className={`col-2 ${rowData.reactive_cons ? 'content-default-primary label-small-semibold' : 'content-default-secondary label-small-medium'}  text-wrap text-center ${theme}`}>
                                                    {rowData.reactive_cons ? rowData.reactive_cons.toFixed(4) + " VArh" : rowData.processed ? props.language.tag_table_tag_label_processed : props.language.tag_table_tag_label_procesing}
                                                </div>
                                                <div className={`col-2 ${rowData.reactive_power ? 'content-default-primary label-small-semibold' : 'content-default-secondary label-small-medium'}  text-wrap text-center ${theme}`}>
                                                    {rowData.reactive_power ? rowData.reactive_power.toFixed(2) + " VAr" : rowData.processed ? props.language.tag_table_tag_label_processed : props.language.tag_table_tag_label_procesing}
                                                </div>
                                            </div>
                                        </Fragment>)
                                        }
                                    </Fragment> :
                                    <Fragment>
                                        <div className={`column-start surface-bg-surface rounded-8 w-100 p-2 ${theme}`} style={{ height: 130 }}>
                                            <div className="column-center align-items-center w-100 h-100">
                                                <div className={`content-default-secondary label-small-semibold text-center my-3 ${theme}`}>{props.language.tag_manager_table_title_empty_data}</div>
                                                <div className={`col-3 content-default-tertiary label-small-regular text-wrap text-center ${theme}`}>{props.language.tag_manager_table_phrase_empty_data}</div>
                                            </div>
                                        </div>
                                        <div className="row-center w-100">
                                            <Link to="/plus">
                                                <button className={`mt-3 button-fill-brand button-content-equal label-small-regular py-2 rounded-pill py-2 px-4 ${theme}`}>
                                                    <i className="fa-light fa-tag txt-8 me-2" />
                                                    {props.language.tag_manager_button_tag}
                                                </button>
                                            </Link>
                                        </div>
                                    </Fragment>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <CreateDevice show={showCreateDevice} setShow={setShowCreateDevice} />
        </section>
    );
}

const mapStateToProps = (state) => ({
    activeServicesPlus: state.services.activeServicesPlus,
    account: state.accounts.account,
    dataGraph: state.plus.dataGraph,
    appliances: state.plus.appliances,
    tagRecords: state.plus.tagRecords,
    language: state.shared.language,
    theme: state.theme.theme,
});

const mapDispatchToProps = dispatch => ({
    setActiveServicesPlus: (payload) => dispatch(servicesActions.setActiveServicesPlus(payload)),
    setActiveServicesEnergy: (payload) => dispatch(servicesActions.setActiveServicesEnergy(payload)),
    resetData: () => dispatch(plusActions.resetData()),
    loadServicesAsync: () => dispatch(servicesActions.loadServicesAsync()),
    loadAppliances: () => dispatch(plusActions.loadAppliances()),
    loadTagRecords: (payload) => dispatch(plusActions.loadTagRecords(payload)),
    setTagRecords: (payload) => dispatch(plusActions.setTagRecords(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TagManager);