import React from 'react';

import ThemedImage from '../utils/ThemedImage';

export const stepsGraphCalendar = (language) => {
    return ([
        {
            content:
                <div className='row-center align-items-center w-100 mt-3' style={{ height: 122, width: 300 }}>
                    <ThemedImage imageName={'step1-graph-period.png'}/>
                </div>,
            title: language.onboarding_graph_calendar_title_step1,
            description: language.onboarding_graph_calendar_description_step1,
            placement: "auto",
            target: "#date_calendar_picker",
            disableBeacon: true,
            steps: 1           
        }       
    ])
};