import React from 'react';
import { connect } from 'react-redux';

import * as paysActions from '../../../store/actions/Pays';

import { themes } from '../../../utils/enums';

import PseDark from '../../../imgs/pse-logo-dark.svg'
import PseLight from '../../../imgs/pse-logo-light.svg'

const PaymentMethod = (props) => {

    let paymentMethods = props.paymentMethods;
    let theme = props.theme;

    return (
        <div className='flex-grow-1 column-start align-items-start py-2 px-4 w-100'>
            {
                paymentMethods.map(method =>
                    <div key={method.value} className={`col-12 row-between align-items-center my-2 py-2 ps-2 pe-3 surface-bg-on-surface rounded-pill pe-pointer ${theme}`} onClick={() => { props.setTypeComponent(method.value); props.setPaymentProvider(method.provider); }}>
                        <div className='row-start align-items-center'>
                            <div className={`row-center align-items-center p-1 surface-bg-on-surface-alpha rounded-pill ${theme}`} style={{ width: 40, height: 40 }}>
                                {
                                    method.image.type === 'icon' ?
                                        <i className={`${method.image.value} txt-12 content-default-tertiary pe-pointer ${theme}`}></i> :
                                        <img src={theme === themes.LIGHT ? PseLight : PseDark} alt="logo-pse" className='w-100 h-100' />
                                }
                            </div>
                            <div className='column-between h-100 ms-2 pe-pointer'>
                                <div className={`pb-1 label-medium-semibold content-default-primary pe-pointer ${theme}`}>{method.title}</div>
                                <div className={`label-small-regular content-default-secondary pe-pointer ${theme}`}>{method.description}</div>
                            </div>
                        </div>
                        <i className={`fa-light fa-chevron-right txt-8 content-default-primary pe-pointer ${theme}`}></i>
                    </div>
                )
            }
            <div className="column-start py-2 gap-3 w-100">
                <p className={`label-small-regular content-default-secondary ${theme}`} style={{ whiteSpace: 'wrap' }}>
                    { props.language.invoices_pay_first_advice_payment_method }
                </p>
                <p className={`label-small-regular content-default-secondary ${theme}`} style={{ whiteSpace: 'wrap' }}>
                    { props.language.invoices_pay_second_advice_payment_method }
                </p>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    invoicesToPay: state.invoices.invoicesToPay,
    user: state.users.user,
    language: state.shared.language,
    paymentMethods: state.pays.paymentMethods,
    theme: state.theme.theme,
})

const mapDispatchToProps = dispatch => ({
    setTypeComponent: (payload) => dispatch(paysActions.setTypeComponent(payload)),
    setPaymentProvider: (payload) => dispatch(paysActions.setPaymentProvider(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(PaymentMethod)