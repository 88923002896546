import React, { Fragment, useEffect } from "react";
import { connect } from 'react-redux';

import { VictoryChart, VictoryArea, VictoryPolarAxis, VictoryLabel, Rect, VictoryBar } from "victory";

import ContentLoader from "react-content-loader";

import * as plusActions from "../../../../store/actions/Plus";

import { Swiper, SwiperSlide } from 'swiper/react';

import empty_modules_plus from "../../../../imgs/empty-modules-plus.svg";
import empty_modules_plus_light from "../../../../imgs/empty-modules-plus-light.svg";

import { track } from "@amplitude/analytics-browser";

// import Tooltip from "../../../shared/utilities/Tooltip";

import { Link } from 'react-router-dom';

import _ from 'lodash';

const formatNumber = number => {
  if (Math.abs(number) >= 500) {
      number = (number / 1000).toFixed(2);
      return (`${number} k`)
  }
  else {
      return (`${(number).toFixed(2)} `);
  }
}

const BiPhaseGraph = (props) => {

  const theme = props.theme;

  const radarStyle = {
    ticks: {
      stroke: "transparent"
    },
    tickLabels: {
      fill: theme === 'dark' ? "var(--white)" : "var(--black)",
      fontSize: 8,
      fontFamily: "Inter"
    },
    grid: {
      stroke: theme === 'dark' ? "var(--white)" : "var(--black)",
      strokeWidth: 1,
      strokeDasharray: "none",
      opacity: 0.2
    },
    axis: {
      stroke: "transparent"
    }
  };

  const axisStyle = {
    axis: {
      stroke: "transparent"
    },
    grid: {
      stroke: theme === 'dark' ? "var(--white)" : "var(--black)"
    },
    tickLabels: {
      fill: theme === 'dark' ? "var(--white)" : "var(--black)",
      fontSize: 8,
      fontFamily: "Inter",
      padding: 15
    },
  };

  return (
    <VictoryChart
      polar
      height={140}
      width={200}
      startAngle={0}
      endAngle={180}
      padding={{ top: 15, left: 30, right: 30, bottom: 0 }}>
      <VictoryBar
        data={props.data}
        style={{ data: { fill: props?.label?.gradient } }} />
      <VictoryPolarAxis
        dependentAxis
        tickCount={3}
        tickFormat={(t) => `${formatNumber(t)}${props?.label?.unit}`}
        axisAngle={90}
        labelPlacement={"vertical"}
        axisLabelComponent={<VictoryLabel dy={20} />}
        tickLabelComponent={
          <VictoryLabel 
            dx={20} textAnchor={'middle'} verticalAnchor={'middle'} 
            backgroundStyle={{ fill: theme === 'dark' ? "var(--alpha-white-6)" :  "var(--alpha-black-6)"}} 
            backgroundComponent={<Rect style={{ fill: theme === 'dark' ? "var(--alpha-white-6)" :  "var(--alpha-black-6)" }} rx={6} />} 
            backgroundPadding={3}>
          </VictoryLabel>}
        style={radarStyle} />
      <VictoryPolarAxis
        labelPlacement="vertical"
        tickLabelComponent={
          <VictoryLabel 
            textAnchor={'middle'} verticalAnchor={'middle'} 
            backgroundPadding={{ top: 5, bottom: 5, left: 5, right: 5 }} 
            backgroundStyle={{ fill: theme === 'dark' ? "var(--alpha-white-6)" :  "var(--alpha-black-6)" }} 
            backgroundComponent={<Rect rx={8} ry={8}></Rect>} />} 
        style={axisStyle}
      />
    </VictoryChart>)
};

const ThreePhaseGraph = props => {
  const theme = props.theme;
  const radarStyle = {
    ticks: {
      stroke: "transparent"
    },
    tickLabels: {
      fill: theme === 'dark' ? "var(--white)" : "var(--black)",
      fontSize: 5,
      fontFamily: "Inter"
    },
    grid: {
      stroke: theme === 'dark' ? "var(--white)" : "var(--black)",
      strokeWidth: 1,
      strokeDasharray: "none",
      opacity: 0.2
    },
    axis: {
      stroke: "transparent"
    }
  };

  const axisStyle = {
    axis: {
      stroke: "transparent"
    },
    grid: {
      stroke: theme === 'dark' ? "var(--white)" : "var(--black)"
    },
    tickLabels: {
      fill: theme === 'dark' ? "var(--white)" : "var(--black)",
      fontSize: 5,
      fontFamily: "Inter",
      padding: 15
    },
  };

  return (
    <VictoryChart
      polar
      height={97}
      width={140}
      startAngle={90}
      endAngle={450}
      padding={{ top: 15, left: 20, right: 20, bottom: 10 }}>
      <VictoryArea
        data={props.data}
        interpolation={'catmullRom'}
        style={{ data: { fill: props?.label?.gradient, stroke: props?.label?.stroke, strokeWidth: 1 } }} />
      <VictoryPolarAxis
        dependentAxis
        tickCount={3}
        tickFormat={(t) => `${formatNumber(t)}${props?.label?.unit}`}
        labelPlacement={'vertical'}
        tickLabelComponent={
          <VictoryLabel 
            dx={20} textAnchor={'middle'} verticalAnchor={'middle'} 
            backgroundStyle={{ fill: theme === 'dark' ? "var(--alpha-white-6)" :  "var(--alpha-black-6)"}} 
            backgroundComponent={<Rect style={{ fill: theme === 'dark' ? "var(--alpha-white-6)" :  "var(--alpha-black-6)" }} rx={6} />} 
            backgroundPadding={3}>
          </VictoryLabel>}
        style={radarStyle} />
      <VictoryPolarAxis
        labelPlacement={'vertical'}
        style={axisStyle}
        tickLabelComponent={
          <VictoryLabel 
            textAnchor={'middle'} verticalAnchor={'middle'} 
            backgroundPadding={{ top: 5, bottom: 5, left: 5, right: 5 }} 
            backgroundStyle={{ fill: theme === 'dark' ? "var(--alpha-white-6)" :  "var(--alpha-black-6)" }} 
            backgroundComponent={<Rect rx={8} ry={8}></Rect>} />} />
    </VictoryChart>
  )
};

const IndicatorsLoading = props => {
  const {theme} = props;
  return (
  <div className="position-absolute w-100 h-100 left-0 top-0 zindex-1">
      <ContentLoader
          seed={2}
          backgroundColor= {theme === 'dark' ? "var(--gray-950)" : "var(--white)"}
          foregroundColor={theme === 'dark' ? "#323234" : "var(--gray-200)" }
          style={{ width: "100%", height: "100%" }}>
          <rect x="0" y="0" width="calc(100%)" height="calc(100%)" rx="16" ry="16"></rect>
      </ContentLoader>
  </div>)
};

const RenderCardEmpty = ({ language, theme }) => {
  return (
    <div className={`swiper-slider-container column-between align-items-center surface-bg-surface border-fill-medium rounded-16 p-3 ${theme}`} style={{ height: 200 }}>
      { theme === 'dark' ?
        <img src={empty_modules_plus} alt="empty_radar_data" style={{ width: 154, height: 154 }} /> :
        <img src={empty_modules_plus_light} alt="empty_radar_data" style={{ width: 154, height: 154 }} />  
      }
      <div className={`row-center mx-5 align-items-center content-default-secondary label-XSmall-regular ${theme}`}>
        {language.plus_graph_title_empty_data}
      </div>
    </div>
  )
};

const Radar = props => {

  let dataGraph = props.dataGraph;
  const { theme } = props;

  const states = [
    { type: 0, streaming: 'rms_voltage_', indicator: 'v', label: "Voltaje", gradient: `url(#radialGreen${theme !== 'dark' ? "Light" : ""}Gradient)`, stroke: "var(--brand-primary-500)", color: 'brand-primary-500', unit: "V", info: props.language.plus_radar_tooltip_info_voltage },
    { type: 1, streaming: 'rms_current_', indicator: 'c', label: "Corriente", gradient: `url(#radialPurple${theme !== 'dark' ? "Light" : ""}Gradient)`, stroke: `${theme !== 'dark' ? "var(--purple-600)" : "var(--purple-500)"}`, color: `${theme !== 'dark' ? "purple-600" : "purple-500"}`, unit: "A", info: props.language.plus_radar_tooltip_info_current },
    { type: 2, streaming: 'active_power_', indicator: 'p', label: "Potencia", gradient: `url(#radialBlue${theme !== 'dark' ? "Light" : ""}Gradient)`, stroke: `${theme !== 'dark' ? "var(--blue-600)" : "var(--blue-500)"}`, color: `${theme !== 'dark' ? "blue-600" : "blue-500"}`, unit: "W", info: props.language.plus_radar_tooltip_info_power }
  ];

  useEffect(() => {
    if (props.activeServicesPlus.length) props.loadEnergyQuality();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.activeServicesPlus]);

  let deviceActive = props.devices.filter(value => value?.id_service === props.activeServicesPlus[0]?.idService)[0];

  return (
    <Fragment>
      {!(props.loadingEnergyQuality) ? !props.energyQuality ?
        <div className={`position-relative column-between align-items-center surface-bg-surface border-fill-medium rounded-16 py-3 w-100 ${theme}`}>
          <p className="txt-phrase txt-8 txt-white txt-bold">{props.language.plus_radar_title}</p>
          <div
            id="prev-slide-quality"
            className="position-absolute left-0 row-center align-items-center h-100 pe-pointer"
            style={{ width: 40, height: 40, zIndex: 1 }}
            onClick={() => track('Quality widget clicked', {Widget_Back: 'activate'})}>
            {/* <i className="fa-regular fa-chevron-left txt-14 txt-white pe-pointer" /> */}
          </div>
          <div className="position-relative row-center align-items-center w-100 h-100">
            <Swiper
              loop={false}
              autoplay={{ delay: 5000000, disableOnInteraction: false }}
              navigation={{ nextEl: "#next-slide-quality", prevEl: "#prev-slide-quality" }}
              slidesPerView={1}>
              <SwiperSlide>
                <RenderCardEmpty language={props.language} theme={theme}/>
              </SwiperSlide>
            </Swiper>
          </div>
          <div
            id="next-slide-quality"
            className="position-absolute row-center align-items-center pe-pointer right-0"
            style={{ width: 40, height: 40 }}
            onClick={() => track('Quality widget clicked', {Widget_Forward: 'activate'})}>
            {/* <i className="fa-regular fa-chevron-right txt-14 txt-white pe-pointer" /> */}
          </div>
        </div> :
        <div className={`position-relative column-between align-items-center surface-bg-surface border-fill-medium rounded-16 py-3 w-100 ${theme}`}>
          <div className="position-relative row-center align-items-center w-100 h-100">
            <div
              id="prev-slide-quality"
              className="position-absolute left-0 row-center align-items-center pe-pointer zindex-2"
              style={{ width: 40, height: 40 }}
              onClick={() => track('Quality widget clicked', {Widget_Back: 'activate'})}>
              {/* <i className="fa-regular fa-chevron-left txt-14 txt-white pe-pointer" /> */}
            </div>
            <div className="position-relative w-100">
              {
                !props.openQualityEnergy ?
                  <Link to="/plus/quality">
                    <div onClick={() => track('Quality opened')} className="position-absolute right-0 zindex-2 row-center me-3">
                      <i className={`position-absolute px-2 py-1 rounded-pill border-fill-medium surface-bg-on-surface-alpha right-0 fal fa-chevron-right content-default-primary txt-10 pe-pointer ${theme}`}></i>
                    </div>
                  </Link> : null
              }
              <Swiper
                loop={false}
                autoplay={{ delay: 5000, disableOnInteraction: false }}
                navigation={{ nextEl: "#next-slide-quality", prevEl: "#prev-slide-quality" }}
                slidesPerView={1}>
                {[states[props.indexVariable]].map((state, index) =>
                  <SwiperSlide key={index}>
                    <div className={`row-${!props.openQualityEnergy ? 'between' : 'center'} align-items-center w-100 px-3`}>
                      <div className="row-center mt-1">
                        {/* <Tooltip text={state?.info} /> */}
                        <p className={`label-medium-semibold content-default-primary ms-0 ${theme}`}>{state.label}</p>
                      </div>
                    </div>
                    {deviceActive?.phases === 3 ?
                      <ThreePhaseGraph
                        data={[
                          { x: "L1", y: dataGraph.length ? _.last(dataGraph)[`${state?.streaming}1`] : props.energyQuality[`${state?.indicator}1`] },
                          { x: "L2", y: dataGraph.length ? _.last(dataGraph)[`${state?.streaming}2`] : props.energyQuality[`${state?.indicator}2`] },
                          { x: "L3", y: dataGraph.length ? _.last(dataGraph)[`${state?.streaming}3`] : props.energyQuality[`${state?.indicator}3`] },
                        ]}
                        label={states[props.indexVariable]}
                        theme={theme}
                      /> :
                      <BiPhaseGraph
                        data={[
                          { x: "L2", y: dataGraph.length ? _.last(dataGraph)[`${state?.streaming}2`] : props.energyQuality[`${state?.indicator}2`] },
                          { x: "L1", y: dataGraph.length ? _.last(dataGraph)[`${state.streaming}1`] : props.energyQuality[`${state?.indicator}1`] }
                        ]}
                        label={states[props.indexVariable]}
                        theme={theme}
                      />
                    }
                    {/* <div className={`row-center mx-5 align-items-center txt-phrase txt-regular txt-6 txt-neutral-50`}>
                      <div className="w-auto bg-gray-450 border-1 border-color-gray-800 rounded-30 py-2 px-4">
                        {state?.label}
                      </div>
                    </div> */}
                  </SwiperSlide>)}
              </Swiper>
            </div>
            <div
              id="next-slide-quality"
              className="position-absolute row-center align-items-center h-100 pe-pointer right-0 zindex-1"
              style={{ width: 40, height: 40 }}
              onClick={() => track('Quality widget clicked', {Widget_Forward: 'activate'})}>
              {/* <i className="fa-regular fa-chevron-right txt-14 txt-white pe-pointer" /> */}
            </div>
          </div>
        </div> :
        <div className="position-relative w-100" style={{ minHeight: 260 }}><IndicatorsLoading theme={theme}/></div>}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  activeServicesPlus: state.services.activeServicesPlus,
  devices: state.plus.devices,
  energyQuality: state.plus.energyQuality,
  loadingEnergyQuality: state.plus.loadingEnergyQuality,
  language: state.shared.language,
  openQualityEnergy: state.plus.openQualityEnergy,
  dataGraph: state.plus.dataGraph,
  theme: state.theme.theme,
});

const mapDispatchToProps = dispatch => ({
  loadEnergyQuality: () => dispatch(plusActions.loadEnergyQuality()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Radar);

