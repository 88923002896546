import React, { useState, useEffect, Fragment } from "react"
import { connect } from "react-redux"

import * as servicesActions from "../../../store/actions/Services"
import * as usersActions from "../../../store/actions/Users"

import { Animation } from "rsuite"
import { Popover } from "@idui/react-popover"

import ContentLoader from "react-content-loader"

import ServicesPopover from "./ServicesPopover"
import ActiveServices from "./ActiveServices"
import UserList from '../../shared/users/Users'
import UserCreate from "../../shared/users/UserCreate"
import ButtonCircle from "../../shared/utilities/ButtonCircle"
import NewUser from '../../users/NewUser';

import _ from "lodash"

const usersLoading = (theme) => {
  return (
    <div className="position-absolute w-100 h-100 left-0 top-0 zindex-1">
      <ContentLoader seed={2} backgroundColor={theme === "light" ? "#ffffff" : "#141414"} foregroundColor={theme === "light" ? "#d4d4d4" : "#262626"} style={{ width: "100%", height: "100%" }}>
        <rect x="0" y="0" width="220" height="33" rx="17" ry="17"></rect>
      </ContentLoader>
    </div>
  )
}

const ServicesEnergy = (props) => {

  let activeServicesEnergy = props.activeServicesEnergy
  let showDetailService = props.showDetailService
  let usersByService = props.usersByService

  const { theme } = props;

  const loadUsersByServiceAsync = props.loadUsersByServiceAsync

  const [activeServiceDetail, setActiveServiceDetail] = useState(activeServicesEnergy[0])

  useEffect(() => {
    const currentIndex = activeServicesEnergy?.findIndex(s => s.idService === activeServiceDetail?.idService)
    if (currentIndex < 0)
      setActiveServiceDetail(activeServicesEnergy[0])
    // eslint-disable-next-line
  }, [activeServicesEnergy])

  useEffect(() => {
    if (showDetailService && activeServiceDetail)
      loadUsersByServiceAsync(activeServiceDetail.idService)
  }, [showDetailService, activeServiceDetail, loadUsersByServiceAsync])

  return (
    <>
      <div className={`w-100 px-3`}>
        <div id="menu_services" className={`position-relative w-100 column-start rounded-16 p-3 border-fill-medium surface-bg-surface ${theme}`}>
          <div className="row-between align-items-center w-100">
            <div className="column-start gap-3">
              <p className={`label-samll-regular content-default-tertiary ${theme}`}>{props.language.energy_main_title_dashboard}</p>
              <ActiveServices showActiveServices={4} activeServices={activeServicesEnergy} setActiveServices={props.setActiveServicesEnergy} activeServiceDetail={activeServiceDetail} setActiveServiceDetail={setActiveServiceDetail} />
            </div>
            <div id="service_picker">
              <ServicesPopover
                activeServices={activeServicesEnergy}
                selectFunction={(value) => {
                  const filteredPlusServices = value.filter(service => service.type.typeService === 2)
                  props.setActiveServicesEnergy(value)
                  if (filteredPlusServices.length) props.setActiveServicesPlus([_.last(filteredPlusServices)])
                }}
                availableTypeServices={["energy", "plus", "generation"]}
                limitSelectServices={3}
                typeComponent={'energy'}
              />
            </div>
          </div>
          <Animation.Collapse in={showDetailService} dimension={"height"}>
            <div className="column-start w-100" style={{ minHeight: 150 }}>
              <div className={`row-between w-100 align-items-center my-3 py-3 border-fill-medium-top border-fill-medium-bottom ${theme}`}>
                <div className="row-start align-items-center">
                  <p className={`me-2 label-small-regular content-default-tertiary ${theme}`}>{props.language.energy_adress_title}</p>
                  {activeServiceDetail?.address ?
                    <Popover
                      lazy
                      closeOnEnter
                      fitMaxHeightToBounds
                      fitMaxWidthToBounds
                      trigger={"hover"}
                      placement={"bottomLeft"}
                      arrowColor={"#00000000"}
                      className={`button-fill-disabled ${theme} p-0`}
                      arrowSize={0}
                      offset={[0, 0]}
                      mouseEnterDelay={1000}
                      openingAnimationTranslateDistance={0}
                      animation={{
                        animate: { opacity: 1, scale: 1 },
                        exit: { opacity: 0, scale: 0.9, transition: { duration: 0.1 } },
                        initial: { opacity: 0, scale: 0.9 },
                      }}
                      content={
                        <div className="w-100">
                          <div className="column-start align-items-center">
                            <div className="row-between w-100 align-items-center px-3 py-2">
                              <div className={`text-nowrap label-small-regular content-default-primary ${theme}`}>{activeServiceDetail?.address}</div>
                            </div>
                          </div>
                        </div>
                      }>
                      <div className={`row-center rounded-pill py-2 px-3 max-width-300 surface-bg-on-surface ${theme}`}>
                        <p className={`text-truncate pe-default label-small-regular content-default-primary ${theme}`}>{activeServiceDetail?.address}</p>
                      </div>
                    </Popover> :
                    <div className={`row-center rounded-pill py-2 px-3 max-width-300 surface-bg-on-surface ${theme}`}>
                      <p className={`text-truncate pe-default label-small-regular content-default-primary ${theme}`}>{props.language.energy_graph_no_data}</p>
                    </div>}
                </div>
                <div className="row-start align-items-center">
                  <p className={`me-2 label-small-regular content-default-tertiary ${theme}`}>{props.language.energy_service_type_title}</p>
                  <div className="row-center gap-2">
                    <div className={`row-center rounded-pill py-2 px-3 max-width-300 surface-bg-on-surface ${theme}`}>
                      <p className={`label-small-regular content-default-primary ${theme}`} style={{ textTransform: 'capitalize' }}>{activeServiceDetail?.type.name ?? props.language.energy_graph_no_data}</p>
                      <i className={`fak fa-${activeServiceDetail?.type.name}-symbol txt-8 ms-2 content-color-green ${theme}`}></i>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column-start w-100">
                <div className="row-start w-100 align-items-center w-100">
                  <p onClick={() => props.setShowUsersListModal(true)} className={`label-small-regular content-default-tertiary ${theme}`}>
                    {props.language.energy_users_title}
                  </p>
                </div>
                <div className="position-relative row-start align-items-center flex-wrap w-100 gap-2 mt-3">
                  <ButtonCircle
                    icon="circle-plus"
                    size={32}
                    onClick={() => props.setShowCreateUserModal(true)}
                    theme={theme}
                    background="surface-bg-on-surface"
                    color="content-default-tertiary"
                  />
                  {
                    !usersByService[activeServiceDetail?.idService] ?
                      usersLoading(theme)
                      : usersByService[activeServiceDetail?.idService].length ?
                        <Fragment>
                          {
                            usersByService[activeServiceDetail?.idService].map((user) => {
                              return (
                                <div key={user.idUser} className={`row-center align-items-center rounded-pill p-2 min-height-33 surface-bg-on-surface ${theme}`}>
                                  <div className="rounded-circle" style={{ height: 16, width: 16 }}>
                                    <div className="row-center align-items-center h-100 w-100">
                                      <div className={`row-center align-items-center rounded-circle h-100 w-100 pe-default surface-bg-on-surface ${theme}`}>
                                        {!user.image ? <i className={`fa-light fa-user txt-8 content-default-tertiary ${theme}`}></i> : <img className="user-img w-100 h-100 overflow-hidden rounded-circle" src={`${process.env.REACT_APP_IMAGES_URL}/profile-photos/${user.idUser}/150x150/${user.image}`} alt="Foto de perfil del usuario" />}
                                      </div>
                                    </div>
                                  </div>
                                  <p className={`txt-capitalize ms-2 pe-default label-small-regular content-default-primary ${theme}`}>
                                    {user.name} {user.lastname}
                                  </p>
                                </div>
                              )
                            })
                          }
                        </Fragment> :
                        <div className={`row-center align-items-center rounded-pill py-2 px-4 min-height-33 surface-bg-on-surface ${theme}`}>
                          <p className={`label-small-regular content-default-primary ${theme}`}>{props.language.energy_no_users}</p>
                        </div>
                  }
                </div>
              </div>
            </div>
          </Animation.Collapse>
        </div>
      </div>
      <UserList />
      <UserCreate />
      <NewUser />
    </>
  )
}

const mapStateToProps = (state) => ({
  activeServicesEnergy: state.services.activeServicesEnergy,
  usersByService: state.services.usersByService,
  language: state.shared.language,
  user: state.users.user,
  showDetailService: state.energy.showDetailService,
  showBlackScreen: state.shared.showBlackScreen,
  showMenu: state.shared.showMenu,
  theme: state.theme.theme,
  showCreateUserModal: state.users.showCreateUserModal,
});

const mapDispatchToProps = (dispatch) => ({
  setActiveServicesEnergy: (payload) => dispatch(servicesActions.setActiveServicesEnergy(payload)),
  setActiveServicesPlus: (payload) => dispatch(servicesActions.setActiveServicesPlus(payload)),
  setShowUsersListModal: (payload) => dispatch(usersActions.setShowUsersListModal(payload)),
  setUsersAccount: (payload) => dispatch(usersActions.setUsersAccount(payload)),
  loadUsersByServiceAsync: (payload) => dispatch(servicesActions.loadUsersByServiceAsync(payload)),
  setShowCreateUserModal: (payload) => dispatch(usersActions.setShowCreateUserModal(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ServicesEnergy)
