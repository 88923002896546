import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";

import * as sharedActions from "../../store/actions/Shared";
import * as geniusActions from "../../store/actions/Genius";
import * as themeActions from "../../store/actions/Theme";

import { Popover } from "@idui/react-popover";

import { format, parseISO } from "date-fns";
import esLocale from "date-fns/locale/es";
import enLocale from "date-fns/locale/en-US";

import ButtonCircle from "../shared/utilities/ButtonCircle";
import SubMenu from "./SubMenu";

const Picker = ({
  options,
  selectedData,
  keyName,
  labelName,
  labelDetail,
  labelButton,
  icon,
  onChange,
  buttonText,
  report = false,
  allowAllOptions = false,
  labelAllServices,
  allowSearch = false,
  showSelected = false,
  typeReport = false,
  newData = [],
  language,
  groups = [],
  filterName,
  placement = "bottomRight",
  closeOnRemoteClick = "true",
  height = 24,
  width = 24,
  outsideButtonEvent,
  insideButtonEvent,
  allOptionButtonEvent,
  externalShowEvent,
  setExternalShowEvent,
  services = [],
  disabled = false,
  ...props
}) => {
  const [search, setSearch] = useState("");
  const [focus, setFocus] = useState(false);
  const [show, setShow] = useState(false);
  const [selectedAllOptions, setSelectedAllOptions] = useState(false);
  const [data, setData] = useState(options);

  const setShowBlackScreen = props.setShowBlackScreen;
  const getGraphsByServiceAsync = props.getGraphsByServiceAsync;
  let locale = language.language_locale === "es" ? esLocale : enLocale;
  const { theme } = props;

  let totalOptions = options
    .filter(
      (graph) => props.graphsType && props.graphsType.includes(graph.category),
    )
    .map((item) => {
      return item[keyName];
    });

  useEffect(() => {
    if (Array.isArray(selectedData)) {
      setSelectedAllOptions(totalOptions.length === selectedData.length);
    }
  }, [selectedData, totalOptions]);

  useEffect(() => {
    if (options.length && filterName) {
      setData([
        ...options.filter((o) => {
          const filterValue = o[filterName];
          if (filterValue) {
            return filterValue.toLowerCase().includes(search.toLowerCase());
          }
          return false;
        }),
      ]);
    }
  }, [search, options, filterName]);

  const countByType = {};

  if (Array.isArray(selectedData)) {
    for (const id of selectedData) {
      const graph = options.find((g) => g?.id_graph === id);
      if (graph) {
        const type = graph.category;
        countByType[type] = (countByType[type] || 0) + 1;
      }
    }
  }

  const groupsWithCount = groups.map((group) => {
    const count = countByType[group.id] || 0;
    return {
      ...group,
      count,
    };
  });

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 10) {
        setShow(false);
        setShowBlackScreen(false);
        setFocus(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [setShowBlackScreen]);

  useEffect(() => {
    if (externalShowEvent) {
      setShowBlackScreen(true);
      setShow(true);
      setFocus(true);
      setExternalShowEvent(false);
    }
  }, [externalShowEvent, setShowBlackScreen, setExternalShowEvent]);

  useEffect(() => {
    if (services.length) getGraphsByServiceAsync({ ids_service: services });
  }, [getGraphsByServiceAsync, services]);

  return (
    <Popover
      lazy
      closeOnRemoteClick={closeOnRemoteClick}
      fitMaxHeightToBounds
      fitMaxWidthToBounds
      closeOnScroll={false}
      trigger={"click"}
      placement={placement}
      className={`p-0 rounded-16 surface-bg-surface border-fill-medium ${theme}`}
      withArrow={false}
      isOpen={show}
      width="400px"
      offset={[0, 0]}
      openingAnimationTranslateDistance={0}
      onChangeOpen={(state) => {
        setShowBlackScreen(state);
        setShow(state);
        setFocus(state);
      }}
      animation={{
        animate: { opacity: 1 },
        exit: { opacity: 0, transition: { duration: 0.1 } },
        initial: { opacity: 0 },
      }}
      content={
        <Fragment>
          <div
            className="w-100 ps-3 pe-1 scroll-without-border"
            style={{ maxHeight: "300px", overflowY: "scroll" }}
          >
            {allowSearch && (
              <div
                className={`sticky-top py-3 surface-bg-surface border-fill-medium-bottom ${theme}`}
              >
                <div className="row-start align-items-center border-1 border-color-gray-800 py-2 px-3 rounded-8">
                  <i className="fal fa-search txt-10 txt-gray-650 me-2" />
                  <input
                    type="text"
                    className={`txt-phrase txt-8 txt-gray-650 border-0 w-100 surface-bg-surface ${theme}`}
                    placeholder={language.genius_search_placeholder}
                    value={search}
                    onChange={({ target }) => setSearch(target.value)}
                  />
                </div>
              </div>
            )}
            <div className="row-between align-items-center my-3 px-2">
              <p
                className={`label-small-regular content-default-secondary ${theme}`}
              >
                {labelAllServices}
              </p>
              {allowAllOptions && labelName !== "file_name" ? (
                <p
                  className={`label-small-regular content-color-green pe-pointer mt-0 ${theme}`}
                  onClick={() => {
                    if (!selectedAllOptions) {
                      onChange(totalOptions);
                    } else {
                      onChange([]);
                    }
                  }}
                >
                  {language.reports_select_all}
                </p>
              ) : null}
              {showSelected ? (
                <p
                  className={`label-small-regular content-color-green pe-pointer mt-0 ${theme}`}
                >{`${language.genius_selected_items} ${selectedData.length}/2`}</p>
              ) : null}
            </div>
            {options?.length && groupsWithCount?.length && services.length ? (
              <div className="column-start graphs-picker-container mb-3">
                {options.map((item, i) => (
                  <div key={i} className="row-between align-items-center my-1">
                    <div
                      className={`prevent-select label-small-regular content-default-primary ${theme}`}
                    >
                      {item.name_category}
                    </div>
                    <div className="row-start align-items-center pe-pointer">
                      {item.options_graphs?.length &&
                      item?.options_graphs.filter((g) =>
                        selectedData.includes(g.id_graph),
                      )?.length ? (
                        <div
                          className={`row-center align-items-center border-1 border-color-green me-2 txt-6 txt-phrase txt-regular content-color-green ${theme}`}
                          style={{ borderRadius: "50%", height: 16, width: 16 }}
                        >
                          {
                            item?.options_graphs.filter((g) =>
                              selectedData.includes(g.id_graph),
                            )?.length
                          }
                        </div>
                      ) : null}
                      <SubMenu
                        data={item?.options_graphs}
                        selectedData={
                          Array.isArray(selectedData) && selectedData
                        }
                        newData={newData}
                        language={language}
                        onChange={onChange}
                        keyName={keyName}
                        labelName={labelName}
                        theme={theme}
                      />
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              data.length && (
                <div className="column-start graphs-picker-container">
                  {data.map((item) => {
                    let isSelected;
                    let url;

                    if (report) {
                      isSelected =
                        Array.isArray(selectedData) &&
                        selectedData.findIndex(
                          (i) => i.id_report === item.id_report,
                        ) >= 0;
                      url = `${process.env.REACT_APP_GENIUS_REPORTS_DISTRIBUTION}${item.id_account}/${item.file_name}`;
                    } else {
                      isSelected = Array.isArray(selectedData)
                        ? selectedData.includes(item[keyName])
                        : selectedData === item[keyName];
                    }

                    return (
                      <div
                        key={item.id_report}
                        className={`row-between align-items-center pe-pointer py-2 px-3 my-1 rounded-8 ${
                          isSelected
                            ? "surface-bg-on-surface"
                            : "surface-bg-surface"
                        } ${theme}`}
                        onClick={() => {
                          if (isSelected) {
                            if (report) {
                              if (
                                Array.isArray(selectedData) &&
                                selectedData.length > 1
                              )
                                onChange(
                                  selectedData.filter(
                                    (i) => i.id_report !== item.id_report,
                                  ),
                                );
                            } else {
                              onChange(
                                Array.isArray(selectedData)
                                  ? typeReport
                                    ? [item[keyName]]
                                    : selectedData.filter(
                                        (value) => value !== item[keyName],
                                      )
                                  : item[keyName],
                              );
                            }
                          } else {
                            if (
                              report &&
                              Array.isArray(selectedData) &&
                              selectedData.length < 2
                            ) {
                              onChange([
                                ...selectedData,
                                { url: url, ...item },
                              ]);
                            } else if (!report) {
                              !typeReport &&
                              props.serviceType &&
                              props.serviceType.includes(4)
                                ? onChange(item[keyName])
                                : typeReport
                                ? onChange([item[keyName]])
                                : onChange([...selectedData, item[keyName]]);
                            }
                          }
                        }}
                      >
                        <div className="column-start max-width-280">
                          <div
                            className={`text-wrap pe-pointer prevent-select label-small-medium content-default-primary ${theme}`}
                          >
                            {report
                              ? [item[labelName].split(".")[0]]
                              : [item[labelName]]}
                          </div>
                          <p className="txt-phrase txt-6 txt-gray-650 txt-capitalize">
                            {report
                              ? format(
                                  parseISO(item[labelDetail]),
                                  "eee. dd, LLLL",
                                  { locale: locale },
                                )
                              : item[labelDetail]}
                          </p>
                        </div>
                        <i
                          className={`fal fa-check-circle ${
                            isSelected ? "content-color-green" : "content-default-secondary"
                          } txt-10 ${theme}`}
                        ></i>
                      </div>
                    );
                  })}
                </div>
              )
            )}
          </div>
          <div
            className={`row-end py-3 px-1 mx-3 border-fill-medium-top ${theme}`}
          >
            <button
              className={`p-2 rounded-16 label-small-regular button-fill-brand button-content-equal ${theme}`}
              onClick={() => {
                setShowBlackScreen(false);
                setShow(false);
                setFocus(false);
              }}
            >
              {labelButton}
            </button>
          </div>
        </Fragment>
      }
    >
      {buttonText ? (
        <button
          className={`row-center align-items-center rounded-pill genius-save-button pulse py-2 px-3 border-color-green surface-bg-on-surface-alpha ${theme}`}
        >
          <i
            className={`fa-light fa-circle-check txt-8 content-color-green me-2 ${theme}`}
          />
          <p
            className={`txt-phrase txt-regular content-color-green txt-8 pe-pointer ${theme}`}
          >
            {buttonText}
          </p>
        </button>
      ) : (
        <ButtonCircle
          focus={focus}
          icon={focus ? "circle-xmark" : "circle-plus"}
          disabled={disabled}
          language={language}
          theme={theme}
        />
      )}
    </Popover>
  );
};

const mapStateToProps = (state, ownProps) => ({
  language: state.shared.language,
  totalReports: state.genius.totalReports,
  account: state.accounts.account,
  serviceType: state.genius.serviceType,
  graphsType: state.genius.graphsType,
  activeReports: ownProps.activeReports,
  theme: state.theme.theme,
});

const mapDispatchToProps = (dispatch) => ({
  setShowBlackScreen: (payload) =>
    dispatch(sharedActions.setShowBlackScreen(payload)),
  getReportsAsync: () => dispatch(geniusActions.getReportsAsync()),
  setActiveReports: (payload) =>
    dispatch(geniusActions.setActiveReports(payload)),
  setShowContentHeader: (payload) =>
    dispatch(geniusActions.setShowContentHeader(payload)),
  setNewReport: (payload) => dispatch(geniusActions.setNewReport(payload)),
  getGraphsByServiceAsync: (payload) =>
    dispatch(geniusActions.getGraphsByServiceAsync(payload)),
  setTheme: (theme) => dispatch(themeActions.setTheme(theme)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Picker);
