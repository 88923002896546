import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import * as usersActions from "../../store/actions/Users";
import * as sharedActions from "../../store/actions/Shared";
import Select from "../shared/utilities/Select";
import NewUser from './NewUser';
import UpdateService from './UpdateService';
import ContentLoader from 'react-content-loader';

const AccountHeader = ({ accountName, theme }) => (
  <tr>
    <td colSpan="12" className="pt-3">
      <div className="d-flex justify-content-between align-items-center w-100">
        <div className={`py-2 px-3 rounded-pill border-fill-medium label-medium-semibold content-default-secondary ${theme}`}>
          {accountName}
        </div>
        <div className="flex-grow-1 d-flex align-items-center">
          <div className={`border-fill-medium-top w-100 ${theme}`}></div>
        </div>
      </div>
    </td>
  </tr>
);

function UserTable(props) {
  const {
    filteredUsers,
    theme,
    setShowBlackScreen,
    deleteUserAsync,
    accounts,
    updateUserAsync,
    selectedAccount,
    userTypes,
    loadUserTypesAsync,
    onSort,
  } = props;

  const [showUpdateServiceModal, setShowUpdateServiceModal] = useState(false);
  const [selectedUserForUpdate, setSelectedUserForUpdate] = useState(null);

  const selectedAccountName = useMemo(() => {
    if (selectedAccount) {
      const account = accounts.find(acc => acc.idAccount === selectedAccount);
      return account && account.name ? account.name : "Cuenta no encontrada";
    }
    return "Cuenta no encontrada";
  }, [selectedAccount, accounts]);

  const paginatedUsersForAccount = useMemo(() => {
    if (!Array.isArray(filteredUsers) || !selectedAccount) return [];
    return filteredUsers.filter(user => user.idAccount === selectedAccount);
  }, [filteredUsers, selectedAccount]);

  useEffect(() => {
    loadUserTypesAsync();
  }, [loadUserTypesAsync]);

  const userNameTemplate = (rowData) => (
    <div className={`row-between align-items-center py-3 px-4 rounded-start border-fill-medium-right surface-bg-on-surface-alpha pe-pointer ${theme}`} style={{ height: 50 }}>
      <p className={`label-medium-semibold content-default-primary text-nowrap overflow-hidden ${theme}`} style={{ textOverflow: 'ellipsis' }}>
        {rowData.name || 'Sin nombre'}
      </p>
    </div>
  );

  const userAreaTemplate = (rowData) => (
    <div className={`row-between align-items-center py-3 gap-3 px-4 border-fill-medium-right surface-bg-on-surface-alpha pe-pointer ${theme}`} style={{ height: 50 }}>
      <p className={`label-medium-regular content-default-secondary text-nowrap overflow-hidden ${theme}`} style={{ textOverflow: 'ellipsis' }}>
        {rowData.area || 'Sin cargo'}
      </p>
    </div>
  );

  const userRolTemplate = (rowData) => {
    const options = userTypes.map(userType => ({
      label: userType.description,
      value: userType.idUserType
    }));

    const userRole = userTypes.find(userType => userType.idUserType === rowData.idUserType || userType.idUserType === rowData.idRol)?.description || 'Desconocido';

    return (
      <div className={`row-center align-items-center py-3 gap-3 px-4 border-fill-medium-right surface-bg-on-surface-alpha pe-pointer ${theme}`} style={{ height: 50 }}>
        <div style={{ width: 150 }}>
          {props.currentUser.capabilities?.show_users_admin_view ? (
            <Select
              value={rowData.idUserType || rowData.idRol || null}
              options={options}
              background={`surface-bg-on-surface-alpha ${theme}`}
              onChange={async (value) => {
                if (updateUserAsync) {
                  const updatedUser = {
                    ...rowData,
                    idUserType: value,
                    rol: userTypes.find(userType => userType.idUserType === value)?.description || 'Desconocido'
                  };
                  await updateUserAsync({ user: updatedUser });
                }
              }}
            />
          ) : (
            <div className={`row-between align-items-center py-2 px-3 gap-2 border-fill-medium surface-bg-on-surface-alpha rounded-pill pe-pointer ${theme}`}>
              <p className={`label-small-medium content-default-primary pe-pointer ${theme}`}>
                {userRole}
              </p>
            </div>
          )}
        </div>
      </div>
    );
  };

  const userProjectsTemplate = (rowData) => {
    const totalServices = rowData.servicesIds ? rowData.servicesIds.length : 0;

    return (
      <div className={`row-center align-items-center py-3 gap-3 px-4 border-fill-medium-right surface-bg-on-surface-alpha ${theme}`} style={{ height: 50 }}>
        <button
          className={`row-center align-items-center gap-2 py-2 px-3 rounded-30 border-fill-medium surface-bg-on-surface-alpha ${theme}`}
          onClick={() => {
            setSelectedUserForUpdate(rowData);
            setShowUpdateServiceModal(true);
            setShowBlackScreen(true);
          }}
        >
          <p className={`label-small-medium content-default-primary ${theme}`}>
            {totalServices}
          </p>
          {props.currentUser.capabilities?.show_users_admin_view ? (
            <i className={`far fa-circle-plus txt-10 content-default-secondary ${theme}`}></i>
          ) : null}
        </button>
      </div>
    );
  };

  const userEmailTemplate = (rowData) => (
    <div className={`row-between align-items-center py-3 gap-3 px-4 border-fill-medium-right surface-bg-on-surface-alpha ${theme}`} style={{ height: 50 }}>
      <p className={`label-medium-regular content-default-secondary text-nowrap overflow-hidden ${theme}`} style={{ textOverflow: 'ellipsis' }}>
        {rowData.email || 'Sin email'}
      </p>
    </div>
  );

  const userPhoneTemplate = (rowData) => (
    <div className={`row-between align-items-center py-3 gap-3 px-4 border-fill-medium-right surface-bg-on-surface-alpha ${theme}`} style={{ height: 50 }}>
      <p className={`label-medium-regular content-default-secondary text-nowrap overflow-hidden ${theme}`} style={{ textOverflow: 'ellipsis' }}>
        {rowData.phone || 'Sin teléfono'}
      </p>
    </div>
  );

  const userRecordTemplate = (rowData) => (
    <div className={`row-between align-items-center py-3 gap-3 px-4 surface-bg-on-surface-alpha pe-pointer ${props.currentUser.capabilities?.show_users_admin_view ? '' : 'rounded-end'} ${theme}`} style={{ height: 50 }}>
      <p className={`label-medium-regular content-default-secondary text-nowrap overflow-hidden ${theme}`} style={{ textOverflow: 'ellipsis' }}>
        {rowData.recordDate || 'Sin actividad'}
      </p>
    </div>
  );

  const handleDelete = (rowData) => {
    if (selectedAccount) {
      deleteUserAsync(rowData.idUser, selectedAccount);
    }
  };

  const userDeleteTemplate = (rowData) => {
    return (
    <div className={`row-between align-items-center py-3 gap-3 border-fill-medium-left px-4 surface-bg-on-surface-alpha rounded-end ${theme}`} style={{ height: 50 }}>
      <button
        onClick={() => handleDelete(rowData)}
        className={`row-center align-items-center surface-bg-on-surface border-fill-medium rounded-pill ${theme}`}
        style={{ height: 32, width: 32 }}
      >
        <i className={`fal fa-trash txt-8 content-default-secondary ${theme}`}></i>
      </button>
    </div>
  );
  };

  const columnWidths = {
    name: '18%',
    area: '13%',
    role: '15%',
    projects: '9%',
    email: '18%',
    phone: '12%',
    lastActivity: '20%',
    delete: '10%',
  };

  return (
    <div className="column-start flex-grow-1 px-3 pb-3 vh-100" style={{ minWidth: '100%', width: 'fit-content' }}>
      <table id={`invoices-table`} className={theme}>
        <thead className="position-sticky zindex-1" style={{ background: `linear-gradient(to bottom, ${theme === 'dark' ? '#141414' : '#ffffff'} 50%, transparent 50%)`, top: '-1px' }}>
          <tr>
            <th className='position-sticky zindex-1' style={{ top: '1rem', width: columnWidths.name }}>
              <div className={`row-between align-items-center py-3 gap-3 px-4 rounded-start border-fill-medium-vertical border-fill-medium-horizontal surface-bg-on-surface pe-pointer ${theme}`} onClick={() => onSort("name")}>
                <p className={`label-small-medium content-default-primary ${theme}`}>Nombre</p>
                <i className={`fal fa-angles-up-down content-default-primary txt-8 ${theme}`}></i>
              </div>
            </th>
            <th className='position-sticky zindex-1' style={{ top: '1rem', width: columnWidths.area }}>
              <div className={`row-between align-items-center py-3 gap-3 px-4 border-fill-medium-vertical border-fill-medium-right surface-bg-on-surface pe-pointer ${theme}`} onClick={() => onSort("area")}>
                <p className={`label-small-medium content-default-primary ${theme}`}>Cargo</p>
                <i className={`fal fa-angles-up-down content-default-primary txt-8 ${theme}`}></i>
              </div>
            </th>
            <th className='position-sticky zindex-1' style={{ top: '1rem', width: columnWidths.role }}>
              <div className={`row-between align-items-center py-3 gap-3 px-4 border-fill-medium-vertical border-fill-medium-right surface-bg-on-surface pe-pointer ${theme}`} onClick={() => onSort("rol")}>
                <p className={`label-small-medium content-default-primary ${theme}`}>Rol</p>
                <i className={`fal fa-angles-up-down content-default-primary txt-8 ${theme}`}></i>
              </div>
            </th>
            <th className='position-sticky zindex-1' style={{ top: '1rem', width: columnWidths.projects }}>
              <div className={`row-between align-items-center py-3 gap-3 px-4 border-fill-medium-vertical border-fill-medium-right surface-bg-on-surface ${theme}`}>
                <p className={`label-small-medium content-default-primary ${theme}`}>Servicios</p>
              </div>
            </th>
            <th className='position-sticky zindex-1' style={{ top: '1rem', width: columnWidths.email }}>
              <div className={`row-between align-items-center py-3 gap-3 px-4 border-fill-medium-vertical border-fill-medium-right surface-bg-on-surface ${theme}`}>
                <p className={`label-small-medium content-default-primary ${theme}`}>Correo</p>
              </div>
            </th>
            <th className='position-sticky zindex-1' style={{ top: '1rem', width: columnWidths.phone }}>
              <div className={`row-between align-items-center py-3 gap-3 px-4 border-fill-medium-vertical border-fill-medium-right surface-bg-on-surface ${theme}`}>
                <p className={`label-small-medium content-default-primary ${theme}`}>Teléfono</p>
              </div>
            </th>
            <th className='position-sticky zindex-1' style={{ top: '1rem', width: columnWidths.lastActivity, minWidth: '180px' }}>
              <div className={`row-between align-items-center py-3 gap-3 px-4 border-fill-medium-vertical border-fill-medium-right surface-bg-on-surface pe-pointer ${props.currentUser.capabilities?.show_users_admin_view ? '' : 'rounded-end'} ${theme}`} onClick={() => onSort("recordDate")}>
                <p className={`label-small-medium content-default-primary ${theme}`}>Última actividad</p>
                <i className={`fal fa-angles-up-down content-default-primary txt-8 ${theme}`}></i>
              </div>
            </th>
            {props.currentUser.capabilities?.show_users_admin_view ? (
              <th className='position-sticky zindex-1' style={{ top: '1rem', width: columnWidths.delete }}>
                <div className={`row-between align-items-center py-3 gap-3 px-4 border-fill-medium-vertical border-fill-medium-right surface-bg-on-surface rounded-end ${theme}`}>
                  <p className={`label-small-medium content-default-primary ${theme}`}>Eliminar</p>
                </div>
              </th>
            ) : null}
          </tr>
        </thead>
        <tbody>
          {paginatedUsersForAccount.length <= 0 ? (
            <tr className="invoice-row zIndex-1">
              <td colSpan="8">
                <div style={{ position: 'relative', width: '100%', height: '100vh' }}>
                  <ContentLoader
                    seed={2}
                    backgroundColor={theme === "light" ? "#ffffff" : "#141414"}
                    foregroundColor={theme === "light" ? "#d4d4d4" : "#262626"}
                    style={{ width: '100%', height: '100%', position: 'absolute', top: 0, left: 0 }}
                  >
                    <rect x="0" y="0" width="100%" height="100%" rx="16" ry="16" />
                  </ContentLoader>
                </div>
              </td>
            </tr>
          ) : (
            <>
              {selectedAccount && <AccountHeader accountName={selectedAccountName} theme={theme} />}
              {paginatedUsersForAccount.length > 0 ? (
                paginatedUsersForAccount.map((user, userIndex) => (
                  <tr key={userIndex} className="invoice-row zIndex-1">
                    <td style={{ width: columnWidths.name, maxWidth: '170px' }}>{userNameTemplate(user)}</td>
                    <td style={{ width: columnWidths.area, maxWidth: '150px' }}>{userAreaTemplate(user)}</td>
                    <td style={{ width: columnWidths.role, maxWidth: '185px' }}>{userRolTemplate(user)}</td>
                    <td style={{ width: columnWidths.projects, maxWidth: '100px' }}>{userProjectsTemplate(user)}</td>
                    <td style={{ width: columnWidths.email, maxWidth: '180px' }}>{userEmailTemplate(user)}</td>
                    <td style={{ width: columnWidths.phone, maxWidth: '120px' }}>{userPhoneTemplate(user)}</td>
                    <td style={{ width: columnWidths.lastActivity, maxWidth: '180px' }}>{userRecordTemplate(user)}</td>
                    {props.currentUser.capabilities?.show_users_admin_view && (
                      <td style={{ width: columnWidths.delete, maxWidth: '70px' }}>{userDeleteTemplate(user)}</td>
                    )}
                  </tr>
                ))
              ) : null}
            </>
          )}
        </tbody>
      </table>
      {props.showCreateUserModal && <NewUser />}
      {showUpdateServiceModal && (
        <UpdateService
          theme={theme}
          selectedUser={selectedUserForUpdate}
          accounts={props.accounts}
          setUserServicesSelected={props.setUserServicesSelected}
          updateUserAsync={props.updateUserAsync}
          language={props.language}
          onClose={() => {
            setShowUpdateServiceModal(false);
            setShowBlackScreen(false);
          }}
          show={showUpdateServiceModal}
          selectedAccount={props.selectedAccount}
          currentUser={props.currentUser}
          loadUsersAsync={props.loadUsersAsync}
        />
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  language: state.shared.language,
  theme: state.theme.theme,
  usersLoading: state.users.usersLoading,
  showBlackScreen: state.shared.showBlackScreen,
  currentUser: state.users.user,
  accounts: state.accounts.totalAccounts,
  showCreateUserModal: state.users.showCreateUserModal,
  selectedAccount: state.users.accountSelectedUsersTable,
  userTypes: state.users.userTypes,
  currentPage: state.users.currentPage,
  users: state.users.users,
});

const mapDispatchToProps = (dispatch) => ({
  loadUsersAsync: (payload) => dispatch(usersActions.loadUsersAsync(payload)),
  setShowCreateUserModal: (payload) => dispatch(usersActions.setShowCreateUserModal(payload)),
  deleteUserAsync: (idUser, idAccount) => dispatch(usersActions.deleteUserAsync(idUser, idAccount)),
  updateUserAsync: (payload) => dispatch(usersActions.updateUserAsync(payload)),
  loadUserTypesAsync: () => dispatch(usersActions.loadUserTypesAsync()),
  setShowBlackScreen: (payload) => dispatch(sharedActions.setShowBlackScreen(payload)),
  setUserServicesSelected: (payload) => dispatch(usersActions.setUserServicesSelected(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserTable);