import React, { useState, useRef, useEffect, useMemo } from "react";
import { connect } from "react-redux";

import * as servicesActions from "../../store/actions/Services";
import * as usersActions from "../../store/actions/Users";

import Header from "../shared/Header";
import UserTable from "./UserTable";
import Pagination from "./UserPagination";
import NewUser from './NewUser';
import InputRounded from '../shared/utilities/InputRounded';
import Select from "../shared/utilities/Select";
import ContentLoader from "react-content-loader";

const Users = (props) => {
    const {
        theme,
        language,
        users,
        currentPage,
        totalPages,
        setShowCreateUserModal,
        totalUsers,
        selectedAccount,
        setSelectedAccount,
        accounts,
        loadUsersAsync,
        currentUser,
        userTypes,
        updateUserAsync,
        deleteUserAsync,
        loadUserTypesAsync,
        userServicesSelected,
        setUserServicesSelected,
    } = props;

    const [searchQuery, setSearchQuery] = useState('');
    const [localSelectedAccount, setLocalSelectedAccount] = useState(selectedAccount);
    const [sortConfig, setSortConfig] = useState({ field: null, direction: 'asc' });
    const invoicesContainerRef = useRef(null);

    useEffect(() => {
        if (accounts && accounts.length > 0 && !localSelectedAccount) {
            setLocalSelectedAccount(accounts[0].idAccount);
            setSelectedAccount(accounts[0].idAccount);
        }
    }, [accounts, localSelectedAccount, setSelectedAccount]);

    useEffect(() => {
        if (localSelectedAccount) {
            loadUsersAsync({
                id_services: accounts.find(acc => acc.idAccount === localSelectedAccount)?.services.map(service => service.idService) || [],
                page: currentPage,
            });
        }
    }, [localSelectedAccount, currentPage, loadUsersAsync, accounts]);

    const sortedUsers = useMemo(() => {
        if (!sortConfig.field) return users;

        const sorted = [...users];
        sorted.sort((a, b) => {
            let valueA = a[sortConfig.field] || '';
            let valueB = b[sortConfig.field] || '';

            if (sortConfig.field === 'rol') {
                valueA = userTypes.find(ut => ut.idUserType === a.idUserType)?.description || '';
                valueB = userTypes.find(ut => ut.idUserType === b.idUserType)?.description || '';
            }

            valueA = valueA.toString().toLowerCase();
            valueB = valueB.toString().toLowerCase();

            return sortConfig.direction === 'asc'
                ? valueA.localeCompare(valueB)
                : valueB.localeCompare(valueA);
        });
        return sorted;
    }, [users, sortConfig, userTypes]);

    const filteredUsers = useMemo(() => {
        if (!searchQuery) return sortedUsers;
        const lowerQuery = searchQuery.toLowerCase();
        return sortedUsers.filter(user =>
            (user.name?.toLowerCase() || "").includes(lowerQuery) ||
            (user.email?.toLowerCase() || "").includes(lowerQuery) ||
            (user.phone?.toLowerCase() || "").includes(lowerQuery)
        );
    }, [sortedUsers, searchQuery]);

    const openNewUser = () => {
        setShowCreateUserModal(true);
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handlePageChange = (newPage) => {
        props.setCurrentPageUsers(newPage);
    };

    const handleSort = (field) => {
        setSortConfig(prev => ({
            field,
            direction: prev.field === field && prev.direction === 'asc' ? 'desc' : 'asc'
        }));
    };
    const [localLoading, setLocalLoading] = useState(false);
    useEffect(() => {
        if (localSelectedAccount) {
            setLocalLoading(true);
            loadUsersAsync({
                id_services: accounts.find(acc => acc.idAccount === localSelectedAccount)?.services.map(service => service.idService) || [],
                page: currentPage,
            }).finally(() => {
                setLocalLoading(false);
            });
        }
    }, [localSelectedAccount, currentPage, loadUsersAsync, accounts]);
    return (
        <section className="column-start align-items-center vh-100 px-3 w-100">
            <Header disablePadding={true} moduleName={language.users_title_header} backToButton={true}/>
            <div className={`row-between w-100 surface-bg-fill h-20 surface-bg-surface rounded-8 border-fill-medium mb-3 p-3 ${theme}`}>
                <p className={`label-small-regular content-default-primary ${theme}`}>{language.users_description_header}</p>
            </div>
            <div className={`column-start flex-grow-1 surface-bg-surface rounded-16 overflow-auto w-100 border-fill-medium mb-3 vh-100 ${theme}`}>
                <div ref={invoicesContainerRef} id="invoices-table-scroll" className={`column-start flex-grow-1 gap-3 overflow-auto w-100 vh-100 border-fill-medium-bottom ${theme}`}>
                    <div className={`position-sticky left-0 row-between align-items-center px-3 pt-3 w-100 zindex-2`}>
                        <div className={`position-relative row-start align-items-center gap-2 p-2 surface-bg-on-surface rounded-30 w-60 border-fill-medium ${theme}`}>
                            <div className='w-40 rounded-8' backdropClassName={`surface-bg-surface pe-pointer ${theme}`}>
                                <Select
                                    backdropClassName={'bg-black opacity-30 pe-pointer'}
                                    value={localSelectedAccount}
                                    options={accounts && Array.isArray(accounts) ? accounts.map(acc => ({ label: acc.name, value: acc.idAccount })) : []}
                                    onChange={(value) => {
                                        setLocalSelectedAccount(value);
                                        setSelectedAccount(value);
                                    }}
                                    background='surface-bg-surface'
                                    width={'40vh'}
                                    height={150}
                                />
                            </div>
                            <div className={`label-small-regular content-default-primary w-10 ${theme}`}>
                                {totalUsers ? `${totalUsers} usuarios` : null}
                            </div>
                            <div className="w-50">
                                <InputRounded
                                    icon="magnifying-glass"
                                    placeholder={language['dashboard_input_placeholder'] || 'Buscar'}
                                    value={searchQuery}
                                    onChange={handleSearchChange}
                                    px={3}
                                    py={2}
                                />
                            </div>
                        </div>
                        <div className={`position-relative row-start align-items-center gap-2 p-2 surface-bg-on-surface rounded-30 border-fill-medium ${theme}`}>
                            <button onClick={openNewUser} className={`row-center align-items-center py-2 px-3 gap-2 button-fill-brand rounded-pill pe-pointer ${theme}`}>
                                <p className={`label-medium-medium button-content-equal pe-pointer ${theme}`}>
                                    {language['users_button_new_user'] || 'Agregar más usuarios'}
                                </p>
                                <i className={`fa-regular fa-circle-plus txt-10 button-content-equal ${theme}`}></i>
                            </button>
                        </div>
                    </div>
                    <UserTable
                        filteredUsers={filteredUsers}
                        language={language}
                        theme={theme}
                        loadUsersAsync={loadUsersAsync}
                        setShowCreateUserModal={setShowCreateUserModal}
                        currentUser={currentUser}
                        accounts={accounts}
                        updateUserAsync={updateUserAsync}
                        selectedAccount={localSelectedAccount}
                        userTypes={userTypes}
                        loadUserTypesAsync={loadUserTypesAsync}
                        userServicesSelected={userServicesSelected}
                        setUserServicesSelected={setUserServicesSelected}
                        currentPage={currentPage}
                        deleteUserAsync={deleteUserAsync}
                        onSort={handleSort}
                    />
                    {props.showCreateUserModal && <NewUser />}
                </div>
                {localLoading || totalPages < 1
                    ? (
                        <div className={`row-center align-items-center w-100 mt-1 ${theme}`} style={{ height: 73 }}>
                            <div style={{height:'96%', width:'50%'}}>
                            <ContentLoader
                                seed={1}
                                backgroundColor={theme === "light" ? "#ffffff" : "#141414"}
                                foregroundColor={theme === "light" ? "#d4d4d4" : "#262626"}
                                style={{ width: '100%', height: '100%' }}
                            >
                                <rect x="0" y="0" rx="8" ry="8" width="100%" height="65px" />
                            </ContentLoader>
                            </div>
                        </div>
                    )
                    : (
                        <div className={`row-center align-items-center w-100 ${theme}`} style={{ height: 100 }}>
                            <Pagination
                                totalPages={totalPages}
                                currentPage={currentPage}
                                theme={theme}
                                onPageChange={handlePageChange}
                            />
                        </div>
                    )}
            </div>
        </section>
    );
};

const mapStateToProps = (state) => {
    return {
        language: state.shared.language,
        theme: state.theme.theme,
        users: state.users.users,
        currentPage: state.users.currentPage,
        totalPages: state.users.totalPagesUsers,
        totalUsers: state.users.totalUsers,
        accounts: state.accounts.totalAccounts,
        showCreateUserModal: state.users.showCreateUserModal,
        currentUser: state.users.user,
        userTypes: state.users.userTypes,
        userServicesSelected: state.users.userServicesSelected,
    };
};

const mapDispatchToProps = (dispatch) => ({
    setActiveServicesEnergy: (payload) => dispatch(servicesActions.setActiveServicesEnergy(payload)),
    setCurrentPageUsers: (payload) => dispatch(usersActions.setCurrentPageUsers(payload)),
    setSelectedAccount: (payload) => dispatch(usersActions.setAccountSelectedUsersTable(payload)),
    loadUsersAsync: (payload) => dispatch(usersActions.loadUsersAsync(payload)),
    setShowCreateUserModal: (payload) => dispatch(usersActions.setShowCreateUserModal(payload)),
    deleteUserAsync: (idUser, idAccount) => dispatch(usersActions.deleteUserAsync(idUser, idAccount)),
    updateUserAsync: (payload) => dispatch(usersActions.updateUserAsync(payload)),
    loadUserTypesAsync: () => dispatch(usersActions.loadUserTypesAsync()),
    setUserServicesSelected: (payload) => dispatch(usersActions.setUserServicesSelected(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Users);