import React from "react";

export const colors = (theme) => {
  return [
    {
      name: "Green",
      hex: theme === "dark" ? "#9ecc14" : "#7da60c",
    },
    {
      name: "Blue",
      hex: theme === "dark" ? "#00b7d3" : "#0396b7",
    },
    {
      name: "Orange",
      hex: theme === "dark" ? "#f97316" : "#ea580c",
    },
    {
      name: "Red",
      hex: theme === "dark" ? "#EF444C" : "#DC262F",
    },
    {
      name: "Purple",
      hex: theme === "dark" ? "#a855f7" : "#9333ea",
    },
    {
      name: "Pink",
      hex: theme === "dark" ? "#ec4899" : "#db2777",
    },
    {
      name: "PurpleLight",
      hex: "var(--purple-600)",
    },
    {
      name: "PurpleDark",
      hex: "var(--purple-700)",
    },
    {
      name: "BlueLight",
      hex: "var(--blue-600)",
    },
    {
      name: "BlueDark",
      hex: "var(--blue-700)",
    },
    {
      name: "GreenLight",
      hex: "var(--brand-primary-600)",
    },
    {
      name: "GreenDark",
      hex: "var(--brand-primary-700)",
    },
    {
      name: "Neutral",
      hex: "#F5FED6",
    },
    {
      name: "BrandPrimary",
      hex: "var(--brand-primary-500)",
    },
  ];
};

const gradients25 = (theme) => {
  return colors(theme).map((color, i) => {
    return (
      <svg key={`${i}-25`} style={{ height: 0, width: 0, display: "flex" }}>
        <defs>
          <linearGradient
            id={`linear${color.name}Transparent25`}
            gradientTransform="rotate(90)"
          >
            <stop offset="25%" stopColor={color.hex} />
            <stop offset="100%" stopColor="transparent" />
          </linearGradient>
        </defs>
      </svg>
    );
  });
};

const gradients25Light = (theme) => {
  return colors(theme).map((color, i) => {
    return (
      <svg key={`${i}-25-light`} style={{ height: 0, width: 0, display: "flex" }}>
        <defs>
          <linearGradient
            id={`linear${color.name}Transparent25Light`}
            gradientTransform="rotate(90)"
          >
            <stop offset="25%" stopColor={color.hex} />
            <stop offset="100%" stopColor="white" />
          </linearGradient>
        </defs>
      </svg>
    );
  });
};

const gradients50 = (theme) => {
  return colors(theme).map((color, i) => {
    return (
      <svg key={`${i}-50`} style={{ height: 0, width: 0, display: "flex" }}>
        <defs>
          <linearGradient
            id={`linear${color.name}Transparent50`}
            gradientTransform="rotate(90)"
          >
            <stop offset="50%" stopColor={color.hex} />
            <stop offset="100%" stopColor="transparent" />
          </linearGradient>
        </defs>
      </svg>
    );
  });
};

const gradient75 = (theme) => {
  return colors(theme).map((color, i) => {
    return (
      <svg key={`${i}-75`} style={{ height: 0, width: 0, display: "flex" }}>
        <defs>
          <linearGradient
            id={`linear${color.name}Transparent75`}
            gradientTransform="rotate(90)"
          >
            <stop offset="75%" stopColor={color.hex} />
            <stop offset="100%" stopColor="transparent" />
          </linearGradient>
        </defs>
      </svg>
    );
  });
};

export const gradients = (theme) => [
  ...gradients25(theme),
  ...gradients25Light(theme),
  ...gradients50(theme),
  ...gradient75(theme),
];

// export const barStyles = colors.map(color => {
//     return {
//         data: { fill: `url(#linear${color.name}Transparent75)` }
//     }
// });

export const barStyles = (theme) => {
  return colors(theme).map((color) => {
    return {
      data: { fill: `${color.hex}` },
    };
  });
};

export const linearStyles = (theme) => {
  return colors(theme).map((color) => {
    return {
      data: {
        fill: `url(#linear${color.name}Transparent25)`,
        fillOpacity: 0.3,
        stroke: color.hex,
        strokeWidth: 2,
      },
    };
  });
};

export const scaltterStyles = (theme) => {
  return colors(theme).map((color) => {
    return {
      data: {
        fill: "#00",
        stroke: color.hex,
        strokeWidth: 2,
      },
    };
  });
};

export const grid = (theme) => {
  return {
    fill: theme === "dark" ? "#ffffffa3" : "#141414a3",
    stroke: theme === "dark" ? "#ffffffa3" : "#141414a3",
    pointerEvents: "painted",
    strokeWidth: 1,
    opacity: 0.2,
  };
};

export const tickLabelsX = (theme) => {
  return {
    fill: theme === "dark" ? "#ffffffa3" : "#141414a3",
    fontSize: 10,
    angle: 45,
    padding: 60,
    fontFamily: "Inter",
  };
};

export const tickLabelsY = (theme) => {
  return {
    fill: theme === "dark" ? "#ffffffa3" : "#141414a3",
    fontSize: 10,
    fontFamily: "Inter",
  };
};

export const tickStyle = (theme) => {
  return {
    fill: theme === "dark" ? "#262626" : "#fafafa",
    opacity: 0.8,
  };
};
