export const SET_USER = "SET_USER";
export const SET_USER_CAPABILITIES = "SET_USER_CAPABILITIES";
export const SET_PROFILE_PROGRESS = "SET_PROFILE_PROGRESS";
export const SET_SAVING_INFO_USER = "SET_SAVING_INFO_USER";
export const SET_USER_ACCESS_EDIT = "SET_USER_ACCESS_EDIT";
export const SET_SHOW_CREATE_USER_MODAL = "SET_SHOW_CREATE_USER_MODAL";
export const SET_BUSINESS_UNITS = "SET_BUSINESS_UNITS";
export const SET_SHOW_USERS_LIST_MODAL = "SET_SHOW_USERS_LIST_MODAL";
export const SET_USER_JOBS = "SET_USER_JOBS";
export const SET_USERS_ACCOUNT = "SET_USERS_ACCOUNT";
export const SET_USERS_ACCOUNT_LOADING = "SET_USERS_ACCOUNT_LOADING";
export const SET_USER_CHANNELS = "SET_USER_CHANNELS";
export const SET_USER_PERSONAL_INTERESTS = "SET_USER_PERSONAL_INTERESTS";
export const SET_USER_PREFERENCE_INFO = "SET_USER_PREFERENCE_INFO";
export const SET_SHOW_MODAL_USER_PREFERENCE = "SET_SHOW_MODAL_USER_PREFERENCE";
export const SET_USER_TOKEN_API = "SET_USER_TOKEN_API";
export const SET_LOADING_NEW_USER = "SET_LOADING_NEW_USER";
export const SET_USERS = "SET_USERS";
export const SET_CURRENT_PAGE_USERS = "SET_CURRENT_PAGE_USERS";
export const SET_LIST_USERS_LOADING = "SET_LIST_USERS_LOADING";
export const SET_LOADING_MORE_USERS = "SET_LOADING_MORE_USERS";
export const SET_TOTAL_PAGES_USERS = "SET_TOTAL_PAGES_USERS";
export const SET_TOTAL_USERS = "SET_TOTAL_USERS";
export const SET_SHOW_USER_TYPE_MODAL = "SET_SHOW_USER_TYPE_MODAL";
export const SET_SHOW_INVITE_SUCCESS_MODAL = "SET_SHOW_INVITE_SUCCESS_MODAL";
export const SET_USER_SERVICES_SELECTED = "SET_USER_SERVICES_SELECTED";
export const SET_USER_DETAIL = "SET_USER_DETAIL";
export const SET_USER_ACCOUNT_SELECTED = "SET_USER_ACCOUNT_SELECTED";
export const SET_USERS_TO_INVITE = "SET_USERS_TO_INVITE";
export const SET_USER_REGISTERED_LOADING = "SET_USER_REGISTERED_LOADING";
export const SET_INFO_INVITE_SUCCESS_MODAL = "SET_INFO_INVITE_SUCCESS_MODAL";
export const SET_LOADING_UPDATE_USER = "SET_LOADING_UPDATE_USER";
export const SET_LOADING_DELETE_USER = "SET_LOADING_DELETE_USER";
export const REMOVE_USER_ACCOUNT_SUCCESS = "REMOVE_USER_ACCOUNT_SUCCESS"
export const SET_ACCOUNT_SELECTED_USERS_TABLE = 'SET_ACCOUNT_SELECTED_USERS_TABLE'
export const SET_USER_TYPES_SELECT = 'SET_USERS_TYPES_SELECT'
export const SET_USER_TYPES = "SET_USER_TYPES";