import * as creators from "../creators/Users";

const initialState = {
    user: null,
    profileProgress: 0,
    savingInfoUser: false,
    userAccessEdit: null,
    showCreateUserModal: false,
    businessUnits: [],
    showUsersListModal: false,
    userJobs: [],
    usersAccount: [],
    usersAccountLoading: true,
    userChannels: [],
    userPersonalInterests: [],
    userPreferenceInfo: null,
    showUserPreferencesModal: null,
    userTokenApi: null,
    usersLoading: false,
    users: [],
    totalUsers: 0,
    currentPage: 1,
    loadingMoreUsers: false,
    totalPagesUsers: 0,
    showUserTypeModal: false,
    showInviteSuccessModal: false,
    userServicesSelected: {
        newServices: [],
        updatedServices: [],
    },
    userDetail: null,
    userAccountSelected: {
        newServices: null,
        updatedServices: null,
        users: null,
    },
    usersToInvite: [],
    userRegisteredLoading: false,
    infoInviteSuccessModal: null,
    loadingUpdateUser: false,
    loadingDeleteUser: false,
    accountSelectedUsersTable: null,
    userTypesSelect: [],
};

export const usersReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case creators.SET_USER:
            return { ...state, user: payload };
        case creators.SET_USER_CAPABILITIES:
            const user = state.user;
            return { ...state, user: { ...user, capabilities: { ...user.capabilities, ...payload } } };
        case creators.SET_PROFILE_PROGRESS:
            return { ...state, profileProgress: payload };
        case creators.SET_SAVING_INFO_USER:
            return { ...state, savingInfoUser: payload };
        case creators.SET_USER_ACCESS_EDIT:
            return { ...state, userAccessEdit: payload };
        case creators.SET_SHOW_CREATE_USER_MODAL:
            return { ...state, showCreateUserModal: payload };
        case creators.SET_SHOW_USERS_LIST_MODAL:
            return { ...state, showUsersListModal: payload };
        case creators.SET_USER_JOBS:
            return { ...state, userJobs: payload };
        case creators.SET_BUSINESS_UNITS:
            return { ...state, businessUnits: payload };
        case creators.SET_USERS_ACCOUNT:
            return { ...state, usersAccount: payload };
        case creators.SET_USERS_ACCOUNT_LOADING:
            return { ...state, usersAccountLoading: payload };
        case creators.SET_USER_CHANNELS:
            return { ...state, userChannels: payload };
        case creators.SET_USER_PERSONAL_INTERESTS:
            return { ...state, userPersonalInterests: payload };
        case creators.SET_USER_PREFERENCE_INFO:
            return { ...state, userPreferenceInfo: payload };
        case creators.SET_SHOW_MODAL_USER_PREFERENCE:
            return { ...state, showUserPreferencesModal: payload };
        case creators.SET_USER_TOKEN_API:
            return { ...state, userTokenApi: payload };
        case creators.SET_LOADING_NEW_USER:
            return { ...state, usersLoading: payload }; 
        case creators.SET_USERS:
            return { ...state, users: payload };
        case creators.SET_TOTAL_USERS:
            return { ...state, totalUsers: payload };
        case creators.SET_CURRENT_PAGE_USERS:
            return { ...state, currentPage: payload };
        case creators.SET_LOADING_MORE_USERS:
            return { ...state, loadingMoreUsers: payload };
        case creators.SET_TOTAL_PAGES_USERS:
            return { ...state, totalPagesUsers: payload };
        case creators.SET_SHOW_USER_TYPE_MODAL:
            return { ...state, showUserTypeModal: payload };
        case creators.SET_SHOW_INVITE_SUCCESS_MODAL:
            return { ...state, showInviteSuccessModal: payload };
        case creators.SET_USER_SERVICES_SELECTED:
            const currentSelectedServices = { ...state.userServicesSelected };
            currentSelectedServices[payload.key] = payload.value;
            return { ...state, userServicesSelected: currentSelectedServices };
        case creators.SET_USER_DETAIL:
            return { ...state, userDetail: payload };
        case creators.REMOVE_USER_ACCOUNT_SUCCESS:
            return {
                ...state,
                users: state.users.map((user) => (user.idUser === payload.idUser ? payload : user)),
                userAccountSelected: {
                    ...state.userAccountSelected,
                    users: null,
                },
                loadingDeleteUser: false,
            };
        case creators.SET_USERS_TO_INVITE:
            return { ...state, usersToInvite: payload };
        case creators.SET_USER_REGISTERED_LOADING:
            return { ...state, userRegisteredLoading: payload };
        case creators.SET_INFO_INVITE_SUCCESS_MODAL:
            return { ...state, infoInviteSuccessModal: payload };
        case creators.SET_LOADING_UPDATE_USER:
            return { ...state, loadingUpdateUser: payload };
        case creators.SET_LOADING_DELETE_USER:
            return { ...state, loadingDeleteUser: payload };
        case creators.SET_ACCOUNT_SELECTED_USERS_TABLE:
            return { ...state, accountSelectedUsersTable: payload };
        case creators.SET_USER_TYPES:
            return { ...state, userTypes: payload };
        case creators.SET_USER_TYPES_SELECT:
            return { ...state, userTypesSelect: payload };
        default:
            return state;
    }
};
