import React from "react";


const Pagination = ({ totalPages, currentPage, onPageChange, theme }) => {
    return (
        <div className="row-start align-items-center gap-3">            
            <button 
                onClick={() => onPageChange(currentPage - 1)} 
                disabled={currentPage === 1}
                className={`row-center align-items-center button ${currentPage === 1 ? 'button-fill-disabled button-content-disabled' : 'surface-bg-on-surface-alpha border-fill-medium content-default-primary'} label-medium-medium rounded-circle ${theme}`}
                style={{ height: 36, width: 36 }}
            >
                <i className={`fal fa-chevron-left txt-10 content-default-secondary ${theme}`}></i>
            </button>            
            {Array.from({ length: totalPages }, (_, index) => (
                <button 
                    key={index + 1} 
                    onClick={() => onPageChange(index + 1)} 
                    className={`row-center align-items-center ${currentPage === index + 1 ? 'surface-bg-on-surface-alpha border-fill-medium content-default-primary' : 'button-fill-disabled button-content-disabled'} label-medium-medium rounded-circle ${theme}`}
                    style={{ height: 36, width: 36 }}
                >
                    {index + 1}
                </button>
            ))}            
            <button 
                onClick={() => onPageChange(currentPage + 1)} 
                disabled={currentPage === totalPages}
                className={`row-center align-items-center button ${currentPage === totalPages ? 'button-fill-disabled button-content-disabled' : 'surface-bg-on-surface-alpha border-fill-medium content-default-primary'} label-medium-medium rounded-circle ${theme}`}
                style={{ height: 36, width: 36 }}
            >
                <i className={`fal fa-chevron-right txt-10 content-default-secondary ${theme}`}></i>
            </button>
        </div>
    );
};

export default Pagination;
